import React, { useState } from "react";
import config from "src/config";
import {
  Box,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
  Button,
  TextField,
  makeStyles,
  CircularProgress,
  colors,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneIphoneOutlinedIcon from "@material-ui/icons/PhoneIphoneOutlined";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(1, "Please enter a valid first name"),
  lastName: Yup.string().min(1, "Please enter a valid last name"),
  emailAddress: Yup.string().email("Please enter a valid email"),
  phoneNumber: Yup.string()
    .min(10, "Please enter a valid phone number")
    .max(10, "Please enter a valid phone number"),
  password: Yup.string().min(6, "Please enter a password of minimum 6 digit"),
});

export default function PassRegForm({ student, handleRegister }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSubmitting, setIsSubmitting] = useState(false);

  let initialValues = {
    firstName: "",
    lastName: "",
    emailAddress: student.emailAddress,
    phoneNumber: "",
    // password: "",
  };
  let onSubmit = async (values) => {
    handleRegSubmit();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    touched,
    isValid,
    dirty,
  } = formik;

  const handleRegSubmit = async () => {
    setIsSubmitting(true);
    await handleRegister(values);
    setIsSubmitting(false);
  };

  return (
    <>
      <Box
        pt={sm ? 3 : 6}
        pb={sm ? 3 : 6}
        overflow="hidden"
        style={{ backgroundColor: "white" }}
      >
        <Container>
          <Grid container spacing={3} justify="center">
            <Grid item lg={6} md={12}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      placeholder="FIRST NAME"
                      value={values.firstName}
                      name="firstName"
                      fullWidth
                      required
                      onChange={handleChange}
                      error={errors.firstName && true}
                      helperText={errors.firstName}
                      InputProps={{
                        endAdornment: (
                          <TextFieldsOutlinedIcon
                            className={classes.inputAdornment}
                          />
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      placeholder="LAST NAME"
                      value={values.lastName}
                      name="lastName"
                      fullWidth
                      required
                      onChange={handleChange}
                      error={errors.lastName && true}
                      helperText={errors.lastName}
                      InputProps={{
                        endAdornment: (
                          <TextFieldsOutlinedIcon
                            className={classes.inputAdornment}
                          />
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled
                      variant="outlined"
                      placeholder="EMAIL ADDRESS"
                      value={values.emailAddress}
                      name="emailAddress"
                      fullWidth
                      required
                      onChange={handleChange}
                      error={errors.emailAddress && true}
                      helperText={errors.emailAddress}
                      InputProps={{
                        endAdornment: (
                          <EmailOutlinedIcon
                            className={classes.inputAdornment}
                          />
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ display: "flex" }}>
                    <TextField
                      disabled
                      placeholder="+1"
                      variant="outlined"
                      className={classes.phonePrefix}
                    />
                    <TextField
                      variant="outlined"
                      placeholder="PHONE NUMBER"
                      value={values.phoneNumber}
                      name="phoneNumber"
                      fullWidth
                      required
                      onChange={handleChange}
                      error={errors.phoneNumber && true}
                      helperText={errors.phoneNumber}
                      className={classes.phoneInput}
                      InputProps={{
                        endAdornment: (
                          <PhoneIphoneOutlinedIcon
                            className={classes.inputAdornment}
                          />
                        ),
                      }}
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      placeholder="PASSWORD"
                      type="password"
                      value={values.password}
                      name="password"
                      fullWidth
                      required
                      onChange={handleChange}
                      error={errors.password && true}
                      helperText={errors.password}
                      InputProps={{
                        endAdornment: (
                          <LockOutlinedIcon
                            className={classes.inputAdornment}
                          />
                        ),
                      }}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      disableElevation
                      className={classes.submitButton}
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      PURCHASE
                      {isSubmitting && (
                        <CircularProgress
                          size={16}
                          style={{
                            marginLeft: 10,
                            marginTop: "-3px",
                            color: "white",
                          }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: 20,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
  phonePrefix: {
    width: 64,

    "& .MuiInputBase-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& fieldset": {
      borderRight: 0,
    },
  },
  phoneInput: {
    "& .MuiInputBase-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  inputAdornment: {
    color: colors.grey["600"],
  },
}));

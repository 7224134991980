import React from "react";
import {
  Paper,
  Box,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  colors,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { Calendar as CalendarIcon, Clock as ClockIcon } from "react-feather";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  eventLogo: {
    width: 60,
  },
  eventName: {
    fontFamily: "Raleway",
    fontWeight: "bold",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  listText: {
    "& .MuiListItemText-primary": {
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "Raleway",
      color: colors.grey["800"],
    },
  },
  listIcon: {
    minWidth: "40px",
  },
}));

export default function AvailableGames({ events }) {
  const classes = useStyles();

  return (
    <Paper elevation={8}>
      <Box p={3}>
        {events &&
          events.map((event, index) => (
            <Paper
              variant="outlined"
              key={uuid()}
              style={{ marginTop: index > 0 ? 25 : 0 }}
              className={classes.paper}
            >
              <Box p={2} className={classes.cardContent}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <img
                    src={event.logoUrl}
                    alt=""
                    className={classes.eventLogo}
                  />
                  <Typography variant="h6" className={classes.eventName}>
                    {event.description}
                  </Typography>
                </Box>

                <Box>
                  <List>
                    <ListItem>
                      <ListItemIcon className={classes.listIcon}>
                        <CalendarIcon />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listText}
                        primary={moment(event.date).format("MM/DD/yyyy")}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon className={classes.listIcon}>
                        <ClockIcon />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listText}
                        primary={moment(event.time, ["HH:mm"]).format("h:mm a")}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Paper>
          ))}
      </Box>
    </Paper>
  );
}

import React, { createContext, useState, useEffect, ReactChild } from "react";
import config from "src/config";

declare global {
  interface Window {
    $crisp: Record<string, any>;
  }
}

interface PropTypes {
  children: ReactChild;
}

type CrispType = Record<string, any> | null;

const CrispContext = createContext<CrispType>(null);

export const CrispProvider = ({ children }: PropTypes) => {
  // const location = useLocation();
  const [crisp, setCrisp] = useState<CrispType>(null);

  // Helper fn to push data to crisp
  const crispPush = (key: string, value: string | number) =>
    crisp && crisp.push(["set", "session:data", [[[key, value]]]]);

  // Pushing data to crisp, based on states
  const setCustomProperties = () => {
    crisp && crispPush("site_name", `${config?.PRODUCT_NAME}`);
    crisp && crispPush("team_id", config?.TEAM_ID);
  };

  // Hiding based on config
  const toggleCrispByConfig = () => {
    if (!crisp) return;
    if (config?.CRISP_ENABLED) {
      crisp.push(["do", "chat:show"]);
    } else {
      crisp.push(["do", "chat:hide"]);
    }
  };

  // Side effects
  useEffect(() => {
    setCrisp(window.$crisp);
  }, [window.$crisp]);
  useEffect(toggleCrispByConfig, [crisp]);
  useEffect(setCustomProperties, [crisp]);

  // Hiding the chatbox on portal
  // useEffect(() => {
  //   if (location.pathname.indexOf("portal") > 0) {
  //     crisp && crisp.push(["do", "chat:hide"]);
  //   } else {
  //     crisp && crisp.push(["do", "chat:show"]);
  //   }
  // });

  return <CrispContext.Provider value={crisp}>{children}</CrispContext.Provider>;
};

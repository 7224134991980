import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import config from "src/config";

export default function MatInput({
  formik,
  name,
  className,
  placeholder,
  ...props
}) {
  const classes = useStyles();
  const { values, handleChange, errors } = formik;

  return (
    <TextField
      required
      className={clsx(classes.inputField, className)}
      fullWidth
      variant="outlined"
      size="medium"
      name={name}
      placeholder={placeholder}
      value={values[name]}
      error={errors[name] && true}
      helperText={errors[name]}
      onChange={handleChange}
      {...{ props }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: config.COLORS.primary,
    },

    "& fieldset": {
      borderRadius: 0,
    },
  },
}));

import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import Header from "src/components/Header";
import {
  Box,
  Container,
  Paper,
  Typography,
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import config from "src/config";
import FriendsView from "src/views/FriendsView";
import PassOverView from "src/views/PassOverview";
import Sidebar from "./Sidebar";
import { v4 as uuid } from "uuid";
import {
  SendOutlined,
  ConfirmationNumberOutlined,
  DateRangeOutlined,
  PermContactCalendarOutlined,
  CreditCardOutlined,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocalStorage } from "src/components/localStorage";
import { homeRedirect, routes } from "src/routes";
import ManageContacts from "src/views/ManageContacts";
import TopBar from "./TopBar";
import ManageSubscription from "src/views/ManageSubscription";
import AnnouncementBar from "src/components/AnnouncementBar";

const sidebarWidth = 300;

let defaultModules = [
  {
    id: uuid(),
    name: "EVENT ATTENDANCE",
    icon: <ConfirmationNumberOutlined />,
    component: <PassOverView />,
    url: routes.membershipPassOverview,
  },
  {
    id: uuid(),
    name: "SIT WITH FRIENDS",
    icon: <SendOutlined />,
    component: <FriendsView />,
    url: routes.membershipSitWithFriend,
  },

  {
    id: uuid(),
    name: "MANAGE SUBSCRIPTION",
    icon: <CreditCardOutlined />,
    component: <ManageSubscription />,
    url: routes.membershipManageSubscription,
  },
  {
    id: uuid(),
    name: "CONTACT INFO",
    icon: <PermContactCalendarOutlined />,
    component: <ManageContacts />,
    url: routes.membershipManageContacts,
  },
];

// Disabling manage subscription if config.IS_RECURRING is false
// Disabling event attendace based on config.
(() => {
  const isRecurring = config.IS_RECURRING;
  const haveEventAttendance = config.EVENT_ATTENDANCE;
  const sitWithFriend = config?.SIT_WITH_FRIENDS;
  let filtered = [...defaultModules];
  if (!isRecurring) {
    filtered = filtered.filter((module) => module.name !== "MANAGE SUBSCRIPTION");
  }

  if (!haveEventAttendance) {
    filtered = filtered.filter((module) => module.name !== "EVENT ATTENDANCE");
  }

  if (!sitWithFriend) {
    filtered = filtered.filter((module) => module.name !== "SIT WITH FRIENDS");
  }

  defaultModules = filtered;
})();

export default function Layout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarEnabled] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  const [modules, setModules] = useState(defaultModules);
  const [activeModule, setActiveModule] = useState(modules[0]);
  const reactLocalStorage = useLocalStorage();
  const [token] = useState(reactLocalStorage.getItem(`${config.TEAM_ID}_MA_REFRESH_TOKEN`));
  const [subscriber] = useState(reactLocalStorage.getItem(`${config.TEAM_ID}_MA_SUBSCRIBER`));

  // This codes commented out intentionally :- as multiple modules are
  // dependent on config, so that filtering is done outside of the component
  // to ensure proper state for the component; check top outside of the components
  // for the filter
  // =========================================================================
  // =========================================================================
  // useEffect(() => {
  //   const isRecurring = config.IS_RECURRING;
  //   const haveEventAttendance = config.EVENT_ATTENDANCE;
  //   let filtered = [...modules];
  //   if (!isRecurring) {
  //     filtered = filtered.filter(
  //       (module) => module.name !== "MANAGE SUBSCRIPTION"
  //     );
  //   }
  //   if (!haveEventAttendance) {
  //     filtered = filtered.filter(
  //       (module) => module.name !== "EVENT ATTENDANCE"
  //     );
  //   }
  //   setModules(filtered);
  // }, []);

  // If membership portal generate modules
  useEffect(() => {
    let pathname = history.location.pathname;
    let isMembership = pathname.indexOf("membership_portal") >= 0 ? true : false;

    if (isMembership) {
      generateModules();
    }
  }, [history]);

  const generateModules = async () => {
    let endpoint = `${config.ADMIN_URI}/teams/team_configuration`;
    try {
      let response = await axios.post(endpoint, { teamId: config.TEAM_ID });
      if (response.status === 200) {
        let { team_configuration } = response.data;

        const isRecurring = config.IS_RECURRING;

        let filteredModules = modules;
        if (!isRecurring) {
          filteredModules = modules.filter((module) => module.name !== "MANAGE SUBSCRIPTION");
        }
        team_configuration.canSitWithFriend && setModules([...filteredModules]);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
  };

  // sidebar toggler in mobile version
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Handling changing modules
  const changeModule = (id) => {
    let module = modules.find((module) => module.id === id);
    setActiveModule(module);
    if (sidebarOpen) setSidebarOpen(false);
  };

  return (
    <>
      {token && subscriber && (
        <Box className={classes.siteWrapper}>
          <Sidebar
            width={sidebarWidth}
            {...{
              sidebarOpen,
              toggleSidebar,
              modules,
              activeModule,
              changeModule,
            }}
          />
          <Box className={classes.pageWrapper}>
            <AnnouncementBar />
            <Header {...{ sidebarEnabled, toggleSidebar }} />
            {sm && <TopBar {...{ modules }} />}
            <Banner
              heading={config.PAGE_TITLES.membershipPortal.heading}
              subtitle={config.PAGE_TITLES.membershipPortal.subtitle}
            />
            <Box mb={12}>
              <Container>
                <Box mt={4} key={uuid}>
                  <Grid container justify="center">
                    <Grid item xs={12} lg={6}>
                      {children}
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  siteWrapper: {
    width: "100%",
    overflow: "hidden",
  },
  pageWrapper: {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginLeft: sidebarWidth,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0",
    },
  },
}));

import React, { useState } from "react";
import { Box, makeStyles, Container, Typography, Grid } from "@material-ui/core";
import config from "src/config";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  banner: {
    height: 450,
    width: "100%",
    backgroundImage: `url(${config.HOME_HERO_BG})`,
    backgroundSize: "cover",
    backgroundBlendMode: "overlay",
    backgroundPosition: "center",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: 300,
      // backgroundPosition: "center",
    },
    [theme.breakpoints.down("xs")]: {
      height: 200,
      // backgroundPosition: "-430px 0",
    },
  },

  overlay: {
    backgroundColor: `rgba(0,0,0,${config.COLORS.heroOverlay})`,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 2,
    height: "100%",
  },
  contentWrapper: {
    position: "relative",
    zIndex: 999,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pageTitle: {
    color: "white",
    fontFamily: "Raleway",
    fontSize: 48,
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  pageSubtitle: {
    color: "white",
    fontSize: 24,
    textAlign: "center",
    lineHeight: 1.3,
    opacity: "0.9",
    fontWeight: 300,
    fontFamily: "Poppins",
    letterSpacing: 1,

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },

  // Full width banner styles
  //=========================
  fullWidthWrapper: {
    width: "100%",
    overflow: "hidden",
    marginBottom: "-5px",
  },
  fullImage: {
    width: "100%",
    height: "auto",
  },
}));

export default function Banner({ heading, subtitle }) {
  const isFullWidth = config?.FULL_WIDTH_BANNER;
  const classes = useStyles();

  return (
    <>
      {React.isValidElement(config.HOME_HERO_BG) ? (
        <>{config.HOME_HERO_BG}</>
      ) : (
        <>
          {isFullWidth ? (
            <Box className={classes.fullWidthWrapper}>
              <img className={classes.fullImage} src={config?.HOME_HERO_BG} alt="" />
            </Box>
          ) : (
            <Box className={classes.banner}>
              <Box className={classes.overlay}></Box>
              <Box className={classes.contentWrapper}>
                <Container>
                  {heading?.length > 0 && (
                    <Grid container justify="center" spacing={4}>
                      <Grid item xs={12} sm={8} lg={6}>
                        <Typography variant="h1" className={classes.pageTitle} gutterBottom>
                          {heading}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {subtitle?.length > 0 && (
                    <Grid container justify="center">
                      <Grid item xs={12} sm={8} lg={6}>
                        <Typography variant="subtitle1" className={classes.pageSubtitle}>
                          {subtitle}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Container>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Header from "src/components/Header";
import Banner from "src/components/Banner";
import PassRegForm from "./PassRegForm";
import ConnectTeamAccount from "./ConnectTeamAccount";
import { useLocation, useHistory } from "react-router";
import config from "src/config";
import axios from "axios";
import { toast } from "react-toastify";
import ManifestCart from "./ManifestCart";
import GameDetailModal from "src/components/GameDetailModal";
import { v4 as uuid } from "uuid";
import { useLocalStorage } from "src/components/localStorage";
import Swal from "sweetalert2";
import queryString from "query-string";
import { teamAuthApi } from "src/api/teamAuthApi";
import LoginNotice from "../PurchasePlan/LoginNotice";

export default function RegisterPass() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [manifests, setManifests] = useState([]);
  const [emailAddress, setEmailAddress] = useState(null);
  const [isEligible, setIsEligible] = useState(false);
  const [isTeamAccountConnected, setIsTeamAccountConnected] = useState(false);
  const [connectedAccountName, setConnectedAccountName] = useState("");
  const [tempRefreshToken, setTempRefreshToken] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const reactLocalStorage = useLocalStorage();

  useEffect(() => {
    // Check for code
    const parsed = queryString.parse(location.search);
    const login = async () => {
      if (parsed.code) {
        const redirectUrl = `${config.SS_FE_BASE_URI}/tmcallback/register-pass?eventCode=${parsed.eventCode}`;
        teamAuthApi
          .loginTicketmasterAuthCode(parsed.code, redirectUrl)
          .then(async (loginTicketmaster) => {
            if (loginTicketmaster.data["userData"]["refreshToken"]) {
              const refreshToken =
                loginTicketmaster.data["userData"]["refreshToken"];
              const emailAddress =
                loginTicketmaster.data["userData"]["tmEmailAddress"];
              setIsTeamAccountConnected(true);
              setConnectedAccountName(emailAddress);
              setEmailAddress(emailAddress);

              await exclusiveEmailVerify(emailAddress);
              // sets local storage
              setTempRefreshToken(refreshToken);
              // localStorage.setItem(
              //   `${config.TEAM_ID}_MA_REFRESH_TOKEN`,
              //   refreshToken
              // );
            }
          })
          .catch((err) => {
            toast.error(
              "Error connecting team account. Try again or contact support if error persists."
            );
          });
      }
    };
    login();
  }, []);

  // Checking verified email if plan is exclusiv
  const exclusiveEmailVerify = async (emailAddress) => {
    const endpoint = `${config.API_BASE_URI}/subscription/eligiblity`;

    try {
      let response = await axios.post(endpoint, { emailAddress });
      if (response.status === 200) {
        let { isEligible, studentManifest, accountNumber } = response.data;
        console.log(response);
        setIsEligible(isEligible);
        setManifests(studentManifest);
        setEmailAddress(emailAddress);
        setAccountNumber(accountNumber);

        toast.success("Email verified");
      }
    } catch (error) {
      setIsEligible(false);
      Swal.fire({
        title: "You are not eligible to purchase this pass",
        icon: "error",
      });
    }
  };

  // Handling register
  const handleRegister = async (values) => {
    let endpoint = `${config.API_BASE_URI}/subscription/register`;

    try {
      let response = await axios.post(endpoint, {
        ...values,
        accountNumber,
      });
      if (response.status === 200) {
        let { subscriber } = response.data;
        reactLocalStorage.setItem(
          `${config.TEAM_ID}_MA_REFRESH_TOKEN`,
          tempRefreshToken
        );
        reactLocalStorage.setItem(
          `${config.TEAM_ID}_MA_SUBSCRIBER`,
          subscriber
        );
        Swal.fire({
          icon: "success",
          title: "Congrats! Your registration was successful.",
          html: "<p>An email was sent with more information about how tickets are delivered. Contact support if you have any questions.</p>",
          confirmButtonText: `Done`,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/membership_portal");
          }
        });
        window.mixpanel.track("Purchase Complete", {});
      }
      return new Promise((resolve) => resolve(response));
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data ||
        "Something went wrong, please try again";
      Swal.fire("Error!", errorMessage, "error");
    }
  };

  return (
    <Box>
      <Header />
      <Banner
        heading={config.PAGE_TITLES.registerPass.heading}
        subtitle={config.PAGE_TITLES.registerPass.subtitle}
      />
      <Container>
        <Box style={{ marginBottom: 200 }}>
          {!isEligible && !isTeamAccountConnected && (
            <Box mt={4}>
              <Grid container justify="center">
                <Grid item lg={8} xs={12}>
                  <ConnectTeamAccount {...{ exclusiveEmailVerify }} />
                </Grid>
              </Grid>
            </Box>
          )}
          {isTeamAccountConnected && (
            <Box mt={4}>
              <Grid container justify="center">
                <Grid item lg={8} xs={12}>
                  <LoginNotice accountUserName={connectedAccountName} />
                </Grid>
              </Grid>
            </Box>
          )}

          {isEligible && emailAddress && (
            <>
              <Box mt={4}>
                <Grid container justify="center">
                  <Grid item lg={8} xs={12}>
                    {manifests.length > 0 && (
                      <ManifestCart {...{ manifests }} />
                    )}
                  </Grid>
                </Grid>
              </Box>

              <Box mt={4} mb={8}>
                <Grid container justify="center">
                  <Grid item lg={8} xs={12}>
                    <PassRegForm {...{ emailAddress, handleRegister }} />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  formTitle: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 700,
  },
}));

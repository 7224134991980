import React from "react";
import Header from "src/components/Header";
import Banner from "src/components/Banner";
import { Box, Container, Grid } from "@material-ui/core";
import FriendsView from "src/views/FriendsView";
import config from "src/config";

export default function FriendsPortal() {
  return (
    <Box>
      <Header />
      <Banner
        heading={config.PAGE_TITLES.friendsPortal.heading}
        subtitle={config.PAGE_TITLES.friendsPortal.subtitle}
      />
      <Container>
        <Box>
          <Grid container justify="center">
            <Grid item xs={12} lg={6}>
              <FriendsView />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

import actionTypes from "./actionTypes";

const initialState = {
  configuration: null,
};

const teamConfigReducer = (state = initialState, { type, payload }) => {
  const { getTeamConfig } = actionTypes;

  switch (type) {
    case getTeamConfig:
      state.configuration = payload;
      return { ...state };
    default:
      return { ...state };
  }
};

export default teamConfigReducer;

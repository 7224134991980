import * as yup from "yup";

const checkValidExpiryDate = function (errorMessage) {
  const testingObject = {
    name: "isValidExpiry",
    message: errorMessage,
    test: function (value) {
      if (!value || !value.includes("/")) {
        return false;
      }
      const expiryArray = value.split("/");
      const month = expiryArray.length > 0 ? expiryArray[0] : "";
      const year = expiryArray.length > 1 ? expiryArray[1] : "";

      // validate month and year length
      if (
        month.length === 0 ||
        month.length > 2 ||
        !(year.length === 2 || year.length === 4)
      ) {
        return false;
      }

      const correctYear = /^\d+$/.test(year);
      const correctMonth = /^\d+$/.test(month);
      if (
        !correctMonth ||
        !correctYear ||
        parseInt(month, 10) < 1 ||
        parseInt(month, 10) > 12 ||
        parseInt(year.slice(-2), 10) < 21
      ) {
        return false;
      }
      return true;
    },
  };
  return this.test(testingObject);
};

yup.addMethod(yup.string, "isValidExpiry", checkValidExpiryDate);

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Please enter First Name")
    .min(2, "Please insert a valid first name"),
  lastName: yup
    .string()
    .required("Please enter Last Name")
    .min(2, "Please insert a valid last name"),
  email: yup.string().email("Please insert a valid email address"),
  phoneNumber: yup
    .string()
    .required("Please enter Phone Number")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid"),
  street: yup
    .string()
    .required("Please enter Street Address")
    .min(10, "Please insert a valid street address"),
  zipCode: yup.number().min(1, "Please insert a valid zip code"),
  cardNumber: yup
    .string()
    .required("Please enter Card Number")
    .min(17, "Please insert a valid card number")
    .max(20, "Please insert a valid card number"),
  cardCvc: yup
    .string()
    .required("Please enter Security Code")
    .min(3, "Please insert a valid card cvc")
    .max(4, "Please insert a valid card cvc"),
  cardExpiry: yup
    .string()
    .required("Please enter Card Expiry")
    .min(4, "Please insert a valid card expiration date")
    .max(7, "Please insert a valid card expiration date")
    .isValidExpiry("Please insert a valid card expiration date"),
  termsAgreed: yup
    .bool()
    .oneOf(
      [true],
      "Please accept our terms and conditions to complete your purchase."
    ),
});

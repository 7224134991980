import React from "react";
import BannerImage from "src/images/kings/kings_banner_psd_edit_feb_22.png";
const LogoImage = "https://ss-leagues.s3.amazonaws.com/NCAA/Kings-Primary-Color_Logo.png";

const PAGE_TITLES = {
  home: {
    heading: "",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "",
    subtitle: "",
  },
};

const SITE_SEO = {
  title: "SACRAMENTO KINGS",
  description: "Register your Sacramento Kings Student Tickets",
  favicon: LogoImage,
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "Verify your email address",
    description:
      "Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "Ticket Options",
  membershipSection: {
    title: "Manage Your Kings Pass",
    question: "Returning Kings Pass buyer?",
    description: "Click the button below to look up and manage your existing Kings Pass.",
  },
  announcementBarText: "",

  registerTerms: {
    enabled: true,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://uploads-ssl.webflow.com/5ed7df6fe67e862e0a62f9f2/616882d2b691e09f75348396_Terms%20%26%20Conditions.pdf"
          className="register_terms_link"
        >
          Terms and Conditions
        </a>
        ,{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.golden1center.com/assets/doc/2021-2022NBAWebsiteSupplementalTicketBackLanguagev3_FINAL-5706eea6a8.pdf"
          className="register_terms_link"
        >
          Sacramento Kings ticket terms
        </a>{" "}
        and the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.nba.com/kings/healthandsafety"
          className="register_terms_link"
        >
          venue's health and safety guidelines
        </a>{" "}
        for event attendance.
        <br />
        <br />
        Game dates, opponents and number of games will be determined when the NBA schedule is
        released. The November Kings Pass will include all November 2022 home games except Lakers
        vs. Kings and Warriors vs. Kings, if applicable.
      </p>
    ),
  },
};

const UI_DIMENSION = {
  eventImageWidth: 80,
};

export const kings = {
  SITE_TITLE: "SACRAMENTO KINGS",
  TEAM_NAME: "SACRAMENTO KINGS",
  TEAM_ID: 78,
  PRODUCT_NAME: "KINGS PASS",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  IS_RECURRING: true,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "https://fanhub.seasonshare.com/sackings/landing",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://passbackend.seasonshare.com/api",
  SS_FE_BASE_URI: "https://kings.seasonshare.com",
  ADMIN_BASE_URI: "https://subsjanitor.seasonshare.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#5b2c81",
    secondary: "#5d676f",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0,
    membershipOverlay: 0,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

import React, { useState } from "react";
import {
  Box,
  Paper,
  Button,
  FormControl,
  OutlinedInput,
  InputLabel,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  colors,
  FormHelperText,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import config from "src/config";
import axios from "axios";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
  },
  alert: {},
  inputLabel: {
    fontFamily: "Raleway",
    "&.MuiInputLabel-shrink": {
      backgroundColor: "white",
    },
  },
  input: {
    fontFamily: "Raleway",
    fontWeight: 600,
  },
  testButton: {
    backgroundColor: config.COLORS.primary,
    borderRadius: 25,
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: "Raleway",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },

  saveButton: {
    backgroundColor: config.COLORS.primary,
    borderRadius: 25,
    paddingLeft: 30,
    paddingRight: 30,
    color: "white",
    fontSize: "18px",
    fontFamily: "Raleway",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
}));

export default function ContactInfoForm({ subscriber }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [phoneNumber, setPhoneNumber] = useState(subscriber.phoneNumber);
  const [emailAddress, setEmailAddress] = useState(subscriber.emailAddress);
  const [testLoading, setTestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (event, type) => {
    let value = event.target.value;
    if (type === "phone") {
      // validating usa phone number
      let prefix = "+1";
      let isPrefixExist = value.indexOf(prefix) >= 0 ? true : false;
      let isProperLength = value.length <= 12;
      let isNumber = !/\D/.test(value.replace("+1", ""));

      if (!(value.length >= 12)) {
        setError("Not a valid phone number");
      } else {
        setError(null);
      }

      if (isPrefixExist && isProperLength && isNumber) {
        setPhoneNumber(value);
      }
    }
    if (type === "email") setEmailAddress(value);
  };

  const handleTestPhone = async () => {
    if (error) return;
    setTestLoading(true);
    let endpoint = `${config.API_BASE_URI}/teamAuth/test/phone`;
    try {
      let response = await axios.post(endpoint, {
        subscriberId: subscriber.id,
        SubscriptionPlanId: subscriber.SubscriptionPlanId,
        teamId: config.TEAM_ID,
        phoneNumber,
      });

      if (response.status === 200) {
        toast.success("Test success");
      }
    } catch (error) {
      toast.error(error.message);
    }
    setTestLoading(false);
  };

  const handleUpdatePhone = async () => {
    if (error) return;
    setSaveLoading(true);
    let endpoint = `${config.API_BASE_URI}/teamAuth/update/phone`;

    try {
      let response = await axios.post(endpoint, {
        subscriberId: subscriber.id,
        teamId: config.TEAM_ID,
        phoneNumber,
      });

      if (response.status === 200) {
        toast.success("Phone number update successfully");
      }
    } catch (error) {
      toast.error(error.message);
    }
    setSaveLoading(false);
  };

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box p={sm ? 2 : 4}>
        <Box>
          <Alert severity="info" className={classes.alert}>
            Please make sure the phone number provided can recieve text messages
          </Alert>
        </Box>
        <Box mt={3}>
          <FormControl variant="outlined" size="medium" required fullWidth>
            <InputLabel
              className={classes.inputLabel}
              htmlFor="contact-phone-number"
            >
              Phone Number
            </InputLabel>

            <OutlinedInput
              id="contact-phone-number"
              className={classes.input}
              type="text"
              value={phoneNumber}
              onChange={(event) => handleInputChange(event, "phone")}
              endAdornment={
                testLoading ? (
                  <CircularProgress
                    size={16}
                    style={{ marginLeft: 3, color: config.COLORS.primary }}
                  />
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.testButton}
                    onClick={handleTestPhone}
                  >
                    test
                  </Button>
                )
              }
            />
            <FormHelperText error={true}>{error}</FormHelperText>
          </FormControl>
        </Box>

        <Box mt={3}>
          <FormControl variant="outlined" size="medium" required fullWidth>
            <InputLabel className={classes.inputLabel} htmlFor="contact-email">
              Email address
            </InputLabel>
            <OutlinedInput
              disabled
              className={classes.input}
              id="contact-email"
              type="email"
              value={emailAddress}
              onChange={(event) => handleInputChange(event, "email")}
            />
          </FormControl>
        </Box>

        <Box mt={3}>
          <Button
            disabled={saveLoading}
            variant="contained"
            size="medium"
            className={classes.saveButton}
            disableElevation
            onClick={handleUpdatePhone}
          >
            Save
            {saveLoading && (
              <CircularProgress
                size={14}
                style={{ marginLeft: 5, color: colors.grey["600"] }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

import React from "react";
export default function Footer() {
  return (
    <div className="whole-footer">
      <div className="footer">
        <div className="badge-cont">
          {/* <img src={bluebadge} className='footer-badge' alt='logooovhvkj1' /> */}
        </div>
        <div className="links-cont">
          {/* <b>
          <p className="foot-texts">Privacy Policy</p>
        </b>
        <b>
          <p className="foot-texts">About us</p>
        </b>
        <b>
          <p className="foot-texts">Terms of services</p>
        </b>

        <b>
          <p className="foot-texts">Press</p>
        </b> */}
        </div>
      </div>
      <div className="copyright-cont">
        <p className="bottom-texts">Copyright 2022 Season Share Inc.</p>
      </div>
    </div>
  );
}

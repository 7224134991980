import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Hidden,
  Checkbox,
  Divider,
  colors,
} from "@material-ui/core";
import { ConfirmationNumberRounded } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import moment from "moment";
import config from "src/config";
import { useQuery } from "src/hooks/url_query_params";

export default function Passes({ subscribers }) {
  const classes = useStyles();
  const query = useQuery();
  const [activePasses, setActivePasses] = useState([]);
  const [selected, setSelected] = useState(null);

  // Handling initial load and url params change
  const handleInit = () => {
    // getting and setting active passes based
    // on subscriberId
    const subscriberId = Number(query.get("subscriberId"));
    const planCode = query.get("planCode");

    if (!subscriberId) return;
    // Setting active passes from the plansOnAccount
    // hide the plans that have portalVisibility 0

    /**
     * Bug : portal visibility working as expected, but the first plan is being
     * persisted from parent commponent, that's why it is showing wrong shedules
     *
     * Fix : Making the filters at parent component and making sure that the
     * url persistant is corrent.
     */
    const activePasses = subscribers.find(
      (s) => s.id == subscriberId
    ).plansOnAccount;

    // Making a default selection
    const selected = activePasses.find((p) => p.planCode == planCode); // p = plan
    setActivePasses(activePasses);
    setSelected(selected);
  };

  useEffect(handleInit, [query.params, subscribers]);

  // Handling selection upon click
  const handleSelect = (planCode) => query.set("planCode", planCode);

  return (
    <Paper variant="outlined">
      <Box p={2} pt={1} pb={1}>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          Plans On Account
        </Typography>
      </Box>
      <Divider />

      <Box p={2}>
        {activePasses.length > 0 &&
          activePasses.map((pass) => (
            <Box
              key={uuid()}
              className={classes.plan}
              p={2}
              onClick={() => handleSelect(pass.planCode)}
            >
              <Box>
                <ConfirmationNumberRounded
                  style={{ color: colors.grey["600"], fontSize: 32 }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.planTitle}
                >
                  {pass.planName}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.planDesc}
                >
                  {selected && selected.id === pass.id ? "" : "Tap to manage"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.planMeta}
                >
                  {/* Seat count */}
                  {pass.numSeats} Seats
                  {/* <Hidden xsDown> | </Hidden>{" "} */}
                  {/* <Hidden smUp>
                    <br />
                  </Hidden>{" "} */}
                  {/* Purchase date */}
                  {/* Purchase Date : {moment(pass.createdAt).format("MMM DD YYYY")} */}
                </Typography>
              </Box>
              <Box>
                <Checkbox
                  checked={selected && selected.id == pass.id}
                  style={{ color: config.COLORS.primary }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 22,
  },

  plan: {
    transition: "all 0.3s ease",
    marginBottom: 10,
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    display: "grid",
    gridTemplateColumns: "50px 1fr 30px",
    alignItems: "center",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 16px rgb(0 0 0 / 10%)",
      cursor: "pointer",
    },
  },
  planTitle: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 16,
  },
  planDesc: {
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: 1.3,
    fontSize: 14,
    color: config.COLORS.primary,
  },
  planMeta: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.3,
    marginTop: 2,
  },
  statusText: {
    color: colors.green["600"],
  },
}));

import * as types from "./actionTypes";
import {
  beginApiCall,
  apiCallError,
  endApiCall,
} from "../apiStatus/apiStatusActions";
import { purchaseApi } from "../../api/purchaseApi";
import { apiReponseError } from "../../api/config/apiRequestError";

export const purchasePass = (data) => {
  return (dispatch) => {
    dispatch(beginApiCall());
    return purchaseApi
      .purchasePass(data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.SUBSCRIBER,
            payload: response.data.subscriber,
          });
          dispatch({
            type: types.TEAM_ACCOUNT,
            payload: response.data.teamAccount,
          });
          dispatch({
            type: types.SUBSCRIPTION_PLAN,
            payload: response.data.subscriptonPlan,
          });
          return response; 
        }
      })
      .catch((error) => {
        dispatch(apiCallError());
        apiReponseError(error);
        return error;
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

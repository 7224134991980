import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useLocalStorage } from "src/components/localStorage";
import config from "src/config";

export default function ExitPortalButton() {
  const classes = useStyles();
  const reactLocalStorage = useLocalStorage();
  const [refreshToken, setRefreshToken] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_REFRESH_TOKEN`)
  );
  const [subscriber, setSubscriber] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_SUBSCRIBER`)
  );

  const signoutPortal = () => {
    reactLocalStorage.setItem(`${config.TEAM_ID}_MA_REFRESH_TOKEN`, null);
    reactLocalStorage.setItem(`${config.TEAM_ID}_MA_SUBSCRIBER`, null);
    setRefreshToken(null);
    setSubscriber(null);
  };

  return (
    <>
      {refreshToken && subscriber && (
        <Button
          className={classes.loginButton}
          size="large"
          disableElevation
          variant="contained"
          onClick={() => {
            signoutPortal();
          }}
        >
          EXIT PORTAL
        </Button>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  loginButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: "18px",
    padding: "10px 40px",
    borderRadius: 35,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 28px",
    },
  },
}));

import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  CircularProgress,
  Hidden,
  Checkbox,
  Divider,
  colors,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import moment from "moment";
import config from "src/config";
import { v4 as uuid } from "uuid";
import { useQuery } from "src/hooks/url_query_params";

export default function Subscribers({ subscribers }) {
  const classes = useStyles();
  const query = useQuery();
  const [selected, setSelected] = useState(null);

  // Handling initial load and url params change
  const handleInit = () => {
    const subscriberId = query.get("subscriberId");
    let selected = subscribers.find((subs) => subs.id == subscriberId);
    setSelected(selected);
  };

  useEffect(handleInit, [query.params]);

  // Handling selection
  const handleSelect = (id) => {
    query.set("subscriberId", id);
    query.set(
      "planCode",
      subscribers.find((s) => s.id == id).plansOnAccount[0].planCode
    );
  };

  return (
    <Paper variant="outlined">
      <Box p={2} pt={1} pb={1}>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          Manage Passes
        </Typography>
      </Box>
      <Divider />

      <Box p={2}>
        {subscribers.length > 0 &&
          subscribers.map((subscriber) => (
            <Box
              key={uuid()}
              className={classes.plan}
              p={2}
              onClick={() => handleSelect(subscriber.id)}
            >
              <Box>
                <AccountCircle
                  style={{ color: colors.grey["600"], fontSize: 38 }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.planTitle}
                >
                  {subscriber.fullName}
                </Typography>

                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.planDesc}
                >
                  {selected && selected.id === subscriber.id
                    ? ""
                    : "Tap to manage"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.planMeta}
                >
                  {/* Purchase date */}
                  Purchase Date :{" "}
                  {moment(subscriber.dateStarted).format("MMM DD YYYY")}
                  <Hidden smUp>
                    <br />
                  </Hidden>{" "}
                  <Hidden xsDown> | </Hidden> Status :{" "}
                  <span className={classes.statusText}>
                    {subscriber.status}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Checkbox
                  checked={selected && selected.id === subscriber.id}
                  style={{ color: config.COLORS.primary }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 22,
  },

  plan: {
    transition: "all 0.3s ease",
    marginBottom: 10,
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    display: "grid",
    gridTemplateColumns: "50px 1fr 30px",
    alignItems: "center",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 16px rgb(0 0 0 / 10%)",
      cursor: "pointer",
    },
  },
  planTitle: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 16,
  },
  planDesc: {
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: 1.3,
    fontSize: 14,
    color: config.COLORS.primary,
  },
  planMeta: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.3,
    marginTop: 5,
  },
  statusText: {
    color: colors.green["600"],
  },
}));

import React, { useState } from "react";
import {
  // Button,
  makeStyles,
  colors,
  Box,
  Typography,
  fade,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Popup from "src/components/Popup";
import { CloseOutlined } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import config from "src/config";

export const withPopup =
  (Button) =>
  ({ buttonText, className, confirmText, action, disabled, loading }) => {
    const styles = useStyles();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const handleConfirmDialog = (open) => setConfirmOpen(open);
    const handleConfirm = async () => {
      action();
      handleConfirmDialog(false);
    };

    return (
      <>
        <Button
          {...{ disabled, className }}
          fullWidth
          size="small"
          disableElevation
          variant="contained"
          onClick={() => handleConfirmDialog(true)}
          endIcon={
            loading && (
              <CircularProgress style={{ color: colors.grey[800] }} size={14} />
            )
          }
        >
          {buttonText}
        </Button>

        <Popup
          open={confirmOpen}
          closeButtonProps={{
            canCloseByUser: loading ? false : true,
            backgroundColor: config.COLORS.primary,
            closeIcon: (
              <IconButton onClick={() => handleConfirmDialog(false)}>
                <CloseOutlined style={{ color: "white" }} />
              </IconButton>
            ),
          }}
        >
          <Box>
            <Alert
              severity="warning"
              variant="standard"
              classes={{
                standardWarning: styles.warningAlert,
              }}
            >
              <AlertTitle className={styles.alertTitle}>Warning</AlertTitle>
              <Typography className={styles.alertSubtitle}>
                {confirmText}
              </Typography>
            </Alert>
          </Box>
          <Box p={2} pt={0} display="flex" justifyContent="flex-end">
            <Box>
              <Button
                disabled={loading}
                disableElevation
                variant="contained"
                size="small"
                className={styles.button}
                onClick={() => handleConfirmDialog(false)}
              >
                No
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                disabled={loading}
                disableElevation
                variant="contained"
                size="small"
                className={styles.button}
                onClick={handleConfirm}
                endIcon={
                  loading && (
                    <CircularProgress
                      style={{ color: colors.grey[600] }}
                      size={12}
                    />
                  )
                }
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Popup>
      </>
    );
  };

const useStyles = makeStyles((theme) => ({
  warningAlert: {
    backgroundColor: "transparent",
  },
  alertTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.text.primary,
  },

  alertSubtitle: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  confirmButton: {
    backgroundColor: theme.palette.warning.main,
    color: "white",

    "&:hover": {
      backgroundColor: fade(theme.palette.warning.main, 0.8),
    },
  },
  button: {
    backgroundColor: colors.grey[200],
    fontFamily: "Poppins",
    fontWeight: 600,
  },
}));

import axios from "axios";
import config from "src/config";
import { toast } from "react-toastify";
import actionTypes from "./actionTypes";

const endpoints = {
  getTeamConfig: `${config.ADMIN_URI}/teams/team_configuration`,
};

export const getTeamConfig = () => async (dispatch) => {
  const teamId = config.TEAM_ID;

  try {
    const response = await axios.post(endpoints.getTeamConfig, { teamId });
    response.status === 200 &&
      dispatch({
        type: actionTypes.getTeamConfig,
        payload: response.data.team_configuration,
      });
  } catch (error) {
    toast.error(
      error?.response?.data?.message ||
        error?.response?.data ||
        "Something went wrong, please try again"
    );
  }
};

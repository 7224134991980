import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Paper,
  Typography,
  colors,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import SubscriptionDetails from "./SubscriptionDetails";
import CardDetails from "./CardDetails";
import SubscriptionAction from "./SubscriptionAction";
import { useLocalStorage } from "src/components/localStorage";
import config from "src/config";
import PlanDetails from "./PlanDetails";
import {
  getSubscriptionDetails,
  updateSubscriptionDetails,
  getCCDetails,
  updateCCDetails,
  errorToast,
} from "./api_calls";

export default function ManageSubscription() {
  const classes = useStyles();
  const teamId = config.TEAM_ID;
  const reactLocalStorage = useLocalStorage();
  const [subscriber] = useState(
    reactLocalStorage.getItem(`${teamId}_MA_SUBSCRIBER`)
  );
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  const [isNextPlan, setIsNextPlan] = useState(false);

  const getSubscription = async () => {
    const { emailAddress } = subscriber;
    const response = await getSubscriptionDetails({ emailAddress });
    const details = response.data;
    setSubscriptionDetails(details);
  };
  const updateSubscription = async ({ status }) => {
    const { emailAddress } = subscriber;
    await updateSubscriptionDetails({ emailAddress, status });
    await getSubscription();
  };

  const getCard = async () => {
    try {
      const { accountNumber, id: subscriberId } = subscriber;
      const response = await getCCDetails({ accountNumber, subscriberId });
      const details = response.data[0];
      if (details) {
        setCardDetails(details);
      }
      return;
    } catch (error) {
      console.log(error);
      errorToast(error);
    }
  };

  const saveNewCard = async (card) => {
    const subscriberId = subscriber.id;
    const { accountNumber: acctId } = subscriber;
    const response = await updateCCDetails({ subscriberId, acctId, ...card });
    await getCard();
    return response;
  };

  const checkNextPlan = () => {
    const { nextPlanId, planId } = subscriptionDetails;
    planId === nextPlanId && setIsNextPlan(true);
  };

  useEffect(() => {
    if (subscriber) {
      getSubscription();
      getCard();
    }
  }, [subscriber]);

  useEffect(() => {
    if (subscriptionDetails) {
      checkNextPlan();
    }
  }, [subscriptionDetails]);

  return (
    <>
      <Paper variant="outlined">
        <Box p={2} pt={1} pb={1}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.sectionTitle}
          >
            Manage Subscription
          </Typography>
        </Box>
        <Divider />

        {!subscriptionDetails ? (
          <Box p={8} textAlign="center">
            <CircularProgress
              size={20}
              style={{ color: config.COLORS.primary }}
            />
          </Box>
        ) : (
          <Box p={3}>
            <Box>
              <PlanDetails {...{ subscriptionDetails }} />
            </Box>
            <Box mt={2}>
              <CardDetails {...{ cardDetails, saveNewCard }} />
            </Box>
            <Box mt={2}>
              <SubscriptionDetails {...{ subscriptionDetails, isNextPlan }} />
            </Box>

            {!isNextPlan && (
              <Box mt={2}>
                <SubscriptionAction
                  {...{ subscriptionDetails, updateSubscription }}
                />
              </Box>
            )}
          </Box>
        )}
      </Paper>
      <Box mb={24} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 22,
  },
  metaText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 14,
    color: colors.grey[600],
  },
  statusActive: {
    color: colors.green[600],
  },
}));

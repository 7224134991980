import React, { useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  Typography,
  Box,
  Divider,
  Avatar,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  colors,
  CircularProgress,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import {
  DateRangeRounded,
  ReportProblemOutlined,
  EditOutlined,
  AddRounded,
} from "@material-ui/icons";
import config from "src/config";
import clsx from "clsx";
import EventImage from "src/images/SA.png";
import { useLocalStorage } from "src/components/localStorage";
import { toast } from "react-toastify";
import axios from "axios";
import { useQuery } from "src/hooks/url_query_params";
import AttendancePopup from "./AttendancePopup";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";

export default function EventHistory({ events, eventLoading, canSitWithFriend }) {
  const classes = useStyles();
  const theme = useTheme();
  const query = useQuery();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState([]);

  const date = moment(new Date()).format("MMM DD YYYY");

  const handleInit = () => {
    const planCode = query.get("planCode");
    setEventData(events[planCode] || []);
  };

  useEffect(handleInit, [query.params, events]);

  return (
    <Paper variant="outlined">
      <Box p={2} pt={1} pb={1}>
        <Typography variant="h5" className={classes.cardTitle} color="textPrimary">
          Schedule
        </Typography>
      </Box>
      <Divider />

      {!eventLoading ? (
        <Box p={3}>
          {eventData.length > 0 ? (
            eventData.map((event) => <Event key={uuid()} {...{ event, canSitWithFriend }} />)
          ) : (
            <Alert severity="warning">Schedule is not available at this time.</Alert>
          )}
        </Box>
      ) : (
        <Box textAlign="center" p={4}>
          <CircularProgress size={18} style={{ color: config.COLORS.primary }} />
        </Box>
      )}
    </Paper>
  );
}

const Event = ({ event, canSitWithFriend }) => {
  const classes = useStyles();
  const query = useQuery();
  const [isPast, setIsPast] = useState();

  const calculatePast = () => {
    if (!event) return;
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const today = date.getTime();
    const eventDate = new Date(moment(event.date)).getTime();
    const diff = (today - eventDate) / 1000 / 60 / 60 / 24;
    diff > 0 ? setIsPast(true) : setIsPast(false);
  };

  useEffect(() => {
    calculatePast();
  }, [event]);

  return (
    <Box key={uuid()} className={classes.plan} p={2}>
      {event.logoUrl ? (
        <Box className={classes.eventImage}>
          <img src={event.logoUrl} alt="" />
        </Box>
      ) : (
        <Box>
          <DateRangeRounded
            style={{
              color: colors.grey["600"],
              fontSize: 32,
            }}
          />
        </Box>
      )}
      <Box>
        <Typography variant="h6" color="textPrimary" className={classes.planTitle}>
          {event.description}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" className={classes.planMeta}>
          {moment(event.date).format("MMM Do")}{" "}
          {event.time != "TBA" && moment(event.time, "HH:mm").format("hh:mm a")}
          {event.time == "TBA" && "TBA"}
        </Typography>

        <Box>
          {[0, 1].includes(event.attended) && (
            <Typography className={classes.planMeta} variant="subtitle1" color="textSecondary">
              {isPast ? "ATTENDED :" : "ATTENDING :"}{" "}
              <span className={classes[`attn_${event.attended === 1 ? "yes" : "no"}`]}>
                {event.attended === 1 ? "YES" : "NO"}
              </span>
            </Typography>
          )}
        </Box>

        {isPast && (
          <Box>
            <Typography className={classes.planMeta} variant="subtitle1" color="textSecondary">
              <span className={classes.passedWarning}>
                <ReportProblemOutlined className={classes.metaWarningIcon} />
                PASSED
              </span>
            </Typography>
          </Box>
        )}

        {!!event.enableReply && !isPast && (
          <Box display="flex" flexWrap="wrap">
            {[0, 1].includes(event.attended) ? (
              <Box mt={1}>
                <Button
                  className={clsx(classes.button, classes.editButton)}
                  disableElevation
                  size="small"
                  variant="contained"
                  startIcon={<EditOutlined />}
                  onClick={() => query.set("attendance", event.eventCode)}
                >
                  Change Attendance
                </Button>
              </Box>
            ) : (
              <Box mt={1} mr={1}>
                <Button
                  className={classes.button}
                  disableElevation
                  size="small"
                  variant="contained"
                  onClick={() => query.set("attendance", event.eventCode)}
                  startIcon={<AddRounded />}
                >
                  Attend Game
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: 24,

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },

  // New styles
  plan: {
    transition: "all 0.3s ease",
    marginBottom: 10,
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    display: "grid",
    gridTemplateColumns: "50px 1fr",
    alignItems: "center",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 16px rgb(0 0 0 / 10%)",
      cursor: "pointer",
    },
  },
  planTitle: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 16,
  },
  planDesc: {
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: 1.3,
    fontSize: 14,
    color: config.COLORS.primary,
  },
  planMeta: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.3,
    marginTop: 2,
  },
  statusText: {
    color: colors.green["600"],
  },

  passedWarning: {
    color: theme.palette.warning.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  metaWarningIcon: {
    fontSize: 20,
    marginRight: 3,
    marginTop: -2,
  },

  eventImage: {
    "& img": {
      width: 40,
    },
  },
  button: {
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,
    fontSize: 12,
    fontWeight: 700,
    padding: "2px 15px",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },

  editButton: {
    backgroundColor: colors.grey[600],
  },

  attn_yes: {
    color: colors.green[600],
  },
  attn_no: {
    color: colors.red[600],
  },
}));

import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Avatar,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import config from "src/config";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocalStorage } from "src/components/localStorage";

export default function MyGroup({ handleChange, canSitWithFriend }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const reactLocalStorage = useLocalStorage();
  const [loading, setLoading] = useState(true);
  const [leaveLoading, setLeaveLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [subscriber, setSubscriber] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_SUBSCRIBER`)
  );
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);

  // useEffect(() => {
  //   console.log(members);
  // }, [members]);

  const handleLeaveDialog = (open) => {
    setLeaveDialogOpen(open);
  };

  useEffect(() => {
    if (subscriber) {
      getGroupMembers();
    }
  }, [subscriber]);

  const getGroupMembers = async () => {
    setLoading(true);
    let endpoint = `${config.ADMIN_URI}/friends/mygroup`;
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        subscriberId: subscriber.id,
      });

      if (response.status === 200) {
        let members = response.data;
        members.length > 0 ? setMembers(members) : setMembers([subscriber]);
      }
    } catch (error) {
      setMembers([subscriber]);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setLoading(false);
  };

  const leaveGroup = async () => {
    setLeaveLoading(true);
    let endpoint = `${config.ADMIN_URI}/friends/leave`;
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        subscriberId: subscriber.id,
      });

      if (response.status === 200) {
        await getGroupMembers();
        setLeaveDialogOpen(false);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setLeaveLoading(false);
  };

  const Member = ({ member }) => (
    <Grid key={uuid()} item xs={12}>
      <Paper variant="outlined">
        <Box p={sm ? 2 : 3} className={classes.profileItem}>
          <Box className={classes.profileImage}>
            <Avatar />
          </Box>

          <Box className={classes.profileContent}>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.profileTitle}
            >
              {member.fullName}
            </Typography>

            {member.invitedBy?.length > 0 && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.profileSubtitle}
              >
                Invited by{" "}
                <span className={classes.invitedBy}>{member.invitedBy}</span>
              </Typography>
            )}

            {/* <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.profileSubtitle}
            >
              {member.emailAddress}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.profileSubtitle}
            >
              {member.phoneNumber}
            </Typography> */}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );

  return (
    <Box>
      <ToastContainer />
      <Grid container justify="center">
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            {loading ? (
              <CircularProgress
                size={16}
                style={{ color: config.COLORS.primary }}
              />
            ) : (
              <>
                <Grid item xs={12} lg={8}>
                  <Box>
                    <Box textAlign="center">
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        className={classes.title}
                      >
                        My Group
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        style={{ lineHeight: 1.2, marginTop: 10 }}
                      >
                        See your Ticket Group members here.
                        {canSitWithFriend == true && (
                          <span>
                            <span
                              className={classes.clickableText}
                              onClick={(e) => handleChange(e, 3)}
                            >
                              Click here
                            </span>{" "}
                            to invite a friend to sit next to you
                          </span>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {members.map((member) => (
                  <Member key={uuid()} {...{ member }} />
                ))}
              </>
            )}

            {members.length > 0 && canSitWithFriend == true && (
              <Grid item xs={12}>
                <Box mt={3}>
                  <Box textAlign="center">
                    {members.length > 1 && canSitWithFriend == true && (
                      <Button
                        variant="contained"
                        className={classes.leaveButton}
                        onClick={() => handleLeaveDialog(true)}
                      >
                        Leave Group
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={leaveDialogOpen}
        onClose={() => handleLeaveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle}>
          {"Are you sure you want to leave your Ticket Group?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogDesc}>
            All outstanding friend requests will be cancelled. You will need to
            send requests again if you want to sit next to friends.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleLeaveDialog(false)} color="primary">
            cancel
          </Button>
          <Button
            variant="contained"
            className={classes.dialogConfirmButton}
            onClick={leaveGroup}
            autoFocus
          >
            Leave
            {leaveLoading && (
              <CircularProgress
                size={12}
                style={{
                  marginLeft: 5,
                  marginTop: -3,
                  color: "white",
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Raleway",
    fontWeight: 800,
  },
  profileItem: {
    display: "grid",
    gridTemplateColumns: "60px 1fr",
    alignItems: "center",
  },
  profileTitle: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: "Poppins",
  },
  actionButton: {
    backgroundColor: config.COLORS.primary,
    fontSize: 14,
    // padding: "8px 15px",
    borderRadius: 30,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },

  profileAction: {
    display: "flex",
    justifyContent: "flex-end",
  },

  leaveButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "10px 20px",
    fontFamily: "Raleway",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
  dialogTitle: {
    "& .MuiTypography-root": {
      fontFamily: "Poppins",
      fontWeight: 600,
    },
  },

  dialogDesc: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 18,
  },
  dialogConfirmButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Raleway",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
  clickableText: {
    color: config.COLORS.primary,
    textDecoration: "underline",
    fontWeight: 500,
    cursor: "pointer",
  },
  invitedBy: {
    color: config.COLORS.primary,
  },
}));

import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  FormControl,
  OutlinedInput,
  InputLabel,
  makeStyles,
  fade,
  CircularProgress,
  useTheme,
  useMediaQuery,
  colors,
} from "@material-ui/core";
import config from "src/config";

export default function VerifyEmail({ exclusiveEmailVerify }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await exclusiveEmailVerify(email);
    setLoading(false);
  };

  return (
    <Paper className={classes.root} elevation={4}>
      <Box p={2} pt={6} pb={6}>
        <Box>
          <Typography
            variant="h3"
            color="textPrimary"
            className={classes.title}
            gutterBottom
          >
            {config.UI_LANGUAGE.VerifyEmail.title}
          </Typography>
          <Box pl={sm ? 2 : 5} pr={sm ? 2 : 5}>
            <Typography
              variant="subtitle1"
              className={classes.subtitle}
              color="textSecondary"
            >
              {config.UI_LANGUAGE.VerifyEmail.description}
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mt={3} pl={sm ? 2 : 5} pr={sm ? 2 : 5}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel
                className={classes.inputLabel}
                htmlFor="email-address"
              >
                Email Address
              </InputLabel>
              <OutlinedInput
                type="email"
                value={email}
                onChange={handleChange}
              />
            </FormControl>
          </Box>

          <Box mt={3} display="flex" justifyContent="center">
            <Button
              disableElevation
              disabled={loading}
              variant="contained"
              size="large"
              type="submit"
              className={classes.button}
            >
              Verify
              {loading && (
                <CircularProgress
                  size={14}
                  style={{ marginLeft: 10, color: colors.grey["500"] }}
                />
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 32,
    marginBottom: 32,
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: "700",
  },
  subtitle: {
    fontFamily: "Raleway",
    fontSize: "18px !important",
    fontWeight: "500",
    textAlign: "center",
  },

  inputLabel: {
    "&.MuiInputLabel-shrink": {
      backgroundColor: "white",
    },
  },
  button: {
    backgroundColor: config.COLORS.secondary,
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "15px 100px",
    fontFamily: "Raleway",
    "&:hover": {
      backgroundColor: fade(config.COLORS.secondary, 0.8),
    },
  },
}));

import React from "react";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  useTheme,
  useMediaQuery,
  ButtonGroup,
  Button,
  Divider,
} from "@material-ui/core";
import config from "src/config";

export default function Product({ product, changeSeatCount }) {
  const classes = useStyles();
  const theme = useTheme();
  const { planName, planDescription, currentSeats, oneTimeCost } = product;

  return (
    <Paper className={classes.paper} variant="outlined">
      <Box p={3} className={classes.productWrapper}>
        <Box>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            {planName}
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="subtitle1"
            color="textSecondary"
          >
            {planDescription}
          </Typography>
        </Box>

        <Box className={classes.buttonWrapper}>
          <ButtonGroup>
            <Button
              variant="outlined"
              onClick={() => changeSeatCount({ increment: false, product })}
            >
              -
            </Button>
            <Button variant="outlined" className={classes.counter}>
              {currentSeats}
            </Button>
            <Button
              variant="outlined"
              onClick={() => changeSeatCount({ increment: true, product })}
            >
              +
            </Button>
          </ButtonGroup>
        </Box>
        <Divider orientation="vertical" />
        <Box className={classes.metaWrapper}>
          <Typography variant="subtitle2" color="textSecondary">
            ONE TIME COST : ${oneTimeCost}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            TOTAL AMOUNT : ${currentSeats * oneTimeCost}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 8,
    borderLeft: `3px solid ${config.COLORS.primary}`,
  },

  productWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 15px 1fr",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
    },
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 18,
  },
  subtitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.2,
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  counter: {
    cursor: "default",
    pointerEvents: "none",
  },
}));

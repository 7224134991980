import React, { useState, useEffect } from "react";
import config from "src/config";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Paper,
  Divider,
  makeStyles,
  Checkbox,
  Hidden,
  CircularProgress,
  TextField,
  Grid,
  Button,
  colors,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";
import clsx from "clsx";
import * as yup from "yup";
import moment from "moment";
import Subscribers from "src/components/Subscribers";
import Passes from "src/components/Passes";
import EventHistory from "src/components/EventHistory";
import { useQuery } from "src/hooks/url_query_params";
import AttendancePopup from "src/components/AttendancePopup";
import { HighlightOff } from "@material-ui/icons";
import { getAccountSummary, getEvents } from "./api_calls";
import ErrorPopup from "src/components/ErrorPopup";
import { settingsApi } from "src/api/settingsApi";

export default function PassOverView() {
  const classes = useStyles();
  const [subscribers, setSubscribers] = useState([]);
  const [events, setEvents] = useState(null);
  const [eventLoading, setEventLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  const [canSitWithFriend, setCanSitWithFriend] = useState(undefined);

  const setDefaultParams = () => {
    let subscriberId = subscribers[0].id;
    let planCode = subscribers[0]?.plansOnAccount[0]?.planCode;
    let attendance = "closed";
    query.set("subscriberId", subscriberId);
    query.set("planCode", planCode);
    query.set("attendance", attendance);
  };

  const calculatePastEvent = (event) => {
    if (!event) return false;
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const today = date.getTime();
    const eventDate = new Date(moment(event.date)).getTime();
    const diff = (today - eventDate) / 1000 / 60 / 60 / 24;
    return diff > 0 ? true : false;
  };

  const handleInit = () => {
    setLoading(true);
    // if subscribers are not available then return
    // and wait for accountSummary to load
    if (!(subscribers.length > 0)) return;

    // Checking if the params defined in url
    let isDefined =
      /subscriberId/g.test(query.params) &&
      /planCode/g.test(query.params) &&
      /attendance/g.test(query.params);

    // if params are not present in url, then set them initially
    if (!isDefined) {
      setDefaultParams();
    }

    // at this stage if no event found
    // waits for events to load based on url subscriberId
    if (!events) return;

    // getting params from url
    const subscriberId = query.get("subscriberId");
    const planCode = query.get("planCode");
    const attendance = query.get("attendance"); // eventCode

    // validating url
    const subscriber = subscribers?.find((s) => s.id == subscriberId); // s = subscriber
    const plan = subscriber?.plansOnAccount?.find(
      (p) => p.planCode == planCode
    ); // p = plan
    const event = events[plan?.planCode]?.find(
      (e) => e.eventCode == attendance
    ); // e = event

    const isPastEvent = calculatePastEvent(event);

    // --> converting values to boolean by prefixing with !!
    const isValid =
      !!subscriber &&
      !!plan &&
      (!!event || attendance == "closed") &&
      !isPastEvent;

    // if given subscriberId, plan, attendance are exists
    // in the api responses then no error will throw and loading will stop
    // otherwise throw error
    if (!isValid) {
      setError(true);
      setDefaultParams();
    }
    setLoading(false);
  };

  const getSummary = async () => {
    setLoading(true);
    let subscribers = await getAccountSummary();

    /**
     * Bug : Setting visibility wise plans in Passes component works as expected,
     * but the first plan selection is being persisted from here.
     *
     * Fix : Make the visibility filter from here, making sure that the persistant is accurate
     */
    let filteredSubs = subscribers.map((subscriber) => {
      let filteredPlans = subscriber.plansOnAccount.filter(
        (plan) => plan.portalVisibility === 1
      );
      subscriber.plansOnAccount = filteredPlans;
      return subscriber;
    });

    setSubscribers(filteredSubs);
    setLoading(false);
  };

  const getEventsInPlan = async () => {
    setEventLoading(true);
    let subscriberId = query.get("subscriberId");
    if (!subscriberId) return;
    let events = await getEvents(subscriberId);
    setEvents(events);
    setEventLoading(false);
  };

  const getTeamConfig = async () => {
    setEventLoading(true);
    try {
      let res = await settingsApi.getTeamSettings();
      let swaf =
        res?.data?.team_configuration?.canSitWithFriend == "1" ? true : false;
      setCanSitWithFriend(swaf);
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message ||
          err?.response?.data ||
          "Error enabling Sit With Friends. Contact Support."
      );
    }
    setEventLoading(false);
  };

  useEffect(() => {
    getTeamConfig();
  }, []);

  useEffect(() => {
    getSummary();
  }, []);

  useEffect(() => {
    getEventsInPlan();
  }, [query.get("subscriberId")]);

  useEffect(handleInit, [query.params, subscribers, events]);

  return (
    <>
      {loading ? (
        <Box textAlign="center" mt={8}>
          <CircularProgress
            size={24}
            style={{ color: config.COLORS.primary }}
          />
        </Box>
      ) : (
        <Box>
          <AttendancePopup
            {...{ subscribers, events, getEventsInPlan, error }}
          />
          <Box>
            <Subscribers {...{ subscribers }} />
          </Box>
          <Box mt={3}>
            <Passes {...{ subscribers }} />
          </Box>
          <Box mt={3}>
            <EventHistory {...{ events, eventLoading, canSitWithFriend }} />
          </Box>
        </Box>
      )}

      {error && (
        <ErrorPopup title="Oops.." subtitle="This event can't be replied" />
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.error.light,
    fontSize: 48,
  },
  errorTitle: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 22,
  },
}));

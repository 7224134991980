import React from "react";
import Alert from "@material-ui/lab/Alert";
import {
  Switch,
  makeStyles,
  fade,
  Box,
  Typography,
  lighten,
} from "@material-ui/core";
import config from "src/config";

interface PropTypes {
  handleManualSwitch: (arg: boolean) => void;
  isManual: boolean;
}

export const ManualAddressNotice = ({
  handleManualSwitch,
  isManual,
}: PropTypes): JSX.Element => {
  const styles = useStyles();

  return (
    <Alert
      severity="info"
      classes={{
        root: styles.background,
      }}
      icon={
        <Switch
          size="small"
          color="primary"
          checked={isManual}
          onChange={() => handleManualSwitch(!isManual)}
          classes={{
            checked: styles.switchChecked,
          }}
        />
      }
    >
      <Box>
        <Typography variant="h6" className={styles.title}>
          MANUAL ADDRESS INPUT
        </Typography>
      </Box>

      <Box>
        <Typography variant="caption" className={styles.description}>
          Enable this switch if you would like to input your address manually.
          Please note, your address must match the address your bank has on
          file.
        </Typography>
      </Box>
    </Alert>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: fade(config?.COLORS?.primary, 0.06),
  },

  title: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: config?.COLORS?.primary,
    fontSize: 14,
  },

  description: {
    color: lighten(config?.COLORS?.primary, 0.2),
    lineHeight: 1.1,
  },

  switchChecked: {
    "& .MuiSwitch-thumb": {
      backgroundColor: config?.COLORS.primary,
    },
  },
}));

import React from "react";
import BannerImage from "src/images/sandbox/banner.jpg";
import LogoImage from "src/images/sandbox/logo.png";
const PAGE_TITLES = {
  home: {
    heading: "SANDBOX STUDENT PASS",
    subtitle: "Pick your favorite combination of matchups and seat location.",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "MEMBERSHIP PORTAL",
    subtitle: "Manage your pass settings, make changes and send friend requests.",
  },
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "REGISTER YOUR PASS",
    description:
      "Please register your pass by connecting the Ticketmaster account you used to purchase your pass.",
  },

  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "PASS OPTIONS",
  membershipSection: {
    title: "MANAGE YOUR PASS",
    question: "Already a passholder?",
    description: "Log In to manage your existing pass.",
  },
  announcementBarText: "Sit with a friend will re-open on Thursday 9/9 for the game on 9/18.",
  registerTerms: {
    enabled: false,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program Terms and Conditions.
      </p>
    ),
  },
};

const UI_DIMENSION = {
  eventImageWidth: 50,
};

export const sandbox = {
  SITE_TITLE: "FLORIDA GATORS",
  TEAM_NAME: "Florida Gators",
  TEAM_ID: 999,
  PRODUCT_NAME: "STUDENT PASS",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  IS_RECURRING: false,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://devpassbackend.seasonshare.com/api",
  SS_FE_BASE_URI: "https://sandboxpass.seasonshare.com",
  ADMIN_BASE_URI: "https://subs-janitor.herokuapp.com/api",
  ADMIN_URI: "https://subs-janitor.herokuapp.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#57837B", //"",
    secondary: "#515E63",
    typography: "#130c0e",
    contrastText: "white",
    heroOverlay: 0.6,
    membershipOverlay: 0.6,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  PAGE_TITLES,
  UI_LANGUAGE,
  UI_DIMENSION,
};

import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import EmptyImage from "src/images/empty.png";

export default function EmptyPass() {
  return (
    <Paper elevation={6} style={{ marginBottom: 25 }}>
      <Box p={4} display="flex" justifyContent="flex-start" alignItems="center">
        <Box>
          <img src={EmptyImage} alt="" />
        </Box>
        <Box pl={4}>
          <Typography
            variant="h6"
            color="textPrimary"
            style={{
              fontFamily: "Raleway",
              fontWeight: "bold",
            }}
          >
            Howdy!
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{
              fontFamily: "Raleway",
              fontWeight: "bold",
            }}
          >
            This pass is no longer for sale. <a href="/">Click here</a> to view
            active passes or subscribe for alerts
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

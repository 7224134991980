import React from "react";
const BannerImage =
  "https://ss-leagues.s3.amazonaws.com/NBA/2122_HWK_TX_Campaign_HawksPass_2000x750%20%282%29.jpg";
const LogoImage = "https://ss-leagues.s3.amazonaws.com/NBA/hawks_logo_oct_21.png";

const PAGE_TITLES = {
  home: {
    heading: "",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "",
    subtitle: "",
  },
};

const SITE_SEO = {
  title: "ATLANTA HAWKS",
  description: "Atlanta Hawks Pass",
  favicon: LogoImage,
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "Verify your email address",
    description:
      "Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "Ticket Options",
  membershipSection: {
    title: "Manage Your Atlanta Hawks Pass",
    question: "Returning Atlanta Hawks Pass buyer?",
    description: "Click the button below to look up and manage your existing Atlanta Hawks Pass.",
  },
  announcementBarText: "",

  registerTerms: {
    enabled: true,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://uploads-ssl.webflow.com/5ed7df6fe67e862e0a62f9f2/616882d2b691e09f75348396_Terms%20%26%20Conditions.pdf"
          className="register_terms_link"
        >
          Terms and Conditions.
        </a>
      </p>
    ),
  },
};

const UI_DIMENSION = {
  eventImageWidth: 80,
};

export const hawks = {
  SITE_TITLE: "ATLANTA HAWKS",
  TEAM_NAME: "ATLANTA HAWKS",
  TEAM_ID: 53,
  PRODUCT_NAME: "ATLANTA HAWKS PASS",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  IS_RECURRING: false,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: true,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://passbackend.seasonshare.com/api",
  SS_FE_BASE_URI: "https://hawkspass.seasonshare.com",
  ADMIN_BASE_URI: "https://subsjanitor.seasonshare.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#C8102E",
    secondary: "#FDB927",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0,
    membershipOverlay: 0,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

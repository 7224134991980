import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./state/configureStore";
import { LocalStorageProvider } from "src/components/localStorage";
import { AxiosProvider } from "src/components/axiosInterceptor";
import { CrispProvider } from "src/components/CrispContext";

const store = configureStore();
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <LocalStorageProvider>
      <AxiosProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AxiosProvider>
    </LocalStorageProvider>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

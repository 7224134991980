import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  FormControl,
  OutlinedInput,
  InputLabel,
  makeStyles,
  fade,
  CircularProgress,
  useTheme,
  useMediaQuery,
  colors,
} from "@material-ui/core";
import config from "src/config";
import { teamAuthApi } from "src/api/teamAuthApi";
import axios from "axios";
import { useLocation, useParams } from "react-router";
import LoginNotice from "../PurchasePlan/LoginNotice";
import { ReportProblemOutlined } from "@material-ui/icons";
const queryString = require("query-string");

export default function ConnectTeamAccount({ exclusiveEmailVerify }) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [teamAuthUrl, setTeamAuthUrl] = useState("");
  const [isTeamConnected, setIsTeamConnected] = useState();
  const [connectedAccountName, setConnectedAccountName] = useState("");

  useEffect(() => {
    // get MA URL
    teamAuthApi
      .getTeamUrl()
      .then((res) => {
        setTeamAuthUrl(res.url);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await exclusiveEmailVerify(email);
    setLoading(false);
  };

  const connectTeamAccount = () => {
    const parsed = queryString.parse(location.search);
    const eventCode = parsed.eventCode;
    window.mixpanel.track("Connect Team Account", {});
    let replaced = teamAuthUrl.replace(
      "REDIRECT_URL",
      `${config.SS_FE_BASE_URI}/tmcallback/register-pass?eventCode=${eventCode}`
    ); // redirect back to where we came from, handle in params
    let finalUrl = replaced.replace("STATE", `PURCHASE-${config.TEAM_ID}`);
    // console.log(finalUrl);
    window.location.href = `${finalUrl}`;
  };

  if (!isTeamConnected) {
    return (
      <>
        {config?.TEAM_ID !== 182 ? (
          <Paper className={classes.root} elevation={4}>
            <Box p={2} pt={6} pb={6}>
              <Box>
                <Typography variant="h3" color="textPrimary" className={classes.title} gutterBottom>
                  {config.UI_LANGUAGE.verifyEmail.title}
                </Typography>
                <Box pl={sm ? 2 : 5} pr={sm ? 2 : 5}>
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    color="textSecondary"
                  >
                    {config.UI_LANGUAGE.verifyEmail.description}
                  </Typography>
                </Box>
              </Box>
              <form onSubmit={connectTeamAccount}>
                {/* <Box mt={3} pl={sm ? 2 : 5} pr={sm ? 2 : 5}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel
                  className={classes.inputLabel}
                  htmlFor="email-address"
                >
                  Email Address
                </InputLabel>
                <OutlinedInput
                  type="email"
                  value={email}
                  onChange={handleChange}
                />
              </FormControl>
            </Box> */}

                <Box mt={3} display="flex" justifyContent="center">
                  <Button
                    disableElevation
                    disabled={loading}
                    variant="contained"
                    size="large"
                    onClick={() => {
                      connectTeamAccount();
                    }}
                    type="button"
                    className={classes.button}
                  >
                    {config.UI_LANGUAGE.teamAccountButton}
                    {loading && (
                      <CircularProgress
                        size={14}
                        style={{ marginLeft: 10, color: colors.grey["500"] }}
                      />
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>
        ) : (
          <Paper className={classes.root} elevation={4}>
            <Box p={2} pt={6} pb={6}>
              <Box>
                <Box pl={sm ? 2 : 5} pr={sm ? 2 : 5} display="flex" justifyContent={"center"}>
                  <ReportProblemOutlined
                    style={{
                      fontSize: "42px",
                      color: "#ffa726",
                    }}
                  />
                </Box>
                <Box pl={sm ? 2 : 5} pr={sm ? 2 : 5} mt="15px">
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    color="textSecondary"
                  >
                    Check back later to register your 2022 Gators Student Football Pass.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        )}
      </>
    );
  } else {
    return <LoginNotice accountUserName="alim" />;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: 32,
    // marginBottom: 32,
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: "700",
  },
  subtitle: {
    fontFamily: "Raleway",
    fontSize: "18px !important",
    fontWeight: "500",
    textAlign: "center",
  },

  inputLabel: {
    "&.MuiInputLabel-shrink": {
      backgroundColor: "white",
    },
  },
  button: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "15px 100px",
    fontFamily: "Raleway",
    "&:hover": {
      backgroundColor: fade(config.COLORS.secondary, 0.8),
    },
    [theme.breakpoints.down("xs")]: {
      padding: "15px 20px",
    },
  },
}));

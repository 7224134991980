import React from "react";
import config from "src/config";
import { Box, Typography, Button, makeStyles, Grid } from "@material-ui/core";
import LoginButton from "src/components/LoginButton";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Raleway",
    fontWeight: "bold",
  },
  heading: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: "32px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
    },
  },
  subTitle: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: "22px !important",
  },
}));

export default function Membership({ loginUser }) {
  const classes = useStyles();
  return (
    <Box style={{ backgroundColor: "#DCDCDC", overflow: "hidden" }}>
      <Box
        height={80}
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: config.COLORS.primary,
        }}
      >
        <Typography variant="h3" className={classes.title}>
          {config.UI_LANGUAGE.membershipSection.title}
        </Typography>
      </Box>

      <Grid container spacing={3} justify="center">
        <Grid item xs={12} lg={4}>
          <Box width="100%" pt={6} pb={6}>
            <Box textAlign="center" pl={2} pr={2}>
              <Box>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={classes.heading}
                >
                  {config.UI_LANGUAGE.membershipSection.question}
                </Typography>
              </Box>
              <Box mt={4}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.subTitle}
                >
                  {config.UI_LANGUAGE.membershipSection.description}
                </Typography>
              </Box>

              <Box mt={4}>
                <LoginButton />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

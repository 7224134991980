import React from "react";
import { Box, Typography, Container, makeStyles } from "@material-ui/core";

type PropTypes = {
  url: string;
};

export const MigrationBanner = ({ url }: PropTypes) => {
  const styles = useStyles();
  return (
    <Box className={styles.wrapper}>
      <Container>
        <Box className={styles.textWrapper}>
          <Box>
            <Typography className={styles.title}>We have migrated to a new website!</Typography>
          </Box>
          <Box>
            <Typography className={styles.subtitle}>
              Login here:{" "}
              <span
                onClick={() => {
                  window.location.href = url;
                }}
                style={{
                  color: "#4FD1C5",
                  cursor: "pointer",
                  fontWeight: 600,
                  textDecoration: "underline",
                }}
              >
                {url}
              </span>{" "}
              to buy and manage your Flex Pass!
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "300px",
    backgroundColor: "RGBA(0, 0, 0, 0.48)",
    display: "flex",
    alignItems: "center",
  },
  textWrapper: {
    textAlign: "center",
  },
  title: {
    color: "white",
    fontSize: "28px",
    lineHeight: "1.2",
    fontWeight: 600,
  },
  subtitle: {
    marginTop: "12px",
    color: "white",
    fontSize: "24px",
    fontWeight: 300,
    lineHeight: 1.1,

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import "../css/home.scss";
import { device } from "../device";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import VerifyEmail from "./VerifyEmail";
import PassRegForm from "./PassRegForm";
import EmptyPass from "./EmptyPass";
import LoginNotice from "./LoginNotice";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "src/utils/utils";
import { useLocalStorage } from "src/components/localStorage";
import { useStates, usStates } from "./usStates";
import CardType from "src/images/cc-images.png";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  Box,
  colors,
  CircularProgress,
  OutlinedInput,
  FormControl,
  InputLabel,
  fade,
  Select,
  MenuItem,
  Grid,
  Container,
} from "@material-ui/core";
import * as purchaseAction from "src/state/purchase/purchaseAction";
import { useDispatch } from "react-redux";
import { teamAuthApi } from "src/api/teamAuthApi";
import config from "src/config";
import { v4 as uuid } from "uuid";
import GameDetailModal from "src/components/GameDetailModal";
import PaymentForm from "src/components/PaymentForm";
import OrderSummary from "./OrderSummary";
import { toast } from "react-toastify";
import Banner from "src/components/Banner";

function PurchasePlan() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [counter, setCounter] = useState(2);
  const [eventCode, setEventCode] = useState();
  const [isExclusive, setIsExclusive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const [accountId, setAccountId] = useState("");
  const [teamAuthUrl, setTeamAuthUrl] = useState("");

  const [isAccountLinked, setIsAccountLinked] = useState(false);
  const [accountUserName, setAccountUserName] = useState("");

  const [isAvailableForSale, setIsAvailableForSale] = useState(undefined);
  const [seatPermutation, setSeatPermutation] = useState(null);
  const [tempRefreshToken, setTempRefreshToken] = useState("");

  const linkAccount = async (authCode, finalUrl) => {
    console.log("Linking account");
    const isDevelopment = config.IS_DEVELOPMENT; //todo read from config
    if (isDevelopment) alert("DEV MODE");

    teamAuthApi
      .loginTicketmasterAuthCode(authCode, finalUrl)
      .then(async (loginTicketmaster) => {
        if (loginTicketmaster.data["userData"]["refreshToken"]) {
          const refreshToken =
            loginTicketmaster.data["userData"]["refreshToken"];
          const emailAddress =
            loginTicketmaster.data["userData"]["tmEmailAddress"];
          const accountNumber =
            loginTicketmaster.data["userData"]["accountNumber"];

          setAccountId(accountNumber);
          setIsAccountLinked(true);
          setAccountUserName(emailAddress);
          setTempRefreshToken(refreshToken);

          Swal.fire(
            "Team Account Linked!",
            "Proceed to purchasing your pass. ",
            "success"
          );
          window.mixpanel.identify(accountNumber);
          window.mixpanel.people.set({
            $email: emailAddress, // only reserved properties need the $
            USER_ID: accountNumber, // use human-readable names
          });

          window.mixpanel.track("Team Account Linked", {
            accountNumber: accountNumber,
          });
        }
      })
      .catch((err) => {
        const params = new URLSearchParams(history.location.search);
        const eventCode = params.get("eventCode");
        toast.error(
          "Error connecting team account. Try again or contact support if error persists."
        );
        history.push(`/purchase-plan?eventCode=${eventCode}`);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams(history.location.search);
    const planCode = params.get("eventCode");
    window.mixpanel.track("Purchase Page View", { planCode: planCode });

    if (planCode) {
      setEventCode(planCode);
      axios
        .get(`${config.API_BASE_URI}/subscription/plans/${planCode}`)
        .then((response) => {
          // console.log(response.data["subscriptionPlans"]);
          // setMaxValue(response.data["subscriptionPlans"][0].maxSeats);
          setSubscriptionPlans(response.data["subscriptionPlans"]);
          setIsExclusive(response.data["subscriptionPlans"][0].isExclusive);
          setIsAvailableForSale(
            response.data["subscriptionPlans"][0].isAvailableForSale
          );

          // Reading seat permutation from plan object and
          // setting state for counter interface
          let permutation = response.data[
            "subscriptionPlans"
          ][0].seatPermutation
            .replace(/,/g, "")
            .split("")
            .map((num) => Number(num));

          setSeatPermutation(permutation);
          setCounter(
            localStorage.getItem("counter")
              ? parseInt(localStorage.getItem("counter"), 10)
              : permutation[0]
          );
          localStorage.removeItem("counter");
          setLoading(false);
        });
    }
    // set team auth url
    teamAuthApi.getTeamUrl().then((teamAuth) => {
      setTeamAuthUrl(teamAuth.url);
      // handle link if coming from
      const authCode = params.get("code");

      let replaced = teamAuth.url.replace(
        "REDIRECT_URL",

        `${config.SS_FE_BASE_URI}/tmcallback/purchase?eventCode=${eventCode}`
      ); // redirect back to where we came from, handle in params
      replaced.replace("STATE", `PURCHASE-${config.teamId}`);

      if (authCode) {
        linkAccount(
          authCode,
          `${config.SS_FE_BASE_URI}/tmcallback/purchase?eventCode=${planCode}`
        );
      }
    });
  }, []);

  const handleConnectAccount = () => {
    window.mixpanel.track("Connect Team Account", {});
    localStorage.setItem("counter", counter || 1);
    let redirectUrl = `${config.SS_FE_BASE_URI}/tmcallback/purchase?eventCode=${eventCode}`;
    // let redirectUrl = `http://localhost:3000/tmcallback/purchase?eventCode=${eventCode}&seatCount=2`;
    let replaced = teamAuthUrl.replace("REDIRECT_URL", redirectUrl); // redirect back to where we came from, handle in params
    let finalUrl = replaced.replace("STATE", `PURCHASE-${config.TEAM_ID}`);
    window.location.href = finalUrl;
  };

  // https://auth.ticketmaster.com/as/authorization.oauth2?lang=en-us&scope=openid profile archtics&client_id=17d94fb22b9f.seasonshare.gators-gators.us&integratorId=35624041429c539a83f8a4e3d0d44196ab78bf62ad7890ade173177ef068214a&placementId=homepage&visualPresets=gators&redirect_uri=https://prowlpass.seasonshare.com/tmcallback/purchase?eventCode=FB21FLEX&seatCount=2&state=PURCHASE-182

  const handleGameDetail = async (passName, subscriptionPlanId) => {
    GameDetailModal(passName, subscriptionPlanId);
  };

  const handleIncrement = () => {
    let currentIndex = seatPermutation.indexOf(counter);
    if (currentIndex !== seatPermutation.length - 1) {
      setCounter(seatPermutation[currentIndex + 1]);
    }
  };

  const handleDecrement = () => {
    let currentIndex = seatPermutation.indexOf(counter);
    if (currentIndex !== 0) {
      setCounter(seatPermutation[currentIndex - 1]);
    }
  };

  // Handle email verification
  const [emailVerified, setEmailVerified] = useState(false);
  const [student, setStudent] = useState(null);
  const reactLocalStorage = useLocalStorage();

  // Checking verified email if plan is exclusive
  const exclusiveEmailVerify = async (emailAddress) => {
    const endpoint = `${config.API_BASE_URI}/subscription/eligiblity`;
    const teamId = config.TEAM_ID;
    try {
      let response = await axios.post(endpoint, { teamId, emailAddress });

      if (response.status == 200) {
        let { student } = response.data;
        setEmailVerified(true);
        setStudent(student);
        toast.success("Email verified");
        console.log(response);
      }
    } catch (error) {
      setEmailVerified(false);
      Swal.fire({
        title: "You are not eligible to purchase this pass",
        icon: "error",
      });
    }
  };

  const handleRegister = async (values) => {
    let studentManifestId = student.id;
    let accountNumber = "1663565";
    let endpoint = `${config.API_BASE_URI}/subscription/register`;

    try {
      let response = await axios.post(endpoint, {
        ...values,
        studentManifestId,
        accountNumber,
      });
      if (response.status === 200) {
        let { accessToken } = response.data;
        reactLocalStorage.setItem("refreshToken", accessToken);
        Swal.fire({
          icon: "success",
          title: "Congrats! Your purchase was successful.",
          html: "<p>An email was sent with more information about how tickets are delivered. Contact support if you have any questions.</p>",
          confirmButtonText: `Done`,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/my-subscription");
          }
        });
        window.mixpanel.track("Purchase Complete", {});
      }
      return new Promise((resolve) => resolve(response));
    } catch (error) {
      const errorMessage =
        error.response.data.errorMessage ||
        "Something went wrong. Please contact support if the error persists.";
      Swal.fire("Error!", errorMessage, "error");
    }
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate || !expiryDate.includes("/")) {
      return expiryDate;
    }

    const expiryArray = expiryDate.split("/");
    let month = expiryArray.length > 0 ? expiryArray[0] : "";
    let year = expiryArray.length > 1 ? expiryArray[1] : "";

    if (month.length === 1) {
      month = `0${month}`;
    }

    if (year.length > 1) {
      year = year.slice(-2);
    }

    return `${month}${year}`;
  };

  const handleSubmit = async (values) => {
    const params = new URLSearchParams(history.location.search);
    const planCode = params.get("eventCode");

    const data = {
      planCode: planCode,
      accountNumber: accountId,
      firstName: values.firstName,
      lastName: values.lastName,
      fullName: values.firstName + " " + values.lastName,
      phoneNumber: values.phoneNumber,
      emailAddress: accountUserName,
      cardNumber: values.cardNumber.replace(/ /g, ""),
      expirationDate: formatExpiryDate(values.cardExpiry),
      securityCode: values.cardCvc,
      address: values.street,
      zipCode: values.zipCode,
      numSeats: counter,
    };

    const purchasePass = await dispatch(purchaseAction.purchasePass(data));
    if (purchasePass.hasOwnProperty("data")) {
      reactLocalStorage.setItem(
        `${config.TEAM_ID}_MA_REFRESH_TOKEN`,
        tempRefreshToken
      );
      reactLocalStorage.setItem(
        `${config.TEAM_ID}_MA_SUBSCRIBER`,
        purchasePass.data.subscriber
      );
      Swal.fire({
        icon: "success",
        title: "Purchase Success!",
        html: "<p>A text was sent with more information about how tickets are delivered. Contact support if you have any questions.</p>",
        confirmButtonText: `Done`,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/membership_portal");
        }
      });
      window.mixpanel.track("Purchase Complete", {});
    }

    return Promise.resolve(purchasePass);
  };

  return (
    <>
      <div className="headers">
        <div className="site-logo">
          <a href="/">
            <img src={config.SITE_LOGO_URI} alt="" />
          </a>
        </div>
      </div>
      <Banner />
      {loading ? (
        <Container>
          <Box textAlign="center" p={8}>
            <CircularProgress
              size={24}
              style={{ color: config.COLORS.primary }}
            />
          </Box>
        </Container>
      ) : (
        <Container maxWidth={false}>
          <Box mt={4} mb={24}>
            {isExclusive && !emailVerified && isAvailableForSale && (
              <Box width="100%" display="flex" justifyContent="center">
                <VerifyEmail {...{ exclusiveEmailVerify }} />
              </Box>
            )}

            {isAvailableForSale === false ? (
              <Box width="100%" display="flex" justifyContent="center">
                <EmptyPass />
              </Box>
            ) : (
              <>
                {isAvailableForSale && isExclusive && emailVerified && (
                  <Card className={classes.cardRoot}>
                    <CardHeader
                      className={classes.headerTitle}
                      title="Order Summary"
                    />
                    {subscriptionPlans.map((plan, i) => {
                      return (
                        <OrderSummary
                          key={uuid()}
                          {...{
                            plan,
                            counter,
                            handleIncrement,
                            handleDecrement,
                            handleGameDetail,
                          }}
                        />
                      );
                    })}
                  </Card>
                )}

                {isAvailableForSale && !isExclusive && (
                  <Card className={classes.cardRoot}>
                    <CardHeader
                      className={classes.headerTitle}
                      title="Order Summary"
                    />
                    {subscriptionPlans.map((plan, i) => {
                      return (
                        <OrderSummary
                          key={uuid()}
                          {...{
                            plan,
                            counter,
                            handleIncrement,
                            handleDecrement,
                            handleGameDetail,
                          }}
                        />
                      );
                    })}
                  </Card>
                )}
                {isExclusive && emailVerified && !isAccountLinked && (
                  <>
                    <ConnectButton
                      onClick={() => {
                        handleConnectAccount();
                      }}
                    >
                      CONNECT TEAM ACCOUNT
                    </ConnectButton>
                    <TextContainer>
                      Please log in to your current Ticketmaster account or
                      create a new account to complete your registration.
                    </TextContainer>
                  </>
                )}

                {!isExclusive && isAccountLinked === false && (
                  <>
                    <ConnectButton
                      onClick={() => {
                        handleConnectAccount();
                      }}
                    >
                      CONNECT TEAM ACCOUNT
                    </ConnectButton>
                    <TextContainer>
                      Please log in to your current Ticketmaster account or
                      create a new account to complete your purchase.
                    </TextContainer>
                  </>
                )}

                {isExclusive && emailVerified && student && isAccountLinked && (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    mt={3}
                    mb={3}
                  >
                    <LoginNotice {...{ accountUserName }} />

                    <Box mt="2">
                      <PassRegForm {...{ student, handleRegister }} />
                    </Box>
                  </Box>
                )}

                {!isExclusive && isAccountLinked && accountUserName && (
                  <Grid
                    container
                    justify="center"
                    alignContent="center"
                    direction="column"
                  >
                    <Grid item xs={12} md={6}>
                      <Box mt={4}>
                        <LoginNotice {...{ accountUserName }} />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <PaymentForm
                        formName="payment form"
                        email={accountUserName}
                        {...{ handleSubmit }}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Box>
        </Container>
      )}

      {/* <Footer/> */}
    </>
  );
}
export default PurchasePlan;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  cardRoot: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  // styling button disabling class
  buttonDisable: {
    // backgroundColor: `${colors.grey(600)}`,
    pointerEvents: "none",
    backgroundColor: "gray !important",
  },
  title: {
    fontSize: 32,
    flexDirection: "column",
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    color: "#000000",
  },
  pos: {
    marginBottom: 12,
  },

  headerTitle: {
    borderBottom: "1px solid #a9a9a9",
  },
  paper: {
    boxShadow: "none",
    fontWeight: 700,
  },
  mainText: {
    boxShadow: "none",
    fontWeight: 700,
    padding: "10px 0",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  subText: {
    boxShadow: "none",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  footer: {
    borderTop: "1px solid #a9a9a9",
  },
  totalTitle: {
    marginLeft: "auto",
    marginRight: 10,
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  btnGroup: {
    width: "100%",
    justifyContent: "center",
    margin: "10px 0",
  },
  btnToggle: {
    border: "2px solid #c4c4c4 !important",
    margin: "0 20px !important",
    borderRadius: "50% !important",

    [theme.breakpoints.down("md")]: {
      margin: "0 8px !important",
    },
  },
  countText: {
    border: "2px solid #c4c4c4 !important",
    fontSize: 18,
    padding: "2px 14px",
    color: "#000000 !important",
  },
  alignText: {
    textAlign: "center",
  },
  imgWidth: {
    width: 170,
    [theme.breakpoints.down("md")]: {
      width: 130,
    },
  },
  imgWidth2: {
    width: 250,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imgStyle: {
    display: "none",
    height: 50,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: 200,
      marginLeft: "auto",
    },
  },
  margin: {
    margin: "auto",
  },
  connectBtn: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "15px 100px",
    fontFamily: "Raleway",
    "&:hover": {
      backgroundColor: fade(config.COLORS.secondary, 0.8),
    },
  },

  suggestionInner: {
    padding: "20px 10px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    borderBottom: `1px solid ${colors.grey["800"]}`,

    "&:last-child": {
      border: "none",
    },
    "&:hover": {
      backgroundColor: `${colors.grey["800"]}`,
    },
  },
  phoneLabel: {
    "&.MuiInputLabel-shrink": {
      backgroundColor: "white !important",
    },
  },

  submitButton: {
    backgroundColor: "#52ACFF",
    height: 60,
    fontSize: 20,
    fontWeight: "bold",
    color: "white",

    "&:hover": {
      backgroundColor: fade("#52ACFF", 0.9),
    },
  },
}));

const BoldText = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: ${colors.grey["50"]};
`;
const LowerText = styled.span`
  font-size: 13px;
  font-weight: normal;
  color: #9d9d9d;
`;

const TextContainer = styled.div`
  font-family: Raleway;
  font-style: normal;
  margin-top: 20px;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #000000;
  display: block;
  width: 100%;
`;

const RadioTitle = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
`;

const RadioContainer = styled.div`
  border: 1px solid #a9a9a9;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px;
  display: flex;
`;

const WrapperContent = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  width: 50%;
`;

const ConnectButton = styled.button`
  display: none;
  width: 40%;
  margin: auto;
  background: ${config.COLORS.primary};
  border-radius: 5px;
  border: none;
  margin-top: 5%;
  padding: 10px 0px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 42px;
  color: #ffffff;
  display: block;
  @media ${device.materialsm} {
    width: 100%;
    padding: 0;
  }
  &:hover {
    background-color: ${config.COLORS.secondary};
    cursor: pointer;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`;
const CardDetails = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;

  > div:first-child {
    padding-left: 0px;
  }

  > div:last-child {
    padding-right: 0px;
  }
`;

const CardDetail2 = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;

  position: relative;

  > div > div > p {
    position: absolute;
    bottom: 0;
    margin-bottom: -20px;
  }

  > div:first-child {
    padding-left: 0px;
  }

  > div:last-child {
    padding-right: 0px;
  }
`;

const SuggestionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  margin-top: 60px;
  z-index: 999;
  background: #fff;
  padding: 0 2px 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 24px -8px;
`;

const BillingContent = styled.div``;

const BillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  margin-top: 20px;

  @media ${device.materialsm} {
    margin: 20px 0 0 0;
    width: 100%;
  }
`;

const BillingHeader = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  flex-direction: column;
  font-weight: 600;
`;

const HeaderContainer = styled.div`
  padding: 2% 5%;
  display: flex;
  height: 25%;
  border-bottom: 10px solid black;
  border-top: 10px solid black;
  flex-direction: row;
  background-image: url(${config.HOME_HERO_BG});
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  h1 {
    color: white;
    font-size: 48px;
    font-weight: 600;
    @media ${device.tablet} {
      text-align: center;
      font-size: 36px;
    }
  }
  h2 {
    color: white;
    font-size: 36px;
    font-weight: 300;
    @media ${device.tablet} {
      text-align: center;
      font-size: 24px;
    }
  }
  .half {
    width: 60%;
    margin: auto;
    text-align: center;
    @media ${device.tablet} {
      text-align: center;
      width: 100%;
    }
  }
`;
// const Container = styled.div`
//   height: 100vh;
// `;
const BodyContainer = styled.div`
  padding: 2% 5%;
  align-items: flex-start;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.materialsm} {
  }
`;

import React, { useState, createContext, useContext } from "react";

const LocalStorageContext = createContext();

export function LocalStorageProvider({ children }) {
  const [storage, setStorage] = useState(localStorage);

  const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
    let storage = { ...localStorage };
    setStorage(storage);
  };

  const getItem = (key) => {
    let item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (error) {
      return;
    }
  };

  return (
    <LocalStorageContext.Provider value={{ storage, setItem, getItem }}>
      {children}
    </LocalStorageContext.Provider>
  );
}

export const useLocalStorage = () => useContext(LocalStorageContext);

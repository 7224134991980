import React from "react";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function ErrorChip({ formik }) {
  const { isValid, submitCount } = formik;

  return (
    <>
      {!isValid && submitCount > 0 && (
        <Box mt={2}>
          <Alert severity="warning">Missing information.</Alert>
        </Box>
      )}
    </>
  );
}

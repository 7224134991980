import React, { useEffect, useRef } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import MySubscription from "./pages/MySubscription";
import PurchasePlan from "./pages/PurchasePlan";
import FriendsPortal from "./pages/FriendsPortal";
import RegisterPass from "./pages/RegisterPass";
import AcceptFriendsRequest from "./pages/AcceptFriendRequest";
import config from "src/config";
import PurchaseTickets from "src/pages/PurchaseTickets";
import SitWithFriend from "src/pages/MembershipPortal/SitWithFriend";
import PassOverview from "./pages/MembershipPortal/PassOverview";
import ManageRoute from "./components/ManageRoute";
import LoginPopup from "./components/LoginPopup";
import { routes } from "src/routes";
import { QueryContextProvider } from "src/hooks/url_query_params";
import ManageContacts from "src/pages/MembershipPortal/ManageContacts";
import ManageSubscription from "src/pages/MembershipPortal/ManageSubscription";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { getTeamConfig } from "src/state/team/teamActions";

function App() {
  const dispatch = useDispatch();

  // getting team config
  useEffect(() => {
    dispatch(getTeamConfig());
  }, []);

  // Intercom enable/disable based on config
  const toggleIntercom = () => {
    const teamName = config?.TEAM_NAME;
    const intercomEnabled = config?.INTERCOM_ENABLED;
    intercomEnabled &&
      window.Intercom("boot", {
        team: teamName,
        app_id: "xol8gfkc",
      });
  };

  useEffect(toggleIntercom, []);

  useEffect(() => {
    if (config.V2_PASS_URL) {
      if (window && window.location) {
        window.location.href = config.V2_PASS_URL;
      } else {
        document.location.href = config.V2_PASS_URL;
      }
    }
  }, []);

  return (
    <>
      {/* <Helmet>
        <link rel="icon" href={config.SITE_SEO.favicon} />
        <link rel="apple-touch-icon" href={config.SITE_SEO.favicon} />
        <meta name="description" content={config.SITE_SEO.description} />
        <title>{config.SITE_SEO.title}</title>
      </Helmet> */}
      <ToastContainer />
      <Router>
        <Switch>
          <QueryContextProvider>
            {/* <LoginPopup /> */}
            <ManageRoute>
              <Route exact path={routes.home}>
                <Home />
              </Route>
              <Route exact path={routes.tmLinkAccount}>
                <Home />
              </Route>
              <Route exact path={routes.mySubscription}>
                <MySubscription />
              </Route>
              <Route exact path={routes.purchasePlan}>
                <PurchasePlan />
              </Route>
              <Route exact path={routes.tmPurchase}>
                <PurchasePlan />
              </Route>
              <Route exact path={routes.tmLogin}>
                <Home />
              </Route>
              <Route exact path={routes.friendsPortal}>
                <FriendsPortal />
              </Route>
              <Route exact path={routes.registerPass}>
                <RegisterPass />
              </Route>
              <Route exact path={routes.tmRegisterPass}>
                <RegisterPass />
              </Route>
              <Route exact path={routes.acceptRequest}>
                <AcceptFriendsRequest />
              </Route>
              <Route exact path={routes.purchaseTickets}>
                <PurchaseTickets />
              </Route>

              <Route exact path={routes.acceptEvent}>
                <Home />
              </Route>

              {/* Membership portal routes */}
              {/* ======================== */}
              <Route exact path={routes.membershipPortal}>
                {config.EVENT_ATTENDANCE ? (
                  <Redirect to={routes.membershipPassOverview}>
                    <PassOverview />
                  </Redirect>
                ) : (
                  <Redirect to={routes.membershipSitWithFriend}>
                    <SitWithFriend />
                  </Redirect>
                )}
              </Route>
              <Route exact path={routes.membershipSitWithFriend}>
                <SitWithFriend />
              </Route>
              <Route exact path={routes.membershipPassOverview}>
                <PassOverview />
              </Route>
              <Route exact path={routes.membershipManageContacts}>
                <ManageContacts />
              </Route>
              <Route exact path={routes.membershipManageSubscription}>
                <ManageSubscription />
              </Route>

              {/* <Route component={Page404} /> */}
            </ManageRoute>
          </QueryContextProvider>
        </Switch>
      </Router>
    </>
  );
}

export default App;

import React from "react";
import {
  Box,
  makeStyles,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";
import config from "src/config";

const useStyles = makeStyles((theme) => ({
  announcementBarContainer: {
    height: 100,
    color: "white",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "black",
  },
}));

export default function AnnouncementBar({ heading, subtitle }) {
  const classes = useStyles();
  return (
    <>
      {config.UI_LANGUAGE.announcementBarText && (
        <div className={classes.announcementBarContainer}>
          {/* TODO pull this content from config file */}
          <Typography
            style={{ fontFamily: "Raleway", fontWeight: 800 }}
            color="white"
            variant="h5"
          >
            {config.UI_LANGUAGE.announcementBarText}
          </Typography>
        </div>
      )}
    </>
  );
}

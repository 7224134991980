import React, { useEffect } from "react";
import config from "src/config";
import { Box, Grid, Container, useMediaQuery, useTheme } from "@material-ui/core";
import PassCard from "./PassCard";
import EmptyPass from "./EmptyPass";
import { v4 as uuid } from "uuid";

export default function PassOptions({
  subscriptionPlans,
  isNotForSale,
  handleBuyNow,
  handleGameDetail,
}) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const passHeader = config.UI_LANGUAGE?.passSelectionHeader || "Ticket Options";

  useEffect(() => {
    console.log(subscriptionPlans);
    console.log(isNotForSale);
  }, []);

  return (
    <>
      <div className="header-cont how-it-works" style={{ backgroundColor: config.COLORS.primary }}>
        <h3>{passHeader}</h3>
      </div>
      {/* className="flex-cont2 how-it-works-container" */}
      <Box pt={sm ? 3 : 6} pb={sm ? 3 : 6} overflow="hidden" style={{ backgroundColor: "#DCDCDC" }}>
        <Container>
          <Grid container spacing={3} justify="center">
            {subscriptionPlans && !isNotForSale ? (
              subscriptionPlans.map((plan, index) => {
                return (
                  plan["isAvailableForSale"] && (
                    <Grid key={uuid()} item xs={12} md={6} lg={4}>
                      <PassCard {...{ plan, index, handleBuyNow, handleGameDetail }} />
                    </Grid>
                  )
                );
              })
            ) : (
              <EmptyPass />
            )}
            {/* {!isNotForSale && <EmptyPass />} */}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

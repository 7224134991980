import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup.string().required("Please enter first name"),

  lastName: yup.string().required("Please enter last name"),
  streetAddress: yup.string().required("Please enter street address"),
  zipCode: yup.string().required("Please enter ZIP code"),

  ccNum: yup
    .string()
    .required("Please enter Card Number")
    .min(17, "Please insert a valid card number")
    .max(20, "Please insert a valid card number"),
  // cardCvc: yup
  //   .string()
  //   .required("Please enter Security Code")
  //   .min(3, "Please insert a valid card cvc")
  //   .max(4, "Please insert a valid card cvc"),
  ccExp: yup
    .string()
    .required("Please enter Card Expiry")
    .min(4, "Please insert a valid card expiration date"),
  // .isValidExpiry("Please insert a valid card expiration date"),
});

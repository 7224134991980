import React from "react";
const MembershipImage = "https://ss-leagues.s3.amazonaws.com/NBA/jazz-membership-portal.png";
const BannerImage = "https://ss-leagues.s3.amazonaws.com/NBA/jazz-banner-2.jpg";

const LogoImage = "https://ss-leagues.s3.amazonaws.com/NCAA/jazz_logo_sep_21.png";

const PAGE_TITLES = {
  home: {
    heading: "",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "MEMBERSHIP PORTAL",
    subtitle: "Manage your pass settings, make changes and send friend requests.",
  },
};

const UI_LANGUAGE = {
  teamAccountButton: "Connect Team Account",
  verifyEmail: {
    title: "Verify your email address",
    description:
      "This subscription plan is exclusive for students, Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  membershipSection: {
    title: "MANAGE MEMBERSHIP",
    question: "Returning ticket holder?",
    description: "Click this button to lookup and manage your existing membership.",
  },
  announcementBarText: "",
  registerTerms: {
    enabled: false,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program Terms and Conditions.
      </p>
    ),
  },
};

const UI_DIMENSION = {
  eventImageWidth: 80,
};

const SITE_SEO = {
  title: "UTAH JAZZ",
  description: "Register your Utah Jazz Student Tickets",
  favicon: LogoImage,
};

export const jazz = {
  SITE_TITLE: "UTAH JAZZ",
  TEAM_NAME: "Utah Jazz",
  TEAM_ID: 81,
  PRODUCT_NAME: "JAZZ PASS",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  IS_RECURRING: true,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://passbackend.seasonshare.com/api",
  SS_FE_BASE_URI: "https://jazz.seasonshare.com",
  ADMIN_BASE_URI: "https://subsjanitor.seasonshare.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#000000",
    secondary: "#333333",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0,
    membershipOverlay: 0,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: MembershipImage,
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

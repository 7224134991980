import React from "react";
import {
  Button,
  Grid,
  makeStyles,
  Box,
  CircularProgress,
} from "@material-ui/core";
import config from "src/config";

export default function SubmitButton({ formik }) {
  const styles = useStyles();
  const { isSubmitting } = formik;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box mt={2}>
          <Button
            type="submit"
            disabled={isSubmitting}
            variant="contained"
            size="large"
            fullWidth
            disableElevation
            className={styles.submitButton}
          >
            Purchase Pass
            {isSubmitting && (
              <CircularProgress
                size={14}
                style={{ color: config.COLORS.primary, marginLeft: 8 }}
              />
            )}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,
    fontSize: 20,
    fontFamily: "Poppins",
    fontWeight: 600,

    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
}));

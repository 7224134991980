import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Paper,
  Box,
  Button,
  makeStyles,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  colors,
} from "@material-ui/core";
import * as yup from "yup";
import BillingDetails from "./BillingDetails";
import CardInfo from "./CardInfo";
import AmexImage from "src/images/cards/amex.svg";
import VisaImage from "src/images/cards/visa.svg";
import DiscoverImage from "src/images/cards/discover.svg";
import MasterCardImage from "src/images/cards/mastercard.svg";
import config from "src/config";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  firstName: yup.string().min(2, "Please insert a valid first name"),
  lastName: yup.string().min(2, "Please insert a valid last name"),
  email: yup.string().email("Please insert a valid email address"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid"),
  address: yup.string().min(10, "Please insert a valid last name"),
  cardNumber: yup
    .string()
    .min(17, "Please insert a valid card number")
    .max(20, "Please insert a valid card number"),
  cardCvc: yup
    .string()
    .min(3, "Please insert a valid card cvc")
    .max(4, "Please insert a valid card cvc"),
  cardExpiry: yup
    .string()
    .min(4, "Please insert a valid card expiration date")
    .max(5, "Please insert a valid card expiration date"),
});

export default function CheckoutForm({ cartTotalAmount }) {
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const onSubmit = (values) => {
    console.log(values);
  };
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const { handleSubmit, dirty, isValid } = formik;

  useEffect(() => {
    if (cartTotalAmount === 0) {
      let formFields = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        address: "",
      };
      setInitialValues(formFields);
    }
  }, [cartTotalAmount]);

  return (
    <form onSubmit={handleSubmit}>
      <Paper variant="outlined">
        <Box
          p={1}
          pl={2}
          pr={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography
              variant="h5"
              className={classes.cardTitle}
              color="textPrimary"
            >
              Billing Details
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Box p={xs ? 2 : 4} pb={0} pt={2}>
          <Typography variant="caption" className={classes.requiredText}>
            *** All fields are required
          </Typography>
        </Box>

        <Box p={xs ? 2 : 4} pt={1}>
          <BillingDetails {...{ formik }} />
        </Box>
      </Paper>

      {cartTotalAmount > 0 && (
        <Paper variant="outlined" style={{ marginTop: 30 }}>
          <Box
            p={1}
            pl={2}
            pr={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography
                variant="h5"
                className={classes.cardTitle}
                color="textPrimary"
              >
                Payment Method
              </Typography>
            </Box>
            <Box>
              <img src={VisaImage} className={classes.titleCardImage} />
              <img src={MasterCardImage} className={classes.titleCardImage} />
              <img src={DiscoverImage} className={classes.titleCardImage} />
              <img src={AmexImage} className={classes.titleCardImage} />
            </Box>
          </Box>
          <Divider />

          <Box p={xs ? 2 : 4} pb={0} pt={2}>
            <Typography variant="caption" className={classes.requiredText}>
              *** All fields are required
            </Typography>
          </Box>

          <Box p={xs ? 2 : 4} pt={1}>
            <CardInfo {...{ formik }} />
          </Box>
        </Paper>
      )}

      <Paper variant="outlined" style={{ marginTop: 30 }}>
        <Box p={xs ? 2 : 4}>
          {cartTotalAmount > 0 ? (
            <Button
              variant="contained"
              disableElevation
              size="large"
              fullWidth
              className={classes.tmButton}
              type="submit"
              disabled={!(isValid && dirty)}
            >
              PURCHASE
            </Button>
          ) : (
            <Button
              variant="contained"
              disableElevation
              size="large"
              fullWidth
              className={classes.tmButton}
              type="submit"
              disabled={!(isValid && dirty)}
            >
              claim ticket
            </Button>
          )}
        </Box>
      </Paper>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: 20,

    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  requiredText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: colors.red["700"],
    fontSize: 14,
  },

  titleCardImage: {
    width: 42,
    marginLeft: 5,

    [theme.breakpoints.down("xs")]: {
      width: 28,
    },
  },
  tmButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: 22,
    // paddingTop: 10,
    // paddingBottom: 10,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      color: config.COLORS.contrastText,
    },
  },
}));

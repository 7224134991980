import * as types from "./actionTypes";
import initialState from "../initialState";

const friendReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FRIENDS:
      return { ...state, friends: action.payload };
    case types.SEND_FRIEND_REQUEST:
      return { ...state, friends: action.payload };
    case types.DELETE_FRIEND:
      return { ...state, friends: action.payload };
    default:
      return state;
  }
};

export default friendReducer;

import React from "react";
import Layout from "src/pages/MembershipPortal/Layout";
import FriendsView from "src/views/FriendsView";

export default function SitWithFriend() {
  return (
    <Layout>
      <FriendsView />
    </Layout>
  );
}

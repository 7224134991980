import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import LoginButton from "./LoginButton";
import { useLocalStorage } from "src/components/localStorage";
import { useHistory } from "react-router-dom";
import config from "src/config";
import Popup from "src/components/Popup";
import { useQuery } from "src/hooks/url_query_params";

export default function LoginPopup() {
  const classes = useStyles();
  const query = useQuery();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const reactLocalStorage = useLocalStorage();

  const setPopup = (open) => setOpen(open);

  // useEffect(() => console.log(query.params), [query.params]);

  const handleQueryUrl = () => {
    // Getting userData
    const token = reactLocalStorage.getItem(
      `${config.TEAM_ID}_MA_REFRESH_TOKEN`
    );
    const subscriber = reactLocalStorage.getItem(
      `${config.TEAM_ID}_MA_SUBSCRIBER`
    );

    // NOTE : redirect = qury params redirect
    // ========================================

    // getting dependency items

    const redirect = query.get("redirect")?.replaceAll("$$", "&");
    const isLoggedIn = token && subscriber;

    if (!redirect) {
      setLoading(true);
      setPopup(false);
    }

    // if redirect available in url and not logged in
    // then save the url in storage and prompt user
    // to log in
    if (redirect && !isLoggedIn) {
      setPopup(true);
      setLoading(false);
      return;
    }

    if (redirect && isLoggedIn) {
      setPopup(false);
      window.location.href = redirect;
      return;
    }
  };

  const handleStorageUrl = () => {
    // Getting userData
    const token = reactLocalStorage.getItem(
      `${config.TEAM_ID}_MA_REFRESH_TOKEN`
    );
    const subscriber = reactLocalStorage.getItem(
      `${config.TEAM_ID}_MA_SUBSCRIBER`
    );

    // getting dependency items
    // NOTE : redirectUrl is local storage redirect URL
    const redirectUrl = decodeURIComponent(
      reactLocalStorage.getItem("redirectUrl")
    );
    const isLoggedIn = token && subscriber;

    if (redirectUrl && isLoggedIn) {
      reactLocalStorage.setItem("redirectUrl", "");
      window.location.href = redirectUrl;
      return;
    }
  };

  useEffect(handleQueryUrl, []);
  useEffect(handleStorageUrl, [reactLocalStorage]);

  // useEffect(setPopup, [redirectUrl]);
  // useEffect(setRedirect, [redirectUrl]);

  return (
    <Popup
      {...{ open }}
      PaperProps={{
        width: 350,
      }}
    >
      {!loading ? (
        <Box p={4}>
          <Box textAlign="center">
            <Typography
              variant="h5"
              color="textPrimary"
              className={classes.title}
            >
              Please login
            </Typography>
          </Box>
          <Box textAlign="center" mt={1} mb={2}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.subtitle}
            >
              Login to our system to manage your events, friends and more.
            </Typography>
          </Box>
          <Box textAlign="center">
            <LoginButton />
          </Box>
        </Box>
      ) : (
        <Box p={4}>
          <Box textAlign="center">
            <Typography
              variant="h5"
              color="textPrimary"
              className={classes.title}
            >
              Please wait
            </Typography>
          </Box>
          <Box mt={2} textAlign="center">
            <CircularProgress
              size={18}
              style={{ color: config.COLORS.primary }}
            />
          </Box>
        </Box>
      )}
    </Popup>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: 26,
  },
  subtitle: {
    fontFamily: "Raleway",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.3,
  },
}));

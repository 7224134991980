import React, { useState, useEffect, useContext, createContext } from "react";
import { Grid, Box, Container, Button, makeStyles } from "@material-ui/core";
import Header from "src/components/Header";
import Banner from "src/components/Banner";
import ProductCart from "./ProductCart";
import config from "src/config";
import CheckoutForm from "./CheckoutForm";
import LoginNotice from "src/components/LoginNotice";

const plans = [
  {
    planName: "Free Student Ticket",
    planDescription: "Tickets are delivered in your Oregon Ducks Team Account.",
    seatPermutation: "2,4,6",
    oneTimeCost: 0,
  },
  {
    planName: "Guest Ticket",
    planDescription: "Tickets are delivered in your Oregon Ducks Team Account.",
    seatPermutation: "0,3,4,8",
    oneTimeCost: 15,
  },
];

const ProductCartContext = createContext();

export const useCart = () => {
  let props = useContext(ProductCartContext);
  return { ...props };
};

export default function PurchaseTickets() {
  const [products, setProducts] = useState(plans);
  const classes = useStyles();
  const [teamAccountConnected, setTeamAccountConnected] = useState(true);
  const [cartTotalAmount, setCartTotalAmount] = useState(0);

  useEffect(() => {
    console.log(cartTotalAmount);
  }, [cartTotalAmount]);

  const updateCartTotal = (amount) => {
    setCartTotalAmount(amount);
  };

  return (
    <ProductCartContext.Provider value={{ cartTotalAmount, updateCartTotal }}>
      <Header />
      <Banner
        heading={config.PAGE_TITLES.purchaseTickets.heading}
        subtitle={config.PAGE_TITLES.purchaseTickets.subtitle}
      />

      <Container>
        <Grid container justify="center">
          {teamAccountConnected && (
            <Grid item xs={12} lg={8}>
              <Box pt={4}>
                <LoginNotice accountUserName="team@seasonshare.com" />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} lg={8}>
            <Box pt={4}>
              <ProductCart {...{ products }} />
            </Box>
          </Grid>

          {!teamAccountConnected && (
            <Grid item xs={12} lg={8}>
              <Box pt={4}>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  fullWidth
                  className={classes.tmButton}
                >
                  Connect team account
                </Button>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} lg={8}>
            <Box pt={4}>
              <CheckoutForm {...{ cartTotalAmount }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box p={12}></Box>
    </ProductCartContext.Provider>
  );
}

const useStyles = makeStyles((theme) => ({
  tmButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: 22,
    borderRadius: 40,
    paddingTop: 10,
    paddingBottom: 10,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      color: config.COLORS.contrastText,
    },
  },
}));

import React, { useEffect } from "react";
import { Box, makeStyles, fade, colors, Checkbox } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import config from "src/config";
import { DoneAllRounded } from "@material-ui/icons";

export default function AcceptNotice({ formik }) {
  const styles = useStyles();
  const termsEnabled = config.UI_LANGUAGE.registerTerms.enabled;
  const termsDescription = config.UI_LANGUAGE.registerTerms.description;
  const errorMessage =
    "Please accept our terms and conditions to complete your purchase.";
  const { values, setFieldValue, setFieldError, errors, submitCount } = formik;

  useEffect(() => {
    if (formik.submitCount > 0 && !values.termsAgreed) {
      setFieldError("termsAgreed", errorMessage);
    }
  }, [submitCount]);

  useEffect(() => {
    // If terms and condition is not enabled from
    // config, then setting the termsAgreed true by default
    if (!termsEnabled) setFieldValue("termsAgreed", true);
  }, [config]);

  const handleChange = (event) => {
    event.persist();
    let checked = event.target.checked;
    // let setError = (error) => {
    //   error
    //     ? setFieldError("termsAgreed", errorMessage)
    //     : setFieldError("termsAgreed", "");
    // };

    // checked ? setError(false) : setError(true);
    setFieldValue("termsAgreed", checked);
  };

  return (
    <>
      <Box mt={2}>
        <Alert
          variant="standard"
          severity="success"
          icon={
            termsEnabled ? (
              <Checkbox checked={values.termsAgreed} onChange={handleChange} />
            ) : (
              <DoneAllRounded />
            )
          }
          classes={{
            root: styles.background,
            message: styles.message,
            icon: styles.icon,
          }}
        >
          {termsDescription}
          {errors["termsAgreed"] && submitCount > 0 && (
            <Box mt={1} className={styles.errorMessage}>
              {errors["termsAgreed"]}
            </Box>
          )}
        </Alert>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: fade(config.COLORS.primary, 0.06),
  },

  message: {
    color: config.COLORS.primary,
  },

  errorMessage: {
    color: "#F44336",
    lineHeight: 1.2,
  },
  termsLink: {
    color: colors.blue[600],
  },

  icon: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: 30,
    "& svg": {
      color: config.COLORS.primary,
    },
  },
}));

// All routes used by app
export const routes = {
  home: "/",
  tmLinkAccount: "/tmcallback/linkAccount",
  mySubscription: "/my-subscription",
  purchasePlan: "/purchase-plan",
  tmPurchase: "/tmcallback/purchase",
  tmLogin: "/tmcallback/login",
  friendsPortal: "/friendsportal",
  registerPass: "/register-pass",
  tmRegisterPass: "/tmcallback/register-pass",
  acceptRequest: "/accept-request",
  acceptEvent: "/accept_event",
  purchaseTickets: "/purchase-tickets",
  membershipPortal: "/membership_portal",
  membershipSitWithFriend: "/membership_portal/sit_with_friend",
  membershipPassOverview: "/membership_portal/pass_overview",
  membershipManageContacts: "/membership_portal/manage_contacts",
  membershipManageSubscription: "/membership_portal/manage_subscription",
};

// Only available while user signed in
export const privateRoutes = [
  routes.mySubscription,
  routes.friendsPortal,
  routes.membershipPortal,
  routes.membershipSitWithFriend,
  routes.membershipPassOverview,
  routes.membershipManageContacts,
  routes.membershipManageSubscription,
];

// Disabled routes while user signed in
export const loggedInDisabledRoutes = [
  routes.home,
  routes.tmLogin,
  routes.registerPass,
  routes.tmRegisterPass,
];

//Default redirects
export const loginRedirect = routes.membershipPortal;
export const homeRedirect = routes.home;

import React from "react";
import config from "src/config";
import {
  Paper,
  Box,
  Avatar,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import { User as CheckSquareIcon } from "react-feather";
import { WarningOutlined } from "@material-ui/icons";

export default function SitWithFriendDisabledNotice({ accountUserName }) {
  const classes = useStyles();

  return (
    <Paper style={{ marginTop: 10, marginBottom: 30 }} elevation={4}>
      <Box
        p={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box pr={2}>
          <Avatar
            style={{
              backgroundColor: colors.red["500"],
            }}
          >
            <WarningOutlined />
          </Avatar>
        </Box>
        <Box>
          <Box>
            <Typography
              style={{ fontFamily: "Raleway", fontWeight: 500 }}
              color="textSecondary"
              variant="subtitle2"
            >
                Sit with friends is temporarily disabled. Check back later. 
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  userName: {
    textDecoration: "underline",
    color: config.COLORS.primary,
  },
}));

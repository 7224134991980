import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "./localStorage";
import config from "src/config";
import {
  privateRoutes,
  loggedInDisabledRoutes,
  loginRedirect,
  homeRedirect,
} from "src/routes";

export default function ManageRoute({ children, path, ...rest }) {
  const reactLocalStorage = useLocalStorage();
  const history = useHistory();

  useEffect(() => {
    validateRoute();
  }, [history.location]);

  const validateRoute = () => {
    const token = reactLocalStorage.getItem(
      `${config.TEAM_ID}_MA_REFRESH_TOKEN`
    );
    const subscriber = reactLocalStorage.getItem(
      `${config.TEAM_ID}_MA_SUBSCRIBER`
    );

    const isLoggedIn = token && subscriber;
    const route = history.location.pathname;
    const isPrivateRoute = privateRoutes.includes(route);
    const isLoginDisabledRoute = loggedInDisabledRoutes.includes(route);

    // If user is not logged in but visits private route
    // redirect him to the home
    if (!isLoggedIn && isPrivateRoute) {
      history.push(homeRedirect);
    }

    // If user is logged in but visits disabled route
    // redirect him to the membership portal
    if (isLoggedIn && isLoginDisabledRoute) {
      history.push(loginRedirect);
    }
  };

  return <>{children}</>;
}

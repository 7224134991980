import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

// Creating context for global state
const QueryContext = React.createContext();

// Creating provider component
export const QueryContextProvider = ({ children }) => {
  const history = useHistory();

  // Setting initial search params from history object
  const [params, setParams] = useState(history.location.search);

  return (
    <QueryContext.Provider value={[params, setParams]}>
      {children}
    </QueryContext.Provider>
  );
};

// Creating the actual hook
export function useQuery() {
  const history = useHistory();

  // Distructuring the state from context
  const [params, setParams] = useContext(QueryContext);

  // Instantiate the native url search class with the state params
  const nativeSearch = new URLSearchParams(params);

  const set = (item, value) => {
    nativeSearch.set(item, value);
    let search = nativeSearch.toString();
    history.replace({ search });
    setParams(search);
  };

  const get = (item) => {
    return nativeSearch.get(item);
  };

  const removeAll = () => {
    for (let [item] of nativeSearch) {
      nativeSearch.delete(item);
    }
    setParams("");
  };

  // const clear = () => {
  //   setParams("");
  // };

  return {
    params,
    set,
    get,
    removeAll,
  };
}

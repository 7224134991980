import React from "react";
import {
  Box,
  Typography,
  makeStyles,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Hidden,
} from "@material-ui/core";
import { useHistory } from "react-router";
import config from "src/config";
import { useQuery } from "src/hooks/url_query_params";

export default function TopBar({ modules }) {
  const history = useHistory();
  const query = useQuery();
  const classes = useStyles();

  return (
    <Box p={2}>
      <List dense className={classes.list}>
        {modules.map((module) => (
          <ListItem
            button={true}
            selected={history.location.pathname === module.url}
            className={classes.listItem}
            key={module.id}
            onClick={() => {
              query.removeAll();
              history.push(module.url);
            }}
          >
            <ListItemIcon className={classes.listIcon}>
              {module.icon}
            </ListItemIcon>
            <ListItemText className={classes.listText} primary={module.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    display: "grid",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  listIcon: {
    minWidth: 25,
    "& svg": {
      fontSize: 18,
    },
  },
  listText: {
    flex: "none",
    [theme.breakpoints.down("xs")]: {
      flex: "1 1 auto",
    },
    "& .MuiListItemText-primary": {
      color: config.COLORS.primary,
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: 10,
      whiteSpace: "nowrap",
      textDecoration: "underline",
    },
  },

  listItem: {
    display: "flex",
    justifyContent: "center",
    "&.Mui-selected": {
      "& .MuiListItemText-primary": {
        color: config.COLORS.primary,
      },

      "& .MuiListItemIcon-root": {
        color: config.COLORS.primary,
      },
    },
  },
}));

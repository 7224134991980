import React from "react";
import BannerImage from "src/images/iowa/banner6.jpg";
import LogoImage from "src/images/iowa/logo_original.png";

const PAGE_TITLES = {
  home: {
    heading: "CYCLONE MOBILE PASS",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "Cyclone Mobile Pass",
    subtitle: "Manage your pass settings, make changes and send friend requests.",
  },
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "Verify your email address",
    description:
      "Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  teamAccountButton: "CONNECT CYCLONE ATHLETICS ACCOUNT",
  passSelectionHeader: "Ticket Options",
  membershipSection: {
    title: "MANAGE MEMBERSHIP",
    question: "Returning ticket holder?",
    description: "Click this button to lookup and manage your existing membership.",
  },
  announcementBarText: "",
  registerTerms: {
    enabled: false,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program Terms and Conditions.
      </p>
    ),
  },
};

// TO DO : Upload new logo image to the s3

const UI_DIMENSION = {
  eventImageWidth: 50,
};

const SITE_SEO = {
  title: "IOWA STATE CYCLONE",
  description: "Register your Iowa State Cyclone Mobile Pass",
  favicon: LogoImage,
};

export default {
  SITE_TITLE: "IOWA STATE CYCLONE",
  TEAM_NAME: "Iowa State Cyclone",
  TEAM_ID: 189,
  PRODUCT_NAME: "MOBILE PASS",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  IS_RECURRING: false,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://iowa-subs-prod.herokuapp.com/api",
  SS_FE_BASE_URI: "https://iowastate.seasonshare.com",
  ADMIN_BASE_URI: "https://subs-janitor-prod.herokuapp.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#C8102E",
    secondary: "#F1BE48",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0.6,
    membershipOverlay: 0.6,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  HOME_HERO_TEXT: {
    heading: "CYCLONE MOBILE PASS",
    subtitle: "",
  },
  CHECKOUT_HERO_TEXT: {
    heading: "CYCLONE MOBILE PASS",
    subtitle:
      "Use all or some of your credit to reserve the games you want to attend. You cannot remove games once reserved, but you can always come back and add.",
  },
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

import React, { useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { withFormik } from "./withFormik";
import PersonalDetails from "./PersonalDetails";
import BillingDetails from "./BillingDetails";
import CardDetails from "./CardDetails";
import SubmitButton from "./SubmitButton";
import ErrorChip from "./ErrorChip";
import AcceptNotice from "./AcceptNotice";

export default withFormik(({ formik }) => {
  const { handleSubmit } = formik;

  return (
    <Paper variant="outlined">
      <Box p={3} pt={2}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <PersonalDetails {...{ formik }} />
          <BillingDetails {...{ formik }} />
          <CardDetails {...{ formik }} />
          <AcceptNotice {...{ formik }} />
          <ErrorChip {...{ formik }} />
          <SubmitButton {...{ formik }} />
        </form>
      </Box>
    </Paper>
  );
});

import React from "react";
import config from "src/config";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  welcomeLabel: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "50px",
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down("sm")]: {
      right: "10px",
    },
  },
  loginButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: "18px",
    padding: "10px 40px",
    borderRadius: 35,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 28px",
    },
  },
}));

export default function Header({
  isAccountConnected,
  accountNumber,
  loginUser,
}) {
  const classes = useStyles();

  return (
    <div className="headers">
      <div className="site-logo">
        <a href="/">
          <img src={config.SITE_LOGO_URI} alt="" />
        </a>
      </div>
      {/* <h1 style={{ color: config.COLORS.primary }}>
            {config.TEAM_NAME.toUpperCase()} <b>{config.PRODUCT_NAME}</b>
          </h1> */}
      <div className={classes.welcomeLabel}></div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  Typography,
  Box,
  Divider,
  Avatar,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  colors,
  CircularProgress,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import {
  DateRangeRounded,
  ConfirmationNumberOutlined,
} from "@material-ui/icons";
import config from "src/config";

export default function PlanDetails({ subscriptionDetails }) {
  const styles = useStyles();
  const { planName, planDescription, numSeats } = subscriptionDetails;

  if(!planName){
    return null;
  }

  return (

    <Box key={uuid()} className={styles.plan} p={2}>
      <Box>
        <ConfirmationNumberOutlined
          style={{
            color: colors.grey["600"],
            fontSize: 32,
            marginTop: 5,
          }}
        />
      </Box>

      <Box>
        <Typography
          variant="h6"
          color="textPrimary"
          className={styles.planTitle}
        >
          {planName}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={styles.planSeat}
        >
          {numSeats} {numSeats > 1 ? "seats" : "seat"}
        </Typography>
        {/* <Typography
          variant="subtitle1"
          color="textSecondary"
          className={styles.planMeta}
        >
          {planDescription}
        </Typography> */}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: 24,

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },

  // New styles
  plan: {
    marginBottom: 10,
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    display: "grid",
    gridTemplateColumns: "50px 1fr",
    alignItems: "flex-start",
  },
  planTitle: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 16,
  },

  planMeta: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.3,
    marginTop: 4,
    color: colors.grey[600],
  },
  planSeat: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.3,
    marginTop: 2,
    color: theme.palette.success.main,
  },

  eventImage: {
    "& img": {
      width: 40,
    },
  },
}));

import React, { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  Box,
  makeStyles,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import config from "src/config";
import { PhoneIphoneOutlined } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useLocalStorage } from "src/components/localStorage";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid"),
});

export default function SendRequest() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const reactLocalStorage = useLocalStorage();
  const [subscriber, setSubscriber] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_SUBSCRIBER`)
  );

  const initialValues = {
    phoneNumber: "",
  };
  const onSubmit = (values) => {
    handleSendFriendRequest();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    errors,
    values,
    handleChange,
    handleReset,
    handleSubmit,
    dirty,
    isValid,
  } = formik;

  const handleSendFriendRequest = async () => {
    setLoading(true);
    let endpoint = `${config.ADMIN_URI}/friends/request`;
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        fromPhoneNumber: subscriber.phoneNumber,
        toPhoneNumber: "+1" + values.phoneNumber,
      });

      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }

    // Resetting the form state to empty
    handleReset();
    setLoading(false);
  };

  return (
    <Box>
      <ToastContainer />
      <Box textAlign="center">
        <Typography variant="h5" color="textPrimary" className={classes.title}>
          Send friend request
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Enter your friend's cell phone number
        </Typography>
      </Box>
      <Box pt={3}>
        <form onSubmit={handleSubmit}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Grid container spacing={4} justify="center">
                <Grid item xs={12} style={{ display: "flex" }}>
                  <TextField
                    variant="outlined"
                    value="+1"
                    disabled
                    size="medium"
                    className={classes.phonePrefix}
                  />
                  <TextField
                    fullWidth
                    size="medium"
                    name="phoneNumber"
                    placeholder="PHONE NUMBER"
                    variant="outlined"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    className={classes.phoneInput}
                    error={errors.phoneNumber && true}
                    helperText={errors.phoneNumber}
                    InputProps={{
                      endAdornment: <PhoneIphoneOutlined />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disableElevation
                    fullWidth
                    className={classes.submitButton}
                    disabled={!(isValid && dirty) || loading}
                  >
                    Send Request
                    {loading && (
                      <CircularProgress
                        size={14}
                        style={{
                          marginLeft: 5,
                          color: "white",
                          marginTop: "-3px",
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Raleway",
    fontWeight: 800,
  },
  phonePrefix: {
    maxWidth: 50,
    "& .MuiInputBase-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,

      "& fieldset": {
        borderRight: 0,
      },
    },
  },
  phoneInput: {
    "& .MuiInputBase-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  submitButton: {
    backgroundColor: config.COLORS.primary,
    fontSize: 18,
    padding: "10px 30px",
    borderRadius: 30,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
}));

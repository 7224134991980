import React, { useEffect } from "react";
import {
  TextField,
  makeStyles,
  Typography,
  colors,
  Box,
  Select,
  FormControl,
  Grid,
  Divider,
} from "@material-ui/core";
import config from "src/config";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

// Generating months and years to use in dropdown
let months = [];
let years = [];

for (let i = 1; i <= 12; i++) {
  let num = i.toString();
  let month = num.length < 2 ? "0" + i : i;
  months.push(month);
}
for (let i = 2021; i <= 2040; i++) {
  years.push(i.toString());
}

export default function MatMultiSelect(props) {
  const styles = useStyles();
  const { label, formik } = props;

  const { values, handleChange, setFieldValue, errors, submitCount } = formik;

  // Formatting card expiry to mm/yy
  useEffect(() => {
    const { expiryMonth, expiryYear } = values;
    const cardExpiry = `${expiryMonth}${expiryYear}`;
    setFieldValue("ccExp", cardExpiry);
  }, [values]);

  return (
    <>
      {label && (
        <Typography variant="subtitle2" className={styles.inputLabel}>
          {label} *
        </Typography>
      )}

      <Box display="flex">
        <Box flexGrow={1} flexBasis={1}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(styles.inputField, styles.monthInput)}
          >
            <Select
              name="expiryMonth"
              value={values.expiryMonth}
              onChange={handleChange}
              placeholder="Month"
              native
            >
              <option value="" disabled>
                mm
              </option>

              {months.map((month) => (
                <option key={uuid()} value={month}>
                  {month}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Divider orientation="vertical" className={styles.divider} />
        </Box>
        <Box flexGrow={1} flexBasis={1}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(styles.inputField, styles.yearInput)}
          >
            <Select
              name="expiryYear"
              onChange={handleChange}
              value={values.expiryYear}
              placeholder="Year"
              native
            >
              <option value="" disabled>
                yyyy
              </option>

              {years.map((year) => (
                <option key={uuid()} value={year.slice(2, 4)}>
                  {year}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {errors.ccExp && submitCount > 0 && (
        <Box>
          <Typography variant="caption" className={styles.errorMessage}>
            {errors.ccExp}
          </Typography>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: colors.grey[800],
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  inputField: {
    "& .MuiInputBase-root": {
      color: colors.grey[600],
    },

    "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: config.COLORS.primary,
    },
    "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F44336",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: config.COLORS.primary,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#F44336",
    },
  },

  monthInput: {
    "& fieldset": {
      borderRightColor: "transparent",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  yearInput: {
    "& fieldset": {
      borderLeftColor: "transparent",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },

  divider: {
    backgroundColor: `rgba(0, 0, 0, 0.23)`,
  },
  errorMessage: {
    color: theme.palette.error.main,
    lineHeight: 1,
  },
}));

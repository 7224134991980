import React, { useState } from "react";
import config from "src/config";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  TextField,
  makeStyles,
  CircularProgress,
  colors,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneIphoneOutlinedIcon from "@material-ui/icons/PhoneIphoneOutlined";
import { User as UserIcon } from "react-feather";
import LoginText from "./LoginText";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(1, "Please enter a valid first name"),
  lastName: Yup.string().min(1, "Please enter a valid last name"),
  emailAddress: Yup.string().email("Please enter a valid email"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid"),
  password: Yup.string().min(6, "Please enter a password of minimum 6 digit"),
});

export default function PassRegForm({ emailAddress, handleRegister }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSubmitting, setIsSubmitting] = useState(false);

  let initialValues = {
    firstName: "",
    lastName: "",
    emailAddress: emailAddress || "",
    phoneNumber: "",
    // password: "",
  };
  let onSubmit = async (values) => {
    handleRegSubmit();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    touched,
    isValid,
    dirty,
  } = formik;

  const handleRegSubmit = async () => {
    setIsSubmitting(true);
    await handleRegister(values);
    setIsSubmitting(false);
  };

  return (
    <>
      <Paper variant="outlined">
        <Box
          p={1}
          pl={2}
          pr={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <UserIcon size={24} />
            <Typography
              variant="h5"
              className={classes.cardTitle}
              color="textPrimary"
            >
              Insert your details
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={4}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder="FIRST NAME"
                  value={values.firstName}
                  name="firstName"
                  fullWidth
                  required
                  onChange={handleChange}
                  error={errors.firstName && true}
                  helperText={errors.firstName}
                  InputProps={{
                    endAdornment: (
                      <TextFieldsOutlinedIcon
                        className={classes.inputAdornment}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder="LAST NAME"
                  value={values.lastName}
                  name="lastName"
                  fullWidth
                  required
                  onChange={handleChange}
                  error={errors.lastName && true}
                  helperText={errors.lastName}
                  InputProps={{
                    endAdornment: (
                      <TextFieldsOutlinedIcon
                        className={classes.inputAdornment}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled
                  variant="outlined"
                  placeholder="EMAIL ADDRESS"
                  value={values.emailAddress}
                  name="emailAddress"
                  fullWidth
                  required
                  onChange={handleChange}
                  error={errors.emailAddress && true}
                  helperText={errors.emailAddress}
                  InputProps={{
                    endAdornment: (
                      <EmailOutlinedIcon className={classes.inputAdornment} />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ display: "flex" }}>
                <TextField
                  disabled
                  placeholder="+1"
                  variant="outlined"
                  className={classes.phonePrefix}
                />
                <TextField
                  variant="outlined"
                  placeholder="PHONE NUMBER"
                  value={values.phoneNumber}
                  name="phoneNumber"
                  fullWidth
                  required
                  onChange={handleChange}
                  error={errors.phoneNumber && true}
                  helperText={errors.phoneNumber}
                  className={classes.phoneInput}
                  InputProps={{
                    endAdornment: (
                      <PhoneIphoneOutlinedIcon
                        className={classes.inputAdornment}
                      />
                    ),
                  }}
                />
              </Grid>

              {/* <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      placeholder="PASSWORD"
                      type="password"
                      value={values.password}
                      name="password"
                      fullWidth
                      required
                      onChange={handleChange}
                      error={errors.password && true}
                      helperText={errors.password}
                      InputProps={{
                        endAdornment: (
                          <LockOutlinedIcon
                            className={classes.inputAdornment}
                          />
                        ),
                      }}
                    />
                  </Grid> */}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disableElevation
                  className={classes.submitButton}
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  REGISTER
                  {isSubmitting && (
                    <CircularProgress
                      size={16}
                      style={{
                        marginLeft: 10,
                        marginTop: "-3px",
                        color: "white",
                      }}
                    />
                  )}
                </Button>
                <LoginText />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: 20,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
  phonePrefix: {
    width: 64,

    "& .MuiInputBase-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& fieldset": {
      borderRight: 0,
    },
  },
  phoneInput: {
    "& .MuiInputBase-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  inputAdornment: {
    color: colors.grey["600"],
  },
  cardTitle: {
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: 24,
    marginLeft: 15,

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
}));

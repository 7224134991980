import config from "src/config";

// Eventually all APIs will point here
// Keep track of what APIs still need to be migrated
export const SubsApiEndpoint = config.ADMIN_URI;

export const purchaseEndPoint = "/subscription/purchase";
export const teamSettingsEndPoint = "/teams/team_configuration";
export const friendRequestEndPoint = "/friendrequest";
export const linkAccountEndpoint = "/teamAuth/linkAccount";
export const loginEndPoint = "/subscription/login";
export const workEndPoint = "/howitworks";

// migrated to subs janitor api
export const getTeamAuthUrl = `${SubsApiEndpoint}/teamAuth/url/${config.TEAM_ID}`;
export const loginTicketmasterAuthCode = `${SubsApiEndpoint}/teamAuth/login`;
export const refreshTicketmasterToken = `${SubsApiEndpoint}/teamAuth/refresh`;

import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import config from "src/config";
import { HighlightOff } from "@material-ui/icons";

export default function Error() {
  const classes = useStyles();
  return (
    <Box>
      <Box textAlign="center">
        <HighlightOff className={classes.icon} />
      </Box>
      <Box textAlign="center">
        <Typography variant="h3" className={classes.title} color="textPrimary">
          Something went wrong
        </Typography>
      </Box>
      <Box textAlign="center" mt={1}>
        <Typography
          className={classes.subtitle}
          variant="h5"
          color="textSecondary"
        >
          Please use a valid invitaion url and try again
        </Typography>
      </Box>
      {/* <Box textAlign="center" mt={2}>
        <Typography
          className={classes.caption}
          variant="subtitle2"
          color="textSecondary"
        >
          Please wait while we redirecting you.{" "}
          <a href="/" className={classes.link}>
            Click here
          </a>{" "}
          if it doesn't redirect you automatically.
        </Typography>
      </Box> */}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 32,
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
  },
  caption: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.1,
  },
  link: {
    color: config.COLORS.primary,
    textDecoration: "underline",
  },
  icon: {
    color: "#e57373",
    fontSize: 42,
  },
}));

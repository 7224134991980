import React, { useState } from "react";
import config from "src/config";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../css/home.scss";
import Swal from "sweetalert2";
import "react-phone-input-2/lib/style.css";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import * as WorkAction from "src/state/howItWorks/worksAction";
import GameDetailModal from "src/components/GameDetailModal";

// Refractored components
import Header from "src/components/Header";
import Banner from "src/components/Banner";
import PassOptions from "./PassOptions";
import Membership from "./Membership";
import HowItWorks from "./HowItWorks";
import Footer from "./Footer";
import { ContactsOutlined } from "@material-ui/icons";
import AnnouncementBar from "src/components/AnnouncementBar";
import { ToastContainer } from "react-toastify";

export default function Home() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [teamAccountUrl, setTeamAccountUrl] = useState("");
  const [accountNumber] = useState("");
  const [isAccountConnected] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isNotForSale, setIsNotForSale] = useState(false);
  const [workStep, setWorkStep] = useState([]);

  React.useEffect(() => {
    window.mixpanel.track("Home Page View", {});
    getSubscriptionPlan();
    getTeamAccountUrl();
  }, []);

  const getSubscriptionPlan = async () => {
    // get subscription plans
    let response = await axios.get(`${config.API_BASE_URI}/subscription/plans`);
    setSubscriptionPlans(response.data["subscriptionPlans"]);

    // Checking all plans
    // If all plans are not for sale then empty state will show
    // if any plan is available for sale then all plan will show
    let isAllNotForSale = response.data["subscriptionPlans"].every(
      (plan) => plan.isAvailableForSale === false
    );
    setIsNotForSale(isAllNotForSale);
  };

  const getTeamAccountUrl = async () => {
    // get team account URL
    axios.get(`${config.API_BASE_URI}/teamAuth/url/${config.TEAM_ID}`).then(
      async (response) => {
        setTeamAccountUrl(response.data.url);
        const params = new URLSearchParams(history.location.search);
        const code = params.get("code");
        if (code) {
          const isDevelopment = config.IS_DEVELOPMENT; // read this from config
          if (isDevelopment) alert("DEV MODE");
          // Login from janitor not from pass backend
          // const login = await dispatch(
          //   loginAction.login({
          //     authCode: code,
          //     teamId: config.TEAM_ID,
          //     isDevelopment: isDevelopment,
          //     redirectUri: `${config.SS_FE_BASE_URI}/tmcallback/login`,
          //   })
          // );
          // found a subscription, login
          //   if (login !== 0 && login !== undefined) {
          //     history.push("/my-subscription");
          //   }
        }
      },
      (err) => {
        console.log(err);
      }
    );

    // const howItWorks = await dispatch(WorkAction.howItWorks());
    // setWorkStep(howItWorks);
  };

  // const lookupAccount = () => {
  //   let replaced = teamAccountUrl.replace(
  //     "REDIRECT_URL",
  //     `${config.API_BASE_URI}/tmcallback/linkAccount`
  //   ); // redirect back to where we came from, handle in params
  //   let finalUrl = replaced.replace("STATE", `UPLOAD-${config.teamId}`);
  //   window.location.href = `${finalUrl}`;
  // };

  const MySwal = withReactContent(Swal);

  let phSwal = {
    title: "Verify your student email",
    focusConfirm: false,
    html: `
    <h1 class="modal-text">Enter your student email to confirm pass eligibility. <br /> </h1>
    <input class="swal2-input" id="phone" type="text" placeholder="Email Address" /><br />
    `,
    footer:
      ' <h1 class="modal-footer">Have questions? <a href="/">Contact Us</a><br />Your Ticket Agent is with you every step of the way. </h1>',
    confirmButtonText: "VERIFY EMAIL",
    allowOutsideClick: true,
    preConfirm: () => ({
      phone: document.getElementById("phone").value,
    }),
  };

  const handleBuyNow = async (index) => {
    // handle
    const thisPlan = subscriptionPlans[index];
    if (thisPlan["isExclusive"]) {
      // const swalval = await MySwal.fire(phSwal);
      // console.log("Phone Number", swalval.value && swalval.value.phone);
      history.push(`/register-pass?eventCode=${thisPlan["planCode"]}`);
    } else {
      history.push(`/purchase-plan?eventCode=${thisPlan["planCode"]}`);
    }
  };

  const handleGameDetail = async (passName, subscriptionPlanId) => {
    GameDetailModal(passName, subscriptionPlanId);
  };

  const loginUser = async () => {
    let replaced = teamAccountUrl.replace(
      "REDIRECT_URL",
      `${config.SS_FE_BASE_URI}/tmcallback/login`
    ); // redirect back to where we came from, handle in params
    let finalUrl = replaced.replace("STATE", `LOGIN-${config.TEAM_ID}`);
    window.location.href = `${finalUrl}`;
  };

  return (
    <div>
      <ToastContainer />
      <AnnouncementBar />
      <Header {...{ isAccountConnected, accountNumber, loginUser }} />
      <Banner
        heading={config.PAGE_TITLES.home.heading}
        subtitle={config.PAGE_TITLES.home.subtitle}
      />
      <PassOptions
        {...{ subscriptionPlans, isNotForSale, handleBuyNow, handleGameDetail }}
      />
      {/* <Membership {...{ loginUser }} /> */}
      {/* {workStep && workStep.length > 0 && <HowItWorks {...{ workStep }} />} */}

      <Footer />
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import config from "src/config";
import styled from "styled-components";
import "../css/home.scss";
import { useSelector } from "react-redux";
import PassCard from "./PassCard";
import {
  Grid,
  Box,
  Typography,
  Container as MuiContainer,
  Paper,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Bookmark as BookmarkIcon, Globe as GlobeIcon } from "react-feather";
import ContactInfoForm from "./ContactInfoForm";
import Banner from "src/components/Banner";
import { toast } from "react-toastify";
import AvailableGames from "./AvailableGames";
import axios from "axios";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import ExitPortalButton from "src/components/ExitPortalButton";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: "32px !important",
  },
  subtitle: {
    fontFamily: "Raleway",
    fontSize: "18px !important",
  },
  welcomeLabel: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "50px",
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down("sm")]: {
      right: "10px",
    },
  },
}));

export default function MySubscription() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [events, setEvents] = useState(null);
  const subscriber = useSelector((state) =>
    state.purchase.subscriber ? state.purchase.subscriber : false
  );

  const subscriptionPlan = useSelector((state) =>
    state.purchase.subscriptionPlan ? state.purchase.subscriptionPlan : false
  );

  // Development purpose
  //=================================
  // React.useEffect(() => {
  //   getTeamAccountUrl();
  // }, []);
  // const getTeamAccountUrl = async () => {
  //   const code = "gFw3l5OGMRfeXAr-IuGxiWWeOi6zgCb6ZkA&state";
  //   if (code) {
  //     await dispatch(
  //       loginAction.login({
  //         authCode: code,
  //         teamId: config.TEAM_ID,
  //       })
  //     );
  //   }
  // };

  // Redirect to login if white screen
  const subscriberRef = useRef(subscriber);
  subscriberRef.current = subscriber;
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (subscriberRef.current && !subscriberRef.current.fullName) {
        toast.error("Your session expired. Please login", {
          onClose: () => {
            window.location.href = "/";
          },
        });
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  // Getting available events in the plan
  useEffect(() => {
    if (subscriptionPlan && subscriptionPlan.id) {
      getAvailableEvents();
    }
  }, [subscriptionPlan]);

  const getAvailableEvents = async () => {
    let endpoint = `${config.API_BASE_URI}/event/schedule`;
    let body = {
      teamId: config.TEAM_ID,
      subscriptionPlanId: subscriptionPlan.id,
    };
    let response = await axios.post(endpoint, body);

    if (response.status === 200) {
      setEvents(response.data.events);
    }
  };

  // const handleCancel = () => {
  //   Swal.fire({
  //     title: "Cancel your Game Pass membership?",
  //     showDenyButton: true,
  //     showCancelButton: false,
  //     confirmButtonText: `Yes, cancel.`,
  //     denyButtonText: `Don't cancel`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const data = {
  //         subcriptionId: "",
  //       };
  //       axios.post(`${config.API_BASE_URI}/subscription/cancel`, data).then(
  //         (response) => {
  //           if (response.data.successfullyCanceled == true) {
  //             Swal.fire(
  //               "Cancelled!",
  //               "Your membership has been cancelled.",
  //               "success"
  //             );
  //           }
  //         },
  //         (err) => {
  //           Swal.fire(
  //             "Error",
  //             "Failed to cancel your membership. Contact support. ",
  //             "error"
  //           );
  //         }
  //       );
  //     }
  //   });
  // };

  return (
    <>
      <Container>
        <div className="headers">
          <div className="site-logo">
            <a href="/">
              <img src={config.SITE_LOGO_URI} alt="" />
            </a>
          </div>

          <div className={classes.welcomeLabel}>
            <ExitPortalButton />
          </div>
        </div>
        <Banner />
      </Container>

      <Box>
        <MuiContainer>
          {subscriptionPlan.planName && subscriber.fullName && (
            <Box pt={6} textAlign="center">
              <Box>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={classes.title}
                >
                  Your{" "}
                  {subscriptionPlan.planName && subscriber.fullName
                    ? subscriptionPlan.planName
                    : ""}{" "}
                  is active!
                </Typography>
              </Box>

              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  className={classes.subtitle}
                >
                  Use this portal to opt into games and manage communication
                  preferences. {!sm && <br />}Please contact support with any
                  questions about your membership
                </Typography>
              </Box>
            </Box>
          )}

          <Box mt={6} pb={6}>
            <Grid container justify="center">
              <Grid item xs={12} lg={10}>
                <Paper elevation={0}>
                  <Box p={sm ? 1 : 3}>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {subscriptionPlan.planName && subscriber.fullName && (
                          <>
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <BookmarkIcon />
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                style={{
                                  fontFamily: "Raleway",
                                  fontWeight: "bold",
                                  marginLeft: 15,
                                }}
                              >
                                Pass details
                              </Typography>
                            </Box>

                            <Box mt={2} flexGrow={1}>
                              <PassCard
                                plan={subscriptionPlan}
                                subscriber={subscriber}
                              />
                            </Box>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {subscriptionPlan.planName && subscriber.fullName && (
                          <>
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <GlobeIcon />
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                style={{
                                  fontFamily: "Raleway",
                                  fontWeight: "bold",
                                  marginLeft: 15,
                                }}
                              >
                                Contact Information
                              </Typography>
                            </Box>
                            <Box mt={2} flexGrow={1}>
                              <ContactInfoForm {...{ subscriber }} />
                            </Box>
                          </>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {events && (
                          <>
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                              mt={3}
                            >
                              <ConfirmationNumberOutlinedIcon />
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                style={{
                                  fontFamily: "Raleway",
                                  fontWeight: "bold",
                                  marginLeft: 15,
                                }}
                              >
                                Available Games
                              </Typography>
                            </Box>

                            <Box mt={2} flexGrow={1}>
                              <AvailableGames events={events} />
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </MuiContainer>
      </Box>
    </>
  );
}

const Container = styled.div`
  background-color: gainsboro;
`;

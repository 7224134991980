import React from "react";
import {
  TextField,
  makeStyles,
  Typography,
  colors,
  Box,
} from "@material-ui/core";
import config from "src/config";

export default function MatInput({ label, formik, name, placeholder }) {
  const styles = useStyles();

  return (
    <>
      {label && (
        <Typography variant="subtitle2" className={styles.inputLabel}>
          {label} *
        </Typography>
      )}

      <TextField
        variant="outlined"
        fullWidth
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        className={styles.inputField}
        size="small"
        placeholder={placeholder}
      />
      {formik.errors[name] && formik.submitCount > 0 && (
        <Box>
          <Typography variant="caption" className={styles.errorMessage}>
            {formik.errors[name]}
          </Typography>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: colors.grey[800],
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  inputField: {
    "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: config.COLORS.primary,
    },
    "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F44336",
    },

    // "& fieldset": {
    //   borderRadius: 0,
    // },

    "& .MuiFormLabel-root.Mui-focused": {
      color: config.COLORS.primary,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#F44336",
    },
  },
  errorMessage: {
    // marginLeft: 3,
    color: theme.palette.error.main,
    lineHeight: 1,
  },
}));

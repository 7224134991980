import { toast } from "react-toastify";
import config from "src/config";
import axios from "axios";

const server = config.ADMIN_URI;
const teamId = config.TEAM_ID;
export const errorToast = (error) => {
  toast.error(
    error?.response?.data?.message ||
      error?.response?.data ||
      "Something went wrong, please try again"
  );
};

export const updateCCDetails = async (payload) => {
  const endpoint = `${server}/ticketmaster/ccUpdate`;
  const response = await axios.post(endpoint, {
    teamId,
    ...payload,
  });
  return response;
};

export const getCCDetails = async (payload) => {
  const { accountNumber, subscriberId } = payload;
  const endpoint = `${server}/subscriptionProduct/getCCDetails`;
  const response = await axios.post(endpoint, {
    teamId,
    accountNumber,
    subscriberId,
  });

  return response;
};

export const getSubscriptionDetails = async (payload) => {
  const { emailAddress } = payload;
  const endpoint = `${server}/subscriptionProduct/getSubscriptionDetails`;

  try {
    const response = await axios.post(endpoint, { teamId, emailAddress });
    return Promise.resolve(response);
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
};

export const updateSubscriptionDetails = async (payload) => {
  const endpoint = `${server}/subscriptionProduct/changeSubscriptionStatus`;
  const { status } = payload;
  try {
    const response = await axios.put(endpoint, {
      teamId,
      ...payload,
    });
    status === "INACTIVE" && toast.warn(response.data.message);
    status === "ACTIVE" && toast.success(response.data.message);
    status === "PAUSED" && toast.success(response.data.message);
    return Promise.resolve(response);
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
};

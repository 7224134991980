import apiClient from "./config/apiClient";
import { purchaseEndPoint } from "./config/apiEndPoints";

export const purchaseApi = {
  purchasePass(data) {
    return apiClient.post(purchaseEndPoint, data).then((response) => {
      return response;
    });
  },
};

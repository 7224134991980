import React, { useEffect } from "react";
import { useFormik } from "formik";
import { validationSchema } from "./ValidationSchema";
import config from "src/config";

export const withFormik =
  (Form) =>
  ({ email, handleSubmit }) => {
    const personalInformation = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: email || "",
    };
    const billingDetails = {
      street: "",
      apartment: "",
      zipCode: "",
    };

    const cardDetails = {
      cardNumber: "",
      cardCvc: "",
      cardExpiry: "",
    };
    const initialValues = {
      ...personalInformation,
      ...billingDetails,
      ...cardDetails,
      termsAgreed: false,
    };

    const formik = useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

    const { setSubmitting } = formik;

    async function onSubmit(values) {
      setSubmitting(true);
      console.log("form success");
      await handleSubmit(values);
      setSubmitting(false);
    }

    return <Form {...{ formik }} />;
  };

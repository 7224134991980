import React from "react";
import BannerImage from "src/images/jaguars/jaguars_banner_oct_22_2.jpg";

const PAGE_TITLES = {
  home: {
    heading: "",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "",
    subtitle: "",
  },
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "Verify your email address",
    description:
      "This subscription plan is exclusive for students, Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "Ticket Options",
  membershipSection: {
    title: "MANAGE MEMBERSHIP",
    question: "Returning ticket holder?",
    description:
      "Click this button to lookup and manage your existing membership.",
  },
  announcementBarText: "",
  registerTerms: {
    enabled: false,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket
        delivery along with the program Terms and Conditions.
      </p>
    ),
  },
};

const UI_DIMENSION = {
  eventImageWidth: 80,
};

const SITE_SEO = {
  title: "JACKSONVILLE JAGUARS",
  description: "Purchase or manage your Jacksonville Jaguars Prowl Pass",
  favicon:
    "https://s3.us-east-2.amazonaws.com/ss-leagues/NFL/jacksonville-jaguars.png",
};

export default {
  SITE_TITLE: "JACKSONVILLE JAGUARS",
  TEAM_NAME: "Jacksonville Jaguars",
  TEAM_ID: 97,
  PRODUCT_NAME: "GAME PASS",
  SITE_LOGO_URI:
    "https://s3.us-east-2.amazonaws.com/ss-leagues/NFL/jacksonville-jaguars.png",
  IS_DEVELOPMENT: false,
  IS_RECURRING: false,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "http://fanhub.seasonshare.com/jaguars",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://passbackend.seasonshare.com/api",
  SS_FE_BASE_URI: "https://prowlpass.seasonshare.com",
  ADMIN_BASE_URI: "https://subsjanitor.seasonshare.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#006778",
    secondary: "#D7A22A",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0,
    membershipOverlay: 0,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  HOME_HERO_TEXT: {
    heading: "JACKSONVILLE JAGUARS GAME PASS",
    subtitle: "Pick your favorite combination of matchups and seat location.",
  },
  CHECKOUT_HERO_TEXT: {
    heading: "JACKSONVILLE JAGUARS GAME PASS",
    subtitle:
      "Use all or some of your credit to reserve the games you want to attend. You cannot remove games once reserved, but you can always come back and add.",
  },
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

import React, { useEffect, useState } from "react";
import {
  Dialog,
  makeStyles,
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  colors,
} from "@material-ui/core";
import { HighlightOffRounded, CheckCircleOutline } from "@material-ui/icons";
import config from "src/config";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import Popup from "src/components/Popup";

export default function AcceptRequest({
  friend,
  isPortal,
  getPendingFriends,
  getMyGroup,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleDialog = (open) => setOpen(open);
  const [responding, setResponding] = useState(false);
  const [declining, setDeclining] = useState(false);

  // useEffect(() => console.log(friend), [friend]);

  const updateUi = (response) => {
    isPortal &&
      toast.success(response.data.message) &&
      getPendingFriends() &&
      getMyGroup();
    !isPortal &&
      toast.success(response.data.message, {
        onClose: () => {
          history.push("/");
        },
      });
  };

  const responseFriendRequest = async () => {
    let endpoint = `${config.ADMIN_URI}/friends/response`;
    setResponding(true);
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        toPhoneNumber: friend.toPhoneNumber,
        fromPhoneNumber: friend.fromPhoneNumber,
      });

      if (response.status === 200) {
        updateUi(response);
      }
    } catch (error) {
      setResponding(false);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
  };

  const cancelFriendRequest = async () => {
    let endpoint = `${config.ADMIN_URI}/friends/reject`;
    setDeclining(true);
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        toPhoneNumber: friend.toPhoneNumber,
        fromPhoneNumber: friend.fromPhoneNumber,
      });

      if (response.status === 200) {
        updateUi(response);
      }
    } catch (error) {
      setDeclining(false);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.actionButton}
        onClick={() => handleDialog(true)}
      >
        Accept
      </Button>
      <Popup {...{ open }}>
        <HighlightOffRounded
          className={classes.popupCloseButton}
          onClick={() => handleDialog(false)}
        />

        <Box p={2}>
          <Alert
            severity="info"
            classes={{
              icon: classes.alertIcon,
              root: classes.alert,
            }}
          >
            <Box>
              <Typography className={classes.popupText} variant="subtitle1">
                <b>
                  You are currently in a ticket group. If you accept this
                  request you will be removed from your current group and added
                  to the new group.
                </b>
                <br />
                <br />
                If you want this person to sit with your current group, please
                decline the invitation and send them a request yourself.
              </Typography>
            </Box>

            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    disabled={declining || responding}
                    size="medium"
                    fullWidth
                    disabled={responding}
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={clsx(
                      classes.popupActionButton,
                      classes.acceptButton
                    )}
                    onClick={responseFriendRequest}
                  >
                    <CheckCircleOutline className={classes.buttonIcon} />
                    Accept
                    {responding && (
                      <CircularProgress
                        size={12}
                        style={{
                          marginLeft: 5,
                          marginTop: -2,
                          color: "white",
                        }}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    disabled={responding || declining}
                    size="medium"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={clsx(
                      classes.popupActionButton,
                      classes.declineButton
                    )}
                    onClick={cancelFriendRequest}
                  >
                    <HighlightOffRounded className={classes.buttonIcon} />
                    decline
                    {declining && (
                      <CircularProgress
                        size={12}
                        style={{
                          marginLeft: 5,
                          marginTop: -2,
                          color: "white",
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Alert>
        </Box>
      </Popup>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    backgroundColor: config.COLORS.primary,
    fontSize: 14,
    // padding: "8px 15px",
    borderRadius: 30,
    marginRight: 10,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },

    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  },

  alert: {
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
  },

  alertIcon: {
    fontSize: 48,
    margin: 0,
  },

  popupText: {
    fontSize: 18,
    color: "black",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "center",
  },
  popupCloseButton: {
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "pointer",
    color: colors.grey[500],
  },

  buttonIcon: {
    color: "white",
    marginRight: 8,
    fontSize: 18,
  },

  acceptButton: {
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[500],
    },
  },

  declineButton: {
    backgroundColor: colors.grey[600],
    "&:hover": {
      backgroundColor: colors.grey[500],
    },
  },
  popupActionButton: {
    fontFamily: "Raleway",
    fontWeight: 500,
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));

import React, { useState } from "react";
import MatInput from "src/components/MatInput";
import {
  Box,
  Typography,
  Grid,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import config from "src/config";
import { toast } from "react-toastify";
import axios from "axios";

export default function PersonalDetails({ formik }) {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const { values } = formik;

  const handleTestPhone = async () => {
    setLoading(true);
    let endpoint = `${config.API_BASE_URI}/teamAuth/test/phone`;
    let { phoneNumber } = values;
    try {
      let response = await axios.post(endpoint, { phoneNumber });
      response.status === 200 && toast.success("Test success");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setLoading(false);
  };

  const PhoneTestButton = () => (
    <Button
      disableElevation
      disabled={loading}
      className={styles.testButton}
      size="small"
      variant="contained"
      onClick={handleTestPhone}
    >
      Test
      {loading && <CircularProgress size={10} className={styles.loader} />}
    </Button>
  );

  return (
    <>
      <Box mb={2}>
        <Typography
          className={styles.sectionTitle}
          variant="h6"
          color="textPrimary"
        >
          Personal Information
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MatInput
            required
            name="firstName"
            label="First Name"
            {...{ formik }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MatInput
            required
            name="lastName"
            label="Last Name"
            {...{ formik }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex">
            <MatInput
              disabled
              value="+1"
              className={styles.phonePrefix}
              onChange={() => ({})}
            />
            <Box width="100%">
              <MatInput
                required
                name="phoneNumber"
                label="Cell Phone"
                endAdornment={<PhoneTestButton />}
                {...{ formik }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MatInput
            required
            disabled
            name="email"
            label="Email Address"
            {...{ formik }}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  phonePrefix: {
    width: 60,
    "& fieldset": {
      borderRight: "none",
    },
  },
  testButton: {
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },

  loader: {
    color: config.COLORS.primary,
    marginLeft: 3,
    marginTop: -2,

    "& svg": {
      width: 10,
      height: 10,
    },
  },
}));

import React from "react";
import {
  Box,
  Grid,
  Typography,
  CardContent,
  Paper,
  ButtonGroup,
  Button,
  CardActions,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  colors,
} from "@material-ui/core";
import config from "src/config";
import VerifiedImage from "src/images/tm_verified_badge.png";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "auto",
  },
  mainText: {
    boxShadow: "none",
    fontWeight: 700,
    padding: "10px 0",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  subText: {
    boxShadow: "none",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  alignText: {
    textAlign: "center",
  },
  btnGroup: {
    width: "100%",
    justifyContent: "center",
    margin: "10px 0",
  },
  btnToggle: {
    border: "2px solid #c4c4c4 !important",
    margin: "0 20px !important",
    borderRadius: "50% !important",

    [theme.breakpoints.down("md")]: {
      margin: "0 8px !important",
    },
  },
  countText: {
    border: "2px solid #c4c4c4 !important",
    fontSize: 18,
    padding: "2px 14px",
    color: "#000000 !important",
  },
  imgWidth: {
    width: 170,
    [theme.breakpoints.down("md")]: {
      width: 130,
    },
  },
  footer: {
    borderTop: "1px solid #a9a9a9",
  },
  totalTitle: {
    marginLeft: "auto",
    marginRight: 10,
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },

  tableHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    fontSmooth: "2em",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  tableBodyText: {
    fontSize: "16px",
    fontWeight: "500",
    color: colors.grey["600"],

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  tableFooterText: {
    fontSize: "20px",
    fontWeight: "600",
    fontSmooth: "2em",
  },
  table: {
    "& .MuiTableCell-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "5px",
      },
    },
  },

  scheduleButton: {
    color: config.COLORS.typography,
    cursor: "pointer",
    textDecoration: "underline",
    padding: 0,
    fontSize: 18,
    fontFamily: "Raleway",
    backgroundColor: "transparent",
    border: "none",
    fontWeight: 500,

    "&:focus": {
      outline: "none",
    },
  },
  counterCell: {
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export default function OrderSummary({
  plan,
  counter,
  handleIncrement,
  handleDecrement,
  handleGameDetail,
}) {
  const classes = useStyles();

  return (
    <>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs className={classes.margin}>
            <Paper className={classes.mainText}>{plan.planName}</Paper>
            <Paper className={classes.subText}>
              {plan.planDescription}

              {config?.TEAM_ID !== 78 && (
                <div style={{ marginTop: 15 }}>
                  <button
                    onClick={() => handleGameDetail(plan.planName, plan.id)}
                    className={classes.scheduleButton}
                  >
                    View Schedule
                  </button>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid
            item
            xs
            className={classes.alignText}
            style={{
              justifySelf: "flex-end",
            }}
          >
            <ButtonGroup
              size="small"
              aria-label="small outlined button group"
              className={classes.btnGroup}
            >
              <Button className={classes.btnToggle} onClick={handleDecrement}>
                -
              </Button>
              <Button className={classes.countText} disabled>
                {counter}
              </Button>
              <Button className={classes.btnToggle} onClick={handleIncrement}>
                +
              </Button>
            </ButtonGroup>
            <img src={VerifiedImage} className={classes.imgWidth} alt="" />
          </Grid>
        </Grid>

        <Box pt={2} width="100%" display="flex" justifyContent="flex-end">
          <Grid container justify="flex-end">
            <Grid item xs={12} xl={7}>
              <Box>
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeading}>Description</TableCell>
                      <TableCell align="right" className={classes.tableHeading}>
                        Amount
                      </TableCell>
                      <TableCell className={classes.tableHeading}>Quantity</TableCell>
                      <TableCell align="right" className={classes.tableHeading}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableBodyText}>
                        {plan.monthlyCost !== 0 ? "Monthly Subscription" : "One time cost"}
                      </TableCell>
                      <TableCell align="right" className={classes.tableBodyText}>
                        {plan.monthlyCost !== 0
                          ? `$${plan.monthlyCost.toFixed(2)}`
                          : `$${plan.oneTimeCost.toFixed(2)}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={clsx(classes.tableBodyText, classes.counterCell)}
                        rowSpan={2}
                      >
                        {counter}
                      </TableCell>
                      <TableCell align="right" className={classes.tableBodyText}>
                        {plan.monthlyCost !== 0
                          ? `$${(plan.monthlyCost * counter).toFixed(2)}`
                          : `$${(plan.oneTimeCost * counter).toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    {plan?.serviceCharge && plan?.serviceCharge > 0 ? (
                      <TableRow>
                        <TableCell className={classes.tableBodyText}>Service charge</TableCell>
                        <TableCell align="right" className={classes.tableBodyText}>
                          {`$${plan.serviceCharge.toFixed(2)}`}
                        </TableCell>

                        {/* <TableCell align="center" className={classes.tableBodyText}>
                          {counter}
                        </TableCell> */}

                        <TableCell align="right" className={classes.tableBodyText}>
                          {`$${(plan.serviceCharge * counter).toFixed(2)}`}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}

                    {/* <TableRow>
                      <TableCell
                        align="right"
                        colSpan="2"
                        className={classes.tableFooterText}
                      >
                        Total Cost
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan="2"
                        className={classes.tableFooterText}
                      >
                        {plan.monthlyCost !== 0
                          ? `$${(
                              (plan.monthlyCost + plan.serviceCharge) *
                              counter
                            ).toFixed(2)}`
                          : `$${(
                              (plan.oneTimeCost + plan.serviceCharge) *
                              counter
                            ).toFixed(2)}`}
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <CardActions className={classes.footer}>
        {/* {plan.monthlyCost !== 0
                      ? plan.monthlyCost
                      : plan.oneTimeCost} */}

        <Typography className={classes.totalTitle}>
          Total: $
          {plan.monthlyCost !== 0
            ? `${(plan.monthlyCost * counter + plan.serviceCharge * counter).toFixed(2)}`
            : `${(plan.oneTimeCost * counter + plan.serviceCharge * counter).toFixed(2)}`}
        </Typography>
      </CardActions>
    </>
  );
}

import React, { useState } from "react";
import {
  Button,
  makeStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import config from "src/config";
import axios from "axios";
import { toast } from "react-toastify";

export default function LoginText() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleLogin = async () => {
    setLoading(true);
    let teamAuthUrl = `${config.ADMIN_URI}/teamAuth/url/${config.TEAM_ID}`;
    let redirectUri = `${config.SS_FE_BASE_URI}/tmcallback/login`;

    try {
      // Getting ticket master url from admin backend
      let teamAuthResponse = await axios.get(teamAuthUrl);
      let authUrl = teamAuthResponse.data.url;

      // Replacing ticket master REDIRECT URL according to our need
      let tmUrl = authUrl
        .replace("REDIRECT_URL", redirectUri)
        .replace("STATE", `LOGIN-${config.TEAM_ID}`);

      // Final redirection to the ticketmaster
      window.location.href = `${tmUrl}`;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.loginText}
      >
        Already have a pass?{" "}
        <span className={classes.link} onClick={handleLogin}>
          {" "}
          Login
        </span>
        {loading && (
          <CircularProgress
            size={13}
            style={{ marginLeft: 8, marginTop: -2, color: "white" }}
          />
        )}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  loginButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: "20px",
    padding: "15px 80px",
    borderRadius: 35,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },

  loginText: {
    marginTop: 5,
  },
  link: {
    color: config.COLORS.primary,
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

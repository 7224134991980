import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const apiReponseError = (error) => {
  if (error.response) {
    Swal.fire("Error", error.response.data.errorMessage, "error");
  } else if (error.request) {
    toast.error("Request was not processed");
  } else {
    toast.error(error.errorMessage);
  }
};

import React, { useEffect } from "react";
import { formatCreditCardNumber, formatCVC } from "src/utils/utils";
import {
  Box,
  Grid,
  Divider,
  Button,
  makeStyles,
  Typography,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MatInput from "./MatInput";
import config from "src/config";
import MatCardExpiry from "./MatCardExpiry";
import { useFormik } from "formik";
import { validationSchema } from "./ValidationSchema";
import { errorToast } from "./api_calls";
import { toast } from "react-toastify";

export default function UpdateCardForm({ saveNewCard, toggleCollapse }) {
  const styles = useStyles();

  const initialValues = {
    firstName: "",
    lastName: "",
    streetAddress: "",
    zipCode: "",
    ccNum: "",
    ccExp: "",
    // cardCvc: "",
    expiryMonth: "",
    expiryYear: "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleCardUpdate,
  });

  // Overriding formik handle change
  formik.handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "ccNum":
        let ccNum = formatCreditCardNumber(value);
        setFieldValue(name, ccNum);
        break;
      case "cardCvc":
        let cardCvc = formatCVC(value);
        setFieldValue(name, cardCvc);
        break;
      default:
        setFieldValue(name, value);
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    resetForm,
  } = formik;

  async function handleCardUpdate(values) {
    let { expiryMonth, expiryYear, ccNum, ...ccDetails } = values;
    setSubmitting(true);
    try {
      ccNum = ccNum.replaceAll(" ", "");
      const response = await saveNewCard({ ccNum, ...ccDetails });
      resetForm();
      toggleCollapse();
      toast.success(response.data.message);
    } catch (error) {
      console.log(error);
      errorToast(error);
    }
    setSubmitting(false);
  }

  return (
    <Paper variant="outlined" className={styles.paper}>
      <form onSubmit={handleSubmit}>
        <Divider />
        <Box p={2} pb={1} pt={1} mt={1}>
          <Typography className={styles.title} variant="h5" color="textPrimary">
            Update Card
          </Typography>
        </Box>

        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MatInput
                {...{ formik }}
                name="firstName"
                label="First Name"
                placeholder="Enter first name"
              />
            </Grid>
            <Grid item xs={6}>
              <MatInput
                {...{ formik }}
                name="lastName"
                label="Last Name"
                placeholder="Enter last name"
              />
            </Grid>
            <Grid item xs={12}>
              <MatInput
                {...{ formik }}
                name="streetAddress"
                label="Street Address"
                placeholder="Enter street address"
              />
            </Grid>
            <Grid item xs={6}>
              <MatInput
                {...{ formik }}
                name="zipCode"
                label="ZIP Code"
                placeholder="Enter ZIP code"
              />
            </Grid>
            <Grid item xs={12}>
              <MatInput
                {...{ formik }}
                name="ccNum"
                label="Enter Card Number"
                placeholder="xxxx xxxx xxxx xxxx"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MatCardExpiry label="Card Expiry" {...{ formik }} />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <MatInput
                {...{ formik }}
                name="cardCvc"
                label="Card Cvc"
                placeholder="xxxx"
              />
            </Grid> */}
          </Grid>
        </Box>
        <Divider style={{ width: "100%" }} />
        <Box p={2} pt={1} pb={1}>
          <Grid container>
            <Grid item xs={5}>
              <Button
                disableElevation
                size="medium"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
                className={styles.submitButton}
                type="submit"
                endIcon={
                  isSubmitting && (
                    <CircularProgress
                      style={{
                        marginLeft: 8,
                        color: config.COLORS.contrastText,
                      }}
                      size={12}
                    />
                  )
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    border: "none",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 18,
  },

  submitButton: {
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,
    fontFamily: "Poppins",

    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      //   color: config.COLORS.secondaryContrastText,
    },
  },
}));

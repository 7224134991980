import React, { useEffect, useState } from "react";
import Header from "./Header";
import { toast } from "react-toastify";
import Banner from "src/components/Banner";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Success from "./Success";
import Error from "./Error";
import { useHistory } from "react-router";
import axios from "axios";
import config from "src/config";

export default function AcceptFriendRequest() {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [friend, setFriend] = useState(null);
  const [sentBy, setSentBy] = useState("");
  const [myGroup, setMyGroup] = useState([]);

  const toggleSuccess = (success) => {
    if (success) {
      setError(false);
      setSuccess(true);
    } else {
      setError(true);
      setSuccess(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    let search = history.location.search;
    let params = new URLSearchParams(search);
    let teamId = params.get("teamId");
    let fromNumber = params.get("from");
    let toNumber = params.get("to");

    if (!fromNumber || !toNumber || !teamId) {
      toggleSuccess(false);
      return;
    }

    setFriend({
      teamId,
      fromPhoneNumber: fromNumber,
      toPhoneNumber: toNumber,
    });
    getSubscriberByPhone({ fromNumber, toNumber, teamId });
  }, [history]);

  const getSubscriberByPhone = async ({ fromNumber, toNumber, teamId }) => {
    let subsendpoint = `${config.ADMIN_URI}/subscribers/detailsbynumber`;
    let mygroupendpoint = `${config.ADMIN_URI}/friends/mygroup`;

    try {
      // Calling necessary apis to
      // get fromSubs, toSubs and toSubs->myGroup data

      let fromResponse = await axios.post(subsendpoint, {
        phoneNumber: fromNumber,
        teamId: teamId,
      });

      let toResponse = await axios.post(subsendpoint, {
        phoneNumber: toNumber,
        teamId: teamId,
      });

      let myGroupResponse = await axios.post(mygroupendpoint, {
        teamId,
        subscriberId: toResponse?.data?.subscriber?.id,
      });

      let from = fromResponse.data.subscriber;
      let myGroup = myGroupResponse.data;
      setMyGroup(myGroup);
      setSentBy(from.fullName);
      toggleSuccess(true);
    } catch (error) {
      toggleSuccess(false);
    }
  };

  // const responseFriendRequest = async ({ teamId, from, to }) => {
  //   let endpoint = `${config.ADMIN_URI}/friends/response`;
  //   try {
  //     let response = await axios.post(endpoint, {
  //       teamId: teamId,
  //       toPhoneNumber: to,
  //       fromPhoneNumber: from,
  //     });

  //     if (response.status === 200) {
  //       setError(false);
  //       setSuccess(true);
  //       setTimeout(() => {
  //         history.push("/");
  //       }, 3000);
  //     }
  //   } catch (error) {
  //     setError(true);
  //     setSuccess(false);
  //   }
  //   setLoading(false);
  // };

  return (
    <Box>
      <Header />
      <Banner />

      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={5} lg={3}>
            <Box mt={4}>
              {loading && (
                <Box>
                  <Box textAlign="center">
                    <CircularProgress
                      size={24}
                      style={{ color: config.COLORS.primary }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography
                      variant="h6"
                      className={classes.loadingTitle}
                      color="textPrimary"
                    >
                      Please wait...
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* <Success {...{ sentBy, friend }} /> */}
              {!loading && error && <Error />}
              {!loading && success && sentBy.length > 0 && (
                <Success {...{ sentBy, friend, myGroup }} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  loadingTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 18,
  },
}));

import magic from "./magic";
import oregon from "./oregon";
import { florida_dev, florida_prod } from "./florida";
import { oregon_dev, oregon_prod } from "./oregon";
import { iowa_dev, iowa_prod } from "./iowa";
import { jaguars_dev, jaguars_prod } from "./jaguars";
import { ohiostate_dev, ohiostate_prod } from "./ohiostate";
import { sandbox } from "./sandbox";
import { louisville } from "./louisville";
import { jazz } from "./jazz";
import { kings } from "./kings";
import { stockton } from "./stockton";
import { bucks } from "./bucks";
import { ohiostate2 } from "./ohiostate2";
import { wizards } from "./wizards";
import { dbacks } from "./dbacks";
import { hawks } from "./hawks";

let allConfig = {
  magic,
  florida_dev,
  florida_prod,
  oregon_dev,
  oregon_prod,
  iowa_dev,
  iowa_prod,
  jaguars_dev,
  jaguars_prod,
  ohiostate_dev,
  ohiostate_prod,
  sandbox,
  louisville,
  jazz,
  kings,
  stockton,
  bucks,
  ohiostate2,
  wizards,
  dbacks,
  hawks,
};
let config = allConfig[process.env.REACT_APP_TYPE];

export default config;

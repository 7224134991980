import React from "react";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  colors,
  Divider,
} from "@material-ui/core";
import config from "src/config";
import { ShoppingBag as CartIcon } from "react-feather";
import Manifest from "./Manifest";
import { v4 as uuid } from "uuid";

export default function ManifestCart({ manifests }) {
  const classes = useStyles();

  return (
    <Paper variant="outlined">
      <Box
        p={1}
        pl={2}
        pr={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <CartIcon size={24} />
          <Typography
            variant="h5"
            className={classes.cardTitle}
            color="textPrimary"
          >
            Register Pass
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={4}>
        {/* all products visible in the cart */}
        {manifests.length > 0 &&
          manifests.map((manifest) => (
            <Manifest key={uuid()} {...{ manifest }} />
          ))}
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardTitle: {
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: 24,
    marginLeft: 15,

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  margin: {
    margin: "auto",
  },
  mainText: {
    boxShadow: "none",
    fontWeight: 700,
    padding: "10px 0",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  subText: {
    boxShadow: "none",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  alignText: {
    textAlign: "center",
  },
  btnGroup: {
    width: "100%",
    justifyContent: "center",
    margin: "10px 0",
  },
  btnToggle: {
    border: "2px solid #c4c4c4 !important",
    margin: "0 20px !important",
    borderRadius: "50% !important",

    [theme.breakpoints.down("md")]: {
      margin: "0 8px !important",
    },
  },
  countText: {
    border: "2px solid #c4c4c4 !important",
    fontSize: 18,
    padding: "2px 14px",
    color: "#000000 !important",
  },
  imgWidth: {
    width: 140,
    [theme.breakpoints.down("md")]: {
      width: 130,
    },
  },
  footer: {
    borderTop: "1px solid #a9a9a9",
  },
  totalCost: {
    fontWeight: "700",
    fontSize: 24,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },

  tableHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    fontSmooth: "2em",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  tableBodyText: {
    fontSize: "16px",
    fontWeight: "500",
    color: colors.grey["600"],

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  tableFooterText: {
    fontSize: "20px",
    fontWeight: "600",
    fontSmooth: "2em",
  },
  table: {
    "& .MuiTableCell-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "5px",
      },
    },
  },

  scheduleButton: {
    color: config.COLORS.typography,
    cursor: "pointer",
    textDecoration: "underline",
    padding: 0,
    fontSize: 18,
    fontFamily: "Raleway",
    backgroundColor: "transparent",
    border: "none",
    fontWeight: 500,

    "&:focus": {
      outline: "none",
    },
  },
}));

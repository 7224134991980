import React from "react";
import BannerImage from "src/images/stockton/stockton_banner_nov_21.jpg";
import LogoImage from "src/images/stockton/stockton_logo_transparent.png";

const PAGE_TITLES = {
  home: {
    heading: "",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "",
    subtitle: "",
  },
};

const SITE_SEO = {
  title: "STOCKTON KINGS",
  description: "Stockton Kings Pass",
  favicon: LogoImage,
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "Verify your email address",
    description:
      "Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "Ticket Options",
  membershipSection: {
    title: "Manage Your Stockton Kings Pass",
    question: "Returning Stockton Kings Pass buyer?",
    description: "Click the button below to look up and manage your existing Stockton Kings Pass.",
  },
  announcementBarText: "",

  registerTerms: {
    enabled: true,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://uploads-ssl.webflow.com/5ed7df6fe67e862e0a62f9f2/616882d2b691e09f75348396_Terms%20%26%20Conditions.pdf"
          className="register_terms_link"
        >
          Terms and Conditions
        </a>
        ,{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.golden1center.com/assets/doc/2021-2022NBAWebsiteSupplementalTicketBackLanguagev3_FINAL-5706eea6a8.pdf"
          className="register_terms_link"
        >
          Sacramento Kings ticket terms
        </a>{" "}
        and the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://stockton.gleague.nba.com/healthandsafety/"
          className="register_terms_link"
        >
          venue's health and safety guidelines
        </a>{" "}
        for event attendance.
        <br />
        <br />
        To attend events at the Stockton Arena you must provide proof of full vaccination, or a
        negative COVID-19 test - PCR within 2 days or Antigen within 1 day for entry into Stockton
        Arena for a game. In compliance with state guidelines, all fans ages 2+ must wear a mask
        when not actively eating or drinking. This event and any scheduled event dates are subject
        to existing State of California, County and City Health Mandates regarding venue capacity
        and safety protocols for implementation of safety in response to the COVID-19 pandemic. The
        Stockton Arena reserves the right to cancel or postpone any event in conformance with any
        health mandate in effect on the date of an event or for operational needs to safely comply
        with public health orders.
      </p>
    ),
  },
};

const UI_DIMENSION = {
  eventImageWidth: 80,
};

export const stockton = {
  SITE_TITLE: "STOCKTON KINGS",
  TEAM_NAME: "STOCKTON KINGS",
  TEAM_ID: 202,
  PRODUCT_NAME: "KINGS PASS",
  SITE_LOGO_URI: LogoImage,
  IS_DEVELOPMENT: false,
  IS_RECURRING: true,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://passbackend.seasonshare.com/api",
  SS_FE_BASE_URI: "https://stockton.seasonshare.com",
  ADMIN_BASE_URI: "https://subsjanitor.seasonshare.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#5b2c81",
    secondary: "#5d676f",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0,
    membershipOverlay: 0,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Avatar,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import config from "src/config";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import EmptyIcon from "src/images/empty.png";
import { useLocalStorage } from "src/components/localStorage";

export default function SentRequests({ handleChange }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const reactLocalStorage = useLocalStorage();
  const [loading, setLoading] = useState(false);
  const [pendingFriends, setPendingFriends] = useState([]);
  const [responding, setResponding] = useState(null);
  const [subscriber, setSubscriber] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_SUBSCRIBER`)
  );

  useEffect(() => {
    if (subscriber) {
      getPendingFriends();
    }
  }, [subscriber]);

  const getPendingFriends = async () => {
    setLoading(true);
    let endpoint = `${config.ADMIN_URI}/friends/sentrequests`;
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        phoneNumber: subscriber.phoneNumber,
      });

      if (response.status === 200) {
        let pendingFriends = response.data;
        setPendingFriends(pendingFriends);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setLoading(false);
  };

  const cancelFriendRequest = async (friend) => {
    let endpoint = `${config.ADMIN_URI}/friends/cancel`;
    setResponding(friend);
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        toPhoneNumber: friend.phoneNumber,
        fromPhoneNumber: subscriber.phoneNumber,
        //fromPhoneNumber: "+11122545654",
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        getPendingFriends();
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setResponding(null);
  };

  return (
    <Box>
      <ToastContainer />

      <Grid container justify="center">
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            {loading ? (
              <CircularProgress
                size={16}
                style={{ color: config.COLORS.primary }}
              />
            ) : (
              <>
                {pendingFriends.length === 0 && (
                  <>
                    {/* <Grid item xs={12}>
                      <Box textAlign="center">
                        <img src={EmptyIcon} alt="" />
                      </Box>
                    </Grid> */}
                    <Grid item xs={10}>
                      <Box textAlign="center">
                        <Typography
                          variant="h5"
                          color="textPrimary"
                          className={classes.title}
                        >
                          You haven’t sent any requests
                        </Typography>

                        <Typography variant="subtitle1" color="textSecondary">
                          <span
                            className={classes.clickableText}
                            onClick={(e) => handleChange(e, 3)}
                          >
                            Click here
                          </span>{" "}
                          to invite a friend to sit next to you
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
                {pendingFriends.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Box>
                        <Box textAlign="center">
                          <Typography
                            variant="h5"
                            color="textPrimary"
                            className={classes.title}
                          >
                            Sent Friend Requests
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            Here are your current friend requests
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    {pendingFriends.map((pendingFriend) => (
                      <Grid key={uuid()} item xs={12}>
                        <Paper variant="outlined">
                          <Box p={sm ? 2 : 3} className={classes.profileItem}>
                            <Box className={classes.profileImage}>
                              <Avatar />
                            </Box>

                            <Box className={classes.profileContent}>
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                className={classes.profileTitle}
                              >
                                {pendingFriend.fullName}
                              </Typography>

                              {pendingFriend.emailAddress && (
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  className={classes.profileSubtitle}
                                >
                                  {pendingFriend.emailAddress}
                                </Typography>
                              )}

                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                className={classes.profileSubtitle}
                              >
                                {pendingFriend.phoneNumber}
                              </Typography>
                            </Box>
                            <Box className={classes.profileAction}>
                              <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={classes.actionButton}
                                onClick={() =>
                                  cancelFriendRequest(pendingFriend)
                                }
                              >
                                Cancel
                                {responding &&
                                  responding["phoneNumber"] ===
                                    pendingFriend["phoneNumber"] && (
                                    <CircularProgress
                                      size={12}
                                      style={{
                                        marginLeft: 3,
                                        color: "white",
                                      }}
                                    />
                                  )}
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Raleway",
    fontWeight: 800,
  },
  profileItem: {
    display: "grid",
    gridTemplateColumns: "60px 1fr 1fr",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "50px 40% 1fr",
    },
  },
  profileTitle: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: "Poppins",

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  actionButton: {
    backgroundColor: config.COLORS.primary,
    fontSize: 14,
    // padding: "8px 15px",
    borderRadius: 30,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },

    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  },

  profileAction: {
    display: "flex",
    justifyContent: "flex-end",
  },
  clickableText: {
    color: config.COLORS.primary,
    textDecoration: "underline",
    fontWeight: 500,
    cursor: "pointer",
  },
}));

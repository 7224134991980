import { combineReducers } from "redux";
import purchase from "./purchase/purchaseReducer";
import friendRequest from "./friendRequest/friendRequestReducer";
import team from "./team/teamReducer";

const rootReducer = combineReducers({
  purchase,
  friendRequest,
  team,
});

export default rootReducer;

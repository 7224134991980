import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  colors,
  CircularProgress,
} from "@material-ui/core";
import {
  DateRangeRounded,
  HighlightOff,
  DoneAllRounded,
  CloseRounded,
} from "@material-ui/icons";
import config from "src/config";
import Popup from "src/components/Popup";
import { useQuery } from "src/hooks/url_query_params";
import moment from "moment";
import ErrorPopup from "src/components/ErrorPopup";
import axios from "axios";
import { toast } from "react-toastify";

export default function AttendancePopup({
  events,
  subscribers,
  getEventsInPlan,
}) {
  const classes = useStyles();
  const query = useQuery();
  const [open, setOpen] = useState(false);
  const handlePopup = (open) => {
    if (!open) query.set("attendance", "closed");
    setOpen(open);
  };
  const [event, setEvent] = useState(null);
  const [subscriber, setSubscriber] = useState(null);
  const [loading, setLoading] = useState(false);

  const calculatePast = (event) => {
    if (!!event) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const today = date.getTime();
      const eventDate = new Date(moment(event.date)).getTime();
      const diff = (today - eventDate) / 1000 / 60 / 60 / 24;
      return diff > 0 ? true : false;
    } else {
      return false;
    }
  };

  const handleInit = () => {
    // Check for necessary params in url
    const subscriberId = query.get("subscriberId");
    const eventCode = query.get("attendance"); // eventId = attendance
    const planCode = query.get("planCode");
    const event = events[planCode]?.find((e) => e.eventCode == eventCode); // e = event
    const subscriber = subscribers.find((s) => s.id == subscriberId); // s = subscriber
    // eventCode !== "closed" && !event?.enableReply && setError(true);
    const isPast = calculatePast(event);

    setSubscriber(subscriber);
    setEvent(event);

    !!event && !!event.enableReply && handlePopup(true);
    !!event && !event.enableReply && handlePopup(false);
    isPast && handlePopup(false);
  };

  useEffect(handleInit, [query.params]);

  const handleReply = async (attending) => {
    setLoading(true);
    let endpoint = `${config.ADMIN_URI}/assignments/reply`;
    let teamId = config.TEAM_ID;
    let isAttending = attending ? 1 : 0;
    let eventCode = query.get("attendance");
    let phoneNumber = subscriber.phoneNumber;

    try {
      let response = await axios.post(endpoint, {
        teamId,
        isAttending,
        eventCode,
        phoneNumber,
      });

      if (response.status === 200) {
        getEventsInPlan();
        query.set("attendance", "closed");
        toast.success("Successfully replied");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }

    setLoading(false);
  };

  return (
    <>
      {event && !!event.enableReply && (
        <Popup
          {...{ open }}
          PaperProps={{
            width: 350,
          }}
          closeButtonProps={{
            canCloseByUser: true,
            backgroundColor: config.COLORS.primary,
            closeIcon: (
              <HighlightOff
                className={classes.closeButton}
                onClick={() => handlePopup(false)}
              />
            ),
          }}
        >
          <Box p={3}>
            <Box>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.dialogTitle}
              >
                Manage Your Attendance ?
              </Typography>
            </Box>
            <Box mt={2} className={classes.plan} p={2}>
              {event.logoUrl ? (
                <Box className={classes.eventImage}>
                  <img src={event.logoUrl} alt="" />
                </Box>
              ) : (
                <Box>
                  <DateRangeRounded
                    style={{
                      color: colors.grey["600"],
                      fontSize: 32,
                    }}
                  />
                </Box>
              )}
              <Box>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.planTitle}
                >
                  {event.description}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.planMeta}
                >
                  {moment(event.date).format("MMM Do")}{" "}
                  {event.time != "TBA" &&
                    moment(event.time, "HH:mm").format("hh:mm a")}
                  {event.time == "TBA" && "TBA"}
                </Typography>

                {[0, 1].includes(event.attended) && (
                  <Typography
                    className={classes.planMeta}
                    variant="subtitle1"
                    color="textSecondary"
                  >
                    ATTENDING :{" "}
                    <span
                      className={
                        classes[`attn_${event.attended == 1 ? "yes" : "no"}`]
                      }
                    >
                      {event.attended == 1 ? "YES" : "NO"}
                    </span>
                  </Typography>
                )}
              </Box>
            </Box>
            {event.attended === null && (
              <>
                <Box mt={4}>
                  <Button
                    fullWidth
                    disableElevation
                    variant="contained"
                    size="medium"
                    className={classes.yesButton}
                    disabled={loading}
                    onClick={() => handleReply(true)}
                    startIcon={<DoneAllRounded />}
                  >
                    YES! I WILL ATTEND
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button
                    disableElevation
                    variant="contained"
                    size="medium"
                    fullWidth
                    className={classes.noButton}
                    disabled={loading}
                    onClick={() => handleReply(false)}
                    startIcon={<CloseRounded />}
                  >
                    NO. I CAN’T MAKE IT
                  </Button>
                </Box>
              </>
            )}
            {event.attended === 0 && (
              <Box mt={4}>
                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  size="medium"
                  className={classes.yesButton}
                  disabled={loading}
                  onClick={() => handleReply(true)}
                  startIcon={<DoneAllRounded />}
                >
                  YES! I WILL ATTEND
                </Button>
              </Box>
            )}

            {event.attended === 1 && (
              <Box mt={2}>
                <Button
                  disableElevation
                  variant="contained"
                  size="medium"
                  fullWidth
                  className={classes.noButton}
                  disabled={loading}
                  onClick={() => handleReply(false)}
                  startIcon={<CloseRounded />}
                >
                  NO. I CAN’T MAKE IT
                </Button>
              </Box>
            )}
          </Box>

          {loading && (
            <Box className={classes.lodingOverlay}>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{ color: config.COLORS.primary }}
                />
              )}
            </Box>
          )}
        </Popup>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  attn_yes: {
    color: colors.green[600],
  },
  attn_no: {
    color: colors.red[600],
  },
  lodingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "15",
    backgroundColor: "rgba(255,255,255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 18,
  },
  plan: {
    transition: "all 0.3s ease",
    marginBottom: 10,
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    display: "grid",
    gridTemplateColumns: "50px 1fr 30px",
    alignItems: "center",
    transform: "translateY(-5px)",
    boxShadow: "0 8px 16px rgb(0 0 0 / 10%)",
  },
  planTitle: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 16,
  },
  planDesc: {
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: 1.3,
    fontSize: 14,
    color: config.COLORS.primary,
  },
  planMeta: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.3,
    marginTop: 2,
  },
  statusText: {
    color: colors.green["600"],
  },

  eventImage: {
    "& img": {
      width: 40,
    },
  },

  closeButton: {
    color: "white",
  },
  errorIcon: {
    color: theme.palette.error.light,
    fontSize: 48,
  },
  yesButton: {
    backgroundColor: config.COLORS.primary,
    fontFamily: "Poppins",
    fontWeight: 600,
    color: config.COLORS.contrastText,
    position: "relative",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },

    "& .MuiSvgIcon-root": {
      position: "absolute",
      left: 15,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  noButton: {
    backgroundColor: colors.grey[400],
    fontFamily: "Poppins",
    fontWeight: 600,
    color: colors.grey[800],
    position: "relative",
    "&:hover": {
      backgroundColor: colors.grey[300],
      color: colors.grey[700],
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      left: 15,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
}));

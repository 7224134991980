import React, { useState, useEffect } from "react";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Box,
  Container,
  Grid,
} from "@material-ui/core";
import {
  AddCircleOutline,
  ListAltRounded,
  GroupOutlined,
  SendOutlined,
} from "@material-ui/icons";
import SendRequest from "./SendRequest";
import PendingRequests from "./PendingRequests";
import MyGroup from "./MyGroup";
import SentRequests from "./SentRequests";
import { useLocalStorage } from "src/components/localStorage";
import { useHistory } from "react-router";
import config from "src/config";
import { toast } from "react-toastify";
import axios from "axios";
import { settingsApi } from "src/api/settingsApi";
import SitWithFriendDisabledNotice from "src/components/SitWithFriendDisabledNotice";

function TabPanel(props) {
  const { children, value, index, ...others } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...others}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function FriendsView() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [canSitWithFriend, setCanSitWithFriend] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getTeamConfig();
  }, []);

  const getTeamConfig = async () => {
    settingsApi
      .getTeamSettings()
      .then(async (res) => {
        const swaf =
          res?.data?.team_configuration?.canSitWithFriend == "1" ? true : false;
        console.log("Swaf", swaf);
        setCanSitWithFriend(swaf);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data ||
            "Error enabling Sit With Friends. Contact Support."
        );
      });
  };

  return (
    <Box className={classes.root} p={2} mt={4} mb={8}>
      {canSitWithFriend == true && (
        <AppBar
          position="static"
          style={{ backgroundColor: "transparent" }}
          elevation={0}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="frinds portal tabs"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            className={classes.tabButtonWrapper}
          >
            <Tab
              label="My Group"
              disableRipple
              icon={<GroupOutlined />}
              className={classes.tabButton}
              {...a11yProps(0)}
            />
            <Tab
              hidden={canSitWithFriend}
              label="Received Requests"
              disableRipple
              icon={<ListAltRounded />}
              className={classes.tabButton}
              {...a11yProps(1)}
            />
            <Tab
              hidden={!canSitWithFriend}
              label="Sent Requests"
              disableRipple
              icon={<SendOutlined />}
              className={classes.tabButton}
              {...a11yProps(2)}
            />

            <Tab
              hidden={!canSitWithFriend}
              label="New Request"
              disableRipple
              icon={<AddCircleOutline />}
              className={classes.tabButton}
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>
      )}

      <TabPanel value={value} index={0}>
        <Box p={2} pt={4} pb={8}>
          {canSitWithFriend == false && <SitWithFriendDisabledNotice />}
          <MyGroup {...{ handleChange, canSitWithFriend }} />
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box p={2} pt={4} pb={8}>
          <PendingRequests {...{ handleChange }} />
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Box p={2} pt={4} pb={8}>
          <SentRequests {...{ handleChange }} />
        </Box>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Box p={2} pt={4} pb={8}>
          <SendRequest />
        </Box>
      </TabPanel>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },

  tabButtonWrapper: {
    "& .MuiTabs-flexContainer": {
      // justifyContent: "space-between",
    },
  },

  tabButton: {
    minWidth: "fit-content",
    padding: "15px 30px",

    [theme.breakpoints.down("sm")]: {
      padding: "5px 8px",
    },

    "&.Mui-selected": {
      backgroundColor: config.COLORS.primary,
      color: "white",
      borderRadius: 5,
    },

    "& .MuiTab-wrapper": {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Poppins",
      maxWidth: 70,
      lineHeight: 1,

      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },

      "& svg": {
        // marginBottom: "10px !important",
      },
    },

    "& .MuiSvgIcon-root": {
      fontSize: 32,
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
  },
}));

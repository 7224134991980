import React from "react";
import Layout from "src/pages/MembershipPortal/Layout";
import PassOverView from "src/views/PassOverview";

export default function () {
  return (
    <Layout>
      <PassOverView />
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import config from "src/config";
import {
  Button,
  makeStyles,
  Box,
  CircularProgress,
  IconButton,
  Container,
  useTheme,
  useMediaQuery,
  Hidden,
} from "@material-ui/core";
import { useLocalStorage } from "src/components/localStorage";
import { useHistory } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { MenuRounded } from "@material-ui/icons";
import { loginRedirect } from "src/routes";

export default function Header({ sidebarEnabled, toggleSidebar }) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const reactLocalStorage = useLocalStorage();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [subscriber, setSubscriber] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_SUBSCRIBER`)
  );
  const [token, setToken] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_REFRESH_TOKEN`)
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // useEffect(() => {
  //   toast.error("error error error");
  // }, []);

  // Parsing url data and running the
  // logintoSystem function
  useEffect(() => {
    // Checking for login & register route
    let isLoginRoute = history.location.pathname.includes("tmcallback/login");
    let isRegisterRoute = history.location.pathname.includes("register-pass");
    let isPurchaseRoute =
      history.location.pathname.includes("purchase-tickets");

    // if login route runing the logintoSystem fn
    isLoginRoute && logintoSystem();

    // if register route buttons will be disabled
    isRegisterRoute && setButtonDisabled(true);

    // if purchase route buttons will be disabled
    isPurchaseRoute && setButtonDisabled(true);

    // if other route, buttons will be visible
    !isRegisterRoute && !isPurchaseRoute && setButtonDisabled(false);
  }, [history]);

  const logintoSystem = async () => {
    setLoading(true);
    let params = new URLSearchParams(history.location.search);
    let authCode = params.get("code");
    let teamId = config.TEAM_ID;
    let isTest = config.IS_DEVELOPMENT;
    let redirectUri = `${config.SS_FE_BASE_URI}/tmcallback/login`;

    try {
      let response = await axios.post(`${config.ADMIN_URI}/pass/login`, {
        authCode,
        teamId,
        redirectUri,
        isTest,
      });

      if (response.status === 200) {
        let subscriber = response.data.subscriber;
        let token = response.data.refreshToken;
        reactLocalStorage.setItem(`${config.TEAM_ID}_MA_REFRESH_TOKEN`, token);
        reactLocalStorage.setItem(
          `${config.TEAM_ID}_MA_SUBSCRIBER`,
          subscriber
        );
        setSubscriber(subscriber);
        setToken(token);
        history.push(loginRedirect);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
      setLoading(false);
      history.push("/");
    }
    setLoading(false);
  };

  const handleLogOut = () => {
    reactLocalStorage.setItem(`${config.TEAM_ID}_MA_SUBSCRIBER`, null);
    reactLocalStorage.setItem(`${config.TEAM_ID}_MA_REFRESH_TOKEN`, null);
    setSubscriber(null);
    setToken(null);
    history.replace("/");
  };

  const handleLogin = async () => {
    setLoading(true);
    let teamAuthUrl = `${config.ADMIN_URI}/teamAuth/url/${config.TEAM_ID}`;
    let redirectUri = `${config.SS_FE_BASE_URI}/tmcallback/login`;

    try {
      // Getting ticket master url from admin backend
      let teamAuthResponse = await axios.get(teamAuthUrl);
      let authUrl = teamAuthResponse.data.url;

      // Replacing ticket master REDIRECT URL according to our need
      let tmUrl = authUrl
        .replace("REDIRECT_URL", redirectUri)
        .replace("STATE", `LOGIN-${config.TEAM_ID}`);

      // Final redirection to the ticketmaster
      window.location.href = `${tmUrl}`;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Box pl={xs ? 2 : 4} pr={xs ? 2 : 4} p={1}>
        <Box className={classes.header}>
          <Box>
            {sidebarEnabled && (
              <Hidden mdUp>
                <IconButton onClick={toggleSidebar}>
                  <MenuRounded />
                </IconButton>
              </Hidden>
            )}
          </Box>
          <Box className={classes.siteLogo}>
            <img src={config.SITE_LOGO_URI} alt="" />
            {/* <a href="/"></a> */}
          </Box>

          {!buttonDisabled && (
            <Box className={classes.buttonWrapper}>
              {subscriber && token && (
                <Button
                  className={classes.button}
                  size="large"
                  disableElevation
                  variant="contained"
                  onClick={handleLogOut}
                  disabled={false}
                >
                  LOG OUT
                </Button>
              )}

              {(!subscriber || !token) && (
                <Button
                  className={classes.button}
                  size="large"
                  disableElevation
                  variant="contained"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  LOG IN
                  {loading && (
                    <CircularProgress
                      size={13}
                      style={{ marginLeft: 8, marginTop: -2, color: "white" }}
                    />
                  )}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    width: "100%",
  },

  siteLogo: {
    "& img": {
      width: 110,
      height: "100%",
    },
    display: "flex",
    justifyContent: "center",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },

  button: {
    backgroundColor: config.COLORS.primary,
    color: "white",
    fontSize: "18px",
    padding: "10px 40px",
    borderRadius: 35,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      color: config.COLORS.contrastText,
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "8px 16px",
    },
  },
}));

import React, { useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Button,
  colors,
  CircularProgress,
} from "@material-ui/core";
import config from "src/config";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AcceptRequest from "src/components/AcceptRequestPopup";

export default function Success({ sentBy, friend, myGroup }) {
  const classes = useStyles();
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [responding, setResponding] = useState(false);
  const history = useHistory();

  const responseFriendRequest = async () => {
    let endpoint = `${config.ADMIN_URI}/friends/response`;
    setAcceptLoading(true);
    setResponding(true);
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        toPhoneNumber: friend.toPhoneNumber,
        fromPhoneNumber: friend.fromPhoneNumber,
      });

      if (response.status === 200) {
        toast.success(response.data.message, {
          onClose: () => {
            history.push("/");
          },
        });
      }
    } catch (error) {
      setResponding(false);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setAcceptLoading(false);
  };

  const cancelFriendRequest = async () => {
    let endpoint = `${config.ADMIN_URI}/friends/reject`;
    setDeclineLoading(true);
    setResponding(true);
    try {
      let response = await axios.post(endpoint, {
        teamId: config.TEAM_ID,
        toPhoneNumber: friend.toPhoneNumber,
        fromPhoneNumber: friend.fromPhoneNumber,
      });

      if (response.status === 200) {
        toast.success(response.data.message, {
          onClose: () => {
            history.push("/");
          },
        });
      }
    } catch (error) {
      setResponding(false);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setDeclineLoading(false);
  };

  return (
    <Box>
      {/* <Box textAlign="center">
        <CheckCircleOutline className={classes.icon} />
      </Box> */}
      <Box textAlign="center">
        <Typography variant="h3" className={classes.title} color="textPrimary">
          Friend Request
        </Typography>
      </Box>
      {/* <Box textAlign="center" mt={1}>
        <Typography
          className={classes.subtitle}
          variant="h5"
          color="textSecondary"
        >
          You are good to go.
        </Typography>
      </Box> */}
      <Box textAlign="center" mt={2}>
        <Typography
          className={classes.caption}
          variant="subtitle2"
          color="textSecondary"
        >
          <span className={classes.personName}>{sentBy}</span> has invited you
          to join their ticket group. Please accept or decline their
          request.
        </Typography>
      </Box>

      <Box textAlign="center" mt={2}>
        {myGroup.length > 1 ? (
          <AcceptRequest {...{ friend }} />
        ) : (
          <Button
            disabled={responding}
            variant="contained"
            color="primary"
            disableElevation
            className={classes.actionButton}
            onClick={() => responseFriendRequest()}
          >
            Accept
            {acceptLoading && (
              <CircularProgress
                size={12}
                style={{
                  marginLeft: 5,
                  marginTop: -2,
                  color: "white",
                }}
              />
            )}
          </Button>
        )}

        <Button
          disabled={responding}
          variant="contained"
          color="primary"
          disableElevation
          className={clsx(classes.actionButton, classes.declineButton)}
          onClick={cancelFriendRequest}
        >
          Decline
          {declineLoading && (
            <CircularProgress
              size={12}
              style={{
                marginLeft: 5,
                marginTop: -2,
                color: "white",
              }}
            />
          )}
        </Button>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 32,
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
  },
  caption: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
  },
  link: {
    color: config.COLORS.primary,
    textDecoration: "underline",
  },
  icon: {
    color: "#81c784",
    fontSize: 42,
  },

  actionButton: {
    backgroundColor: config.COLORS.primary,
    fontSize: 14,
    // padding: "8px 15px",
    borderRadius: 30,
    marginRight: 10,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },

    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  },

  declineButton: {
    backgroundColor: colors.grey["500"],
  },

  personName: {
    color: config.COLORS.primary,
    fontWeight: 500,
  },
}));

import React from "react";
import BannerImage from "src/images/ohiostate/banner2.png";

const PAGE_TITLES = {
  home: {
    heading: "OHIO STATE BUCKEYES FLEX PASS",
    subtitle: "Register your pass, make changes and send friend requests.",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "MEMBERSHIP PORTAL",
    subtitle: "Manage your pass settings, make changes and send friend requests.",
  },
  registerTerms: {
    enabled: false,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program Terms and Conditions.
      </p>
    ),
  },
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "Verify your email address",
    description:
      "Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "Ticket Options",
  membershipSection: {
    title: "MANAGE MEMBERSHIP",
    question: "Returning ticket holder?",
    description: "Click this button to lookup and manage your existing membership.",
  },
  announcementBarText: "",
};

const UI_DIMENSION = {
  eventImageWidth: 50,
};

const SITE_SEO = {
  title: "OHIO STATE BUCKEYES",
  description: "Register your Ohio State Buckeyes Student Tickets",
  favicon: "https://ss-leagues.s3.amazonaws.com/NCAA/ohio-state-buckeyes.png",
};

export default {
  SITE_TITLE: "OHIO STATE BUCKEYES",
  TEAM_NAME: "Ohio State Buckeyes",
  TEAM_ID: 190,
  PRODUCT_NAME: "STUDENT PASS",
  SITE_LOGO_URI: "https://ss-leagues.s3.amazonaws.com/NCAA/ohio-state-buckeyes.png",
  IS_DEVELOPMENT: false,
  IS_RECURRING: false,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://ohio-state-subs.herokuapp.com/api",
  SS_FE_BASE_URI: "https://buckeyes.seasonshare.com",
  ADMIN_BASE_URI: "https://subs-janitor-prod.herokuapp.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#666666",
    secondary: "#bb0000",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0.6,
    membershipOverlay: 0.6,
  },
  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  HOME_HERO_TEXT: {
    heading: "OHIO STATE CYCLONE STUDENT PASS",
    subtitle: "Pick your favorite combination of matchups and seat location.",
  },
  CHECKOUT_HERO_TEXT: {
    heading: "OHIO STATE CYCLONE STUDENT PASS",
    subtitle:
      "Use all or some of your credit to reserve the games you want to attend. You cannot remove games once reserved, but you can always come back and add.",
  },
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

import React from "react";
import BannerImage from "src/images/oregon/banner1.png";

const PAGE_TITLES = {
  home: {
    heading: "Oregon Ducks Football Pass",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "MEMBERSHIP PORTAL",
    subtitle: "Manage your pass settings, make changes and send friend requests.",
  },
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "Verify your email address",
    description:
      "This subscription plan is exclusive for students, Please verify your eligibility by connecting the Ticketmaster account you used to purchase these tickets.",
  },
  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "Ticket Options",
  membershipSection: {
    title: "MANAGE MEMBERSHIP",
    question: "Returning ticket holder?",
    description: "Click this button to lookup and manage your existing membership.",
  },
  announcementBarText: "",
  registerTerms: {
    enabled: false,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program Terms and Conditions.
      </p>
    ),
  },
};

const SITE_SEO = {
  title: "OREGON DUCKS",
  description: "Register your Oregon Ducks Student Tickets",
  favicon: "https://s3.us-east-2.amazonaws.com/ss-leagues/NCAA/oregon-ducks.png",
};

const UI_DIMENSION = {
  eventImageWidth: 50,
};

export default {
  SITE_TITLE: "OREGON DUCKS",
  TEAM_NAME: "Oregon Ducks",
  TEAM_ID: 146,
  PRODUCT_NAME: "STUDENT PASS",
  SITE_LOGO_URI: "https://s3.us-east-2.amazonaws.com/ss-leagues/NCAA/oregon-ducks.png",
  IS_DEVELOPMENT: false,
  IS_RECURRING: false,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://ducks-subs-prod.herokuapp.com/api",
  SS_FE_BASE_URI: "https://duckspass.seasonshare.com",
  ADMIN_BASE_URI: "https://subs-janitor-prod.herokuapp.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#154733",
    secondary: "#FEE123",
    typography: "#130c0e",
    contrastText: "#FFFFFF",
    heroOverlay: 0.6,
    membershipOverlay: 0.6,
  },
  HOME_HERO_BG: "https://ss-leagues.s3.amazonaws.com/NCAA/banner1.png",
  MEMBERSHIP_BANNER: "https://ss-leagues.s3.amazonaws.com/NCAA/banner1.png",
  HOME_HERO_TEXT: {
    heading: "Oregon Football Fan Pass",
    subtitle: "",
  },
  CHECKOUT_HERO_TEXT: {
    heading: "Oregon Football Fan Pass",
    subtitle: "",
  },
  PAGE_TITLES,
  SITE_SEO,
  UI_LANGUAGE,
  UI_DIMENSION,
};

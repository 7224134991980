import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import config from "src/config";
import MatInput from "src/components/MatInput";
import { HighlightOffRounded } from "@material-ui/icons";
import AddressAutocomplete from "./AddressAutocomplete";
import OutsideClickHandler from "react-outside-click-handler";
import { ManualAddressNotice } from "./ManualAddressNotice";

export default function BillingDetails({ formik }) {
  const styles = useStyles();
  const [suggestionOpen, setSuggestionOpen] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const { setFieldValue, setFieldError, values } = formik;

  /** Manual Address input toggler Fn, will clear first whatever in address and zip.
   * @param enabled boolean
   */
  const handleManualSwitch = (enabled) => {
    clearStreet();
    setIsManual(enabled);
  };

  /** Google address suggestion bar toggler fn.
   * @param open boolean
   */
  const handleSuggestionOpen = (open) => setSuggestionOpen(open);

  /** Utility function,  Clear street and zip, triggered by the cross button available in street input  */
  const clearStreet = (event) => {
    event?.stopPropagation();
    setFieldValue("street", "");
    setFieldValue("zipCode", "");
  };

  /** Handler function to update the street and zip, triggered by autocomplete component
   * @param address street address
   * @param zip zip code associated with street
   */
  const handleStreetChange = (address, zip) => {
    let streetError =
      "Please select an address that is associated with Zip Code";
    setFieldValue("street", address);
    setFieldValue("zipCode", zip);
    !zip && setFieldError("street", streetError);
  };

  return (
    <>
      <Box mb={2} mt={3}>
        <Typography
          className={styles.sectionTitle}
          variant="h6"
          color="textPrimary"
        >
          Billing Details
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ManualAddressNotice {...{ handleManualSwitch, isManual }} />
        </Grid>
        <Grid item xs={12}>
          <Box className={styles.streeInputWrapper}>
            <MatInput
              required
              name="street"
              label="Street Address"
              autoComplete="new-password"
              onChange={(e) =>
                isManual && setFieldValue("street", e.target.value)
              }
              onFocus={() => !isManual && handleSuggestionOpen(true)}
              onClick={() => !isManual && handleSuggestionOpen(true)}
              endAdornment={
                values.street && (
                  <IconButton onClick={clearStreet}>
                    <HighlightOffRounded />
                  </IconButton>
                )
              }
              {...{ formik }}
            />
            {!isManual && (
              <OutsideClickHandler
                onOutsideClick={() => handleSuggestionOpen(false)}
              >
                {suggestionOpen && (
                  <AddressAutocomplete
                    {...{ handleStreetChange, handleSuggestionOpen }}
                  />
                )}
              </OutsideClickHandler>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <MatInput name="apartment" label="Apt/Unit" {...{ formik, config }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <MatInput
            required
            disabled={!isManual}
            name="zipCode"
            label="Zip Code"
            {...{ formik, config }}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() => ({
  sectionTitle: {
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  testButton: {
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
  streeInputWrapper: {
    position: "relative",
  },
}));

import Swal from "sweetalert2";
import config from "src/config";
import moment from "moment";
import Axios from "axios";

export default async function GameDetailModal(planName, planId) {
  //   const styles = useStyles();
  const data = {
    teamId: config.TEAM_ID,
    subscriptionPlanId: planId,
  };

  const schedule = await Axios.post(
    `${config.API_BASE_URI}/event/schedule`,
    data
  );
  const events = schedule.data["events"] || [];

  // consider moving this to a helper
  const getDate = (date) => {
    const formatted = moment(date).format("MM/DD/yyyy");
    console.log(formatted);
    if (formatted == "Invalid date") return "TBA";
    else return formatted;
  };

  let htmlValue = "";
  if (events.length !== 0) {
    events.forEach((element) => {
      let number = moment(element["time"], ["HH.mm"]).format("h:mm a");
      if (number == "Invalid date") number = "TBA";
      htmlValue =
        htmlValue +
        `
              <div class="game-detail-single">
                <div class="content-wrapper" >
                <img class="image" style='max-width : ${
                  config.UI_DIMENSION.eventImageWidth
                }px' src='${element["logoUrl"]}'/>
                <div class="name">

                ${element["description"]}
                </div>
                </div>
                <div class="date">
                  <p>
                  ${getDate(element["date"])}
                  </p>
                  <p>
                  ${number}
                  </p>
                </div>
              </div>
            `;
    });

    Swal.fire({
      title: planName,
      html: htmlValue,
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {});
  } else {
    htmlValue += `
    <div class="game-detail-empty"  >
    <h3>No schedule available at this time</h3>
    </div>
    `;

    Swal.fire({
      icon: "warning",
      // title: "No schedule available at this time",
      html: htmlValue,
    });
  }

  //   return <div></div>;
}

import React from "react";
import BannerImage from "src/images/magic/banner.png";
import LogoImage from "src/images/magic/logo.png";
const PAGE_TITLES = {
  home: {
    heading: "",
    subtitle: "",
  },

  purchaseTickets: {
    heading: "",
    subtitle: "",
  },
  registerPass: {
    heading: "",
    subtitle: "",
  },
  friendsPortal: {
    heading: "",
    subtitle: "",
  },
  membershipPortal: {
    heading: "",
    subtitle: "",
  },
};

const UI_LANGUAGE = {
  verifyEmail: {
    title: "REGISTER YOUR PASS",
    description:
      "Please register your pass by connecting the Ticketmaster account you used to purchase your pass.",
  },

  teamAccountButton: "Connect Team Account",
  passSelectionHeader: "PASS OPTIONS",
  membershipSection: {
    title: "MANAGE YOUR PASS",
    question: "Already a passholder?",
    description: "Log In to manage your existing pass.",
  },
  announcementBarText: "",
  registerTerms: {
    enabled: true,
    description: (
      <p className="register_terms_description">
        By purchasing you agree to receive SMS messages regarding ticket delivery along with the
        program{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.nba.com/magic/2021-22-fast-break-pass-terms-and-conditions"
          className="register_terms_link"
        >
          Terms and Conditions.
        </a>
      </p>
    ),
  },
};

const SITE_SEO = {
  title: "ORLANDO MAGIC",
  description: "Register your Florida Gators Student Tickets",
  favicon: "https://ss-leagues.s3.amazonaws.com/NBA/orl-magic-primary-icon-aug21.png",
};

const UI_DIMENSION = {
  eventImageWidth: 50,
};

const config = {
  SITE_TITLE: "ORLANDO MAGIC",
  TEAM_NAME: "Orlando Magic",
  TEAM_ID: 74,
  PRODUCT_NAME: "FAST BREAK PASS",
  SITE_LOGO_URI: "https://ss-leagues.s3.amazonaws.com/NBA/orl-magic-primary-icon-aug21.png",
  STRIPE_PK: "pk_test_0XtZvy0pSFVvgtKC60FTrGnB",
  STRIPE_SK:
    "sk_test_519iFYDJmjWHtzSSPcmNYBuK8BS20bihiEFB3EX3CmThDEJ5VpQXsMnRXOEe8OpgU7wO3Eb6QtLCtHqg6szd4m4VR00RDwt3jRc",

  // API_BASE_URI: "http://localhost:3001/api",
  IS_DEVELOPMENT: false,
  IS_RECURRING: true,
  INTERCOM_ENABLED: true,
  CRISP_ENABLED: false,
  EVENT_ATTENDANCE: true,
  FULL_WIDTH_BANNER: false,
  SIT_WITH_FRIENDS: true,
  V2_PASS_URL: "https://fanhub.seasonshare.com/magic/landing",

  //======================
  // API base URLs
  //======================
  API_BASE_URI: "https://magic-subs-prod.herokuapp.com/api",
  SS_FE_BASE_URI: "https://magic.seasonshare.com",
  ADMIN_BASE_URI: "https://subs-janitor-prod.herokuapp.com/api",
  ADMIN_URI: "https://subsjanitor.seasonshare.com",

  THEME_NAME: "primary",
  COLORS: {
    primary: "#000000",
    secondary: "#0077c0",
    typography: "#130c0e",
    heroOverlay: 0,
    contrastText: "#FFFFFF",
    membershipOverlay: 0,
  },

  HOME_HERO_BG: BannerImage,
  MEMBERSHIP_BANNER: BannerImage,
  PAGE_TITLES,
  UI_LANGUAGE,
  SITE_SEO,
  termsAndConditionUri: "https://www.nba.com/magic/2021-22-fast-break-pass-terms-and-conditions",
  UI_DIMENSION,
};

export default config;

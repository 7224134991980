import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import EmptyIcon from "src/images/empty.png";

export default function EmptyPass() {
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Paper elevation={1}>
        <Box
          minWidth={400}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          p={8}
          pt={6}
        >
          <img src={EmptyIcon} alt="" />
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{
              fontFamily: "Raleway",
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            Sorry, no passes are available at the moment
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}

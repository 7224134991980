import React, { useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Hidden,
} from "@material-ui/core";

import { HighlightOffOutlined } from "@material-ui/icons";
import config from "src/config";
import clsx from "clsx";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocalStorage } from "src/components/localStorage";
import { useHistory } from "react-router-dom";
import { useQuery } from "src/hooks/url_query_params";

export default function Sidebar({
  width,
  sidebarOpen,
  toggleSidebar,
  modules,
}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const query = useQuery();
  const reactLocalStorage = useLocalStorage();
  const [subscriber, setSubscriber] = useState(
    reactLocalStorage.getItem(`${config.TEAM_ID}_MA_SUBSCRIBER`)
  );
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        return sidebarOpen ? toggleSidebar() : "";
      }}
    >
      <Box
        className={clsx(classes.sidebar, sm && sidebarOpen ? "open" : "closed")}
        style={{ width }}
      >
        <Hidden mdUp>
          <Box className={classes.closeIcon} onClick={toggleSidebar}>
            <HighlightOffOutlined />
          </Box>
        </Hidden>
        <Box p={3} className={classes.userSection}>
          <Box>
            <Avatar style={{ width: 40, height: 40 }} />
          </Box>
          <Box>
            <Typography variant="h5" className={classes.userName}>
              {subscriber.fullName}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.userSubtitle}
            >
              {subscriber.emailAddress}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box p={2}>
          <List dense>
            {modules.map((module) => (
              <ListItem
                button={true}
                selected={history.location.pathname === module.url}
                className={classes.listItem}
                key={module.id}
                onClick={() => {
                  query.removeAll();
                  history.push(module.url);
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  {module.icon}
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary={module.name}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </OutsideClickHandler>
  );
}

const useStyles = makeStyles((theme) => ({
  sidebar: {
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "white",
    zIndex: 999,
    boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
    borderRight: "1px solid #E0E0E0",
    transition: "all 0.3s ease",

    [theme.breakpoints.down("sm")]: {
      "&.open": {
        transform: "translateX(0)",
      },
      "&.closed": {
        transform: "translateX(-100%)",
      },
    },
  },

  userSection: {
    display: "grid",
    gridTemplateColumns: "50px 1fr",
    alignItems: "center",
  },

  userName: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Poppins",
  },

  listIcon: {
    minWidth: 40,
  },
  listText: {
    "& .MuiListItemText-primary": {
      color: theme.palette.text.secondary,
      fontFamily: "Poppins",
      fontWeight: "600",
      // fontSize: 12,
    },
  },

  listItem: {
    "&.Mui-selected": {
      "& .MuiListItemText-primary": {
        color: config.COLORS.primary,
      },

      "& .MuiListItemIcon-root": {
        color: config.COLORS.primary,
      },
    },
  },
  closeIcon: {
    position: "absolute",
    top: 15,
    right: 15,
    zIndex: 99,

    "& .MuiSvgIcon-root": {
      color: theme.palette.text.secondary,
    },
  },
}));

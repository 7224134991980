import * as types from "./actionTypes";
import initialState from "../initialState";

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUBSCRIBER:
      return { ...state, subscriber: action.payload };
    case types.TEAM_ACCOUNT:
      return { ...state, teamAccount: action.payload };
    case types.SUBSCRIPTION_PLAN:
      return { ...state, subscriptionPlan: action.payload };
    default:
      return state;
  }
};

export default customerReducer;

import React, { useState } from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import config from "src/config";
import GenericImage from "src/images/cards/generic.png";
import AmexImage from "src/images/cards/amex.svg";
import VisaImage from "src/images/cards/visa.svg";
import MasterCardImage from "src/images/cards/mastercard.svg";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "src/utils/utils";

export default function CardInfo({ formik }) {
  const classes = useStyles();
  const { values, errors, handleChange } = formik;
  const [cardImage, setCardImage] = useState(GenericImage);

  const validateCardImage = (cardNumber) => {
    let isVisa = /^4/.test(cardNumber);
    let isMasterCard = /^5[1-5]/.test(cardNumber);
    let isAmex = /^3[47]/.test(cardNumber);
    let isEmpty = cardNumber.length === 0;
    let isUnknown = !isVisa && !isAmex && !isMasterCard;
    let isLengthError = cardNumber.length > 22;

    if (isMasterCard) setCardImage(MasterCardImage);
    if (isVisa) setCardImage(VisaImage);
    if (isAmex) setCardImage(AmexImage);
    if (isUnknown || isEmpty || isLengthError) setCardImage(GenericImage);
  };

  const handleCreditCardChange = (event) => {
    let value = event.target.value;
    validateCardImage(value);
    let formattedValue = formatCreditCardNumber(event.target.value);
    handleChange({ target: { name: "cardNumber", value: formattedValue } });
  };

  const handleCardCvc = (event) => {
    let value = formatCVC(event.target.value);
    handleChange({ target: { name: "cardCvc", value } });
  };

  const handleCardExpiry = (event) => {
    let value = formatExpirationDate(event.target.value);
    handleChange({ target: { name: "cardExpiry", value } });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12}>
        <TextField
          required
          fullWidth
          size="medium"
          className={classes.inputField}
          variant="outlined"
          name="cardNumber"
          placeholder="CARD NUMBER"
          value={values.cardNumber}
          error={errors.cardNumber && true}
          helperText={errors.cardNumber}
          InputProps={{
            endAdornment: (
              <img src={cardImage} className={classes.cardImage} alt="" />
            ),
          }}
          onChange={handleCreditCardChange}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="cardExpiry"
          placeholder="CARD EXPIRATION"
          value={values.cardExpiry}
          error={errors.cardExpiry && true}
          helperText={errors.cardExpiry}
          onChange={handleCardExpiry}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TextField
          required
          fullWidth
          className={classes.inputField}
          variant="outlined"
          size="medium"
          name="cardCvc"
          placeholder="CARD CVC"
          value={values.cardCvc}
          error={errors.cardCvc && true}
          helperText={errors.cardCvc}
          onChange={handleCardCvc}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: config.COLORS.primary,
    },

    "& fieldset": {
      borderRadius: 0,
    },
  },

  cardImage: {
    width: 50,
  },
}));

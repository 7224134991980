import React, { useEffect, createContext } from "react";
import { useLocalStorage } from "src/components/localStorage";
import axios from "axios";
import config from "src/config";
import axiosRetry from "axios-retry";

// Integration of axios-retry plugin
axiosRetry(axios, {
  retries: 4,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    if (error?.response?.status === 500 || error?.response?.status === 401) {
      return true;
    }
    return false;
  },
});

// Creating axios context and initial token set from local storage
let AxiosContext = createContext();
axios.defaults.headers.common["teamId"] = config.TEAM_ID;
axios.defaults.headers.common["team_id"] = config.TEAM_ID;
let token = localStorage.getItem(`${config.TEAM_ID}_MA_REFRESH_TOKEN`);
try {
  axios.defaults.headers.common["refreshToken"] = JSON.parse(token);
} catch (error) {
  axios.defaults.headers.common["refreshToken"] = null;
}

export function AxiosProvider({ children }) {
  // Getting refresh token and teamId to pass in axios interceptor
  // with side effect
  const reactLocalStorage = useLocalStorage();

  useEffect(() => {
    let refreshToken = reactLocalStorage.getItem(
      `${config.TEAM_ID}_MA_REFRESH_TOKEN`
    );
    if (refreshToken) {
      axios.defaults.headers.common["refreshToken"] = refreshToken;
    } else {
      axios.defaults.headers.common["refreshToken"] = null;
    }
  }, [reactLocalStorage]);

  return <AxiosContext.Provider>{children}</AxiosContext.Provider>;
}

import config from "src/config";
import apiClientV2 from "./config/apiClientV2";
import { teamSettingsEndPoint } from "./config/apiEndPoints";

export const settingsApi = {
  getTeamSettings() {
    return apiClientV2
      .post(`${teamSettingsEndPoint}`, {teamId: config.TEAM_ID})
      .then((response) => {
        return response;
      });
  },
};

import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Paper,
  makeStyles,
  colors,
  Box,
  CircularProgress,
} from "@material-ui/core";
import {
  AlbumOutlined,
  AccountCircleOutlined,
  AutorenewOutlined,
} from "@material-ui/icons";
import config from "src/config";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";

export default function SubscriptionDetails({
  subscriptionDetails,
  isNextPlan,
}) {
  const styles = useStyles();
  const { status, dateStarted, nextRenewalDate, monthlyCost, numSeats } =
    subscriptionDetails || {};
  const [isActive, setIsActive] = useState(false);

  const checkStatus = () => {
    const isActive = status === "ACTIVE";
    setIsActive(isActive);
  };

  useEffect(checkStatus, [subscriptionDetails]);

  return (
    <>
      <Paper variant="outlined">
        <List dense>
          <ListItem>
            <ListItemIcon classes={{ root: styles.icon }}>
              <AlbumOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Status"
              classes={{ primary: styles.itemName }}
            />
            <ListItemSecondaryAction>
              <Typography
                className={clsx(styles.status, styles[status?.toLowerCase()])}
                variant="subtitle1"
              >
                {status || "Active"}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon classes={{ root: styles.icon }}>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Member Since"
              classes={{ primary: styles.itemName }}
            />
            <ListItemSecondaryAction>
              <Typography className={styles.date} variant="subtitle1">
                {moment(dateStarted).format("MMM Do yyyy")}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          {isActive && !isNextPlan && (
            <ListItem>
              <ListItemIcon classes={{ root: styles.icon }}>
                <AutorenewOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Next Renewal Date"
                classes={{ primary: styles.itemName }}
              />
              <ListItemSecondaryAction>
                <Typography className={styles.date} variant="subtitle1">
                  {moment(nextRenewalDate).format("MMM Do yyyy")}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </Paper>
      {isActive && !isNextPlan && (
        <Box mt={2}>
          <Paper variant="outlined">
            <Box p={2}>
              <Alert severity="info">
                <Typography className={styles.alertText}>
                  You will be charged{" "}
                  {`$${(monthlyCost * numSeats).toFixed(2)}`} on{" "}
                  {moment(nextRenewalDate).format("MMM Do")}
                </Typography>
              </Alert>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 32,
  },

  itemName: {
    fontFamily: "Poppins",
    fontWeight: 600,
  },

  buttonItem: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: config.COLORS.primary,
    textDecoration: "underline",
  },
  status: {
    color: colors.green[600],
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: 14,
  },
  active: {
    color: colors.green[600],
  },
  inactive: {
    color: theme.palette.error.main,
  },
  paused: {
    color: theme.palette.warning.main,
  },
  date: {
    color: config.COLORS.primary,
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: 14,
  },

  alertText: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
  },
}));

import config from "src/config";
import apiClient from "./config/apiClient";
import {
  linkAccountEndpoint,
  getTeamAuthUrl,
  loginTicketmasterAuthCode,
  refreshTicketmasterToken,
} from "./config/apiEndPoints";
import { apiReponseError } from "./config/apiRequestError";

export const teamAuthApi = {
  linkAccount(data) {
    return apiClient
      .post(linkAccountEndpoint, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        apiReponseError(err);
      });
  },
  getTeamUrl() {
    return apiClient.get(getTeamAuthUrl, null).then((response) => {
      return response.data;
    });
  },
  loginTicketmasterAuthCode(authCode, redirectUrl) {
    const data = {
      authCode: authCode,
      teamId: config.TEAM_ID,
      redirectUri: redirectUrl,
      isTest: config.IS_DEVELOPMENT,
    };
    return apiClient.post(loginTicketmasterAuthCode, data).then((response) => {
      return response;
    });
  },
  refreshTicketmasterUser(refreshToken) {
    const data = {
      refreshToken: refreshToken,
      teamId: config.TEAM_ID,
    };
    return apiClient.post(refreshTicketmasterToken, data).then((response) => {
      return response;
    });
  },
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  makeStyles,
  Grid,
  colors,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import config from "src/config";
import { withPopup } from "./withPopup";
import { Alert } from "@material-ui/lab";

export default function SubscriptionAction({
  subscriptionDetails,
  updateSubscription,
}) {
  const teamId = config?.TEAM_ID;
  const isSkipHidden = teamId === 78 || teamId === 202;
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [remainingDays, setRemainingDays] = useState(undefined);
  const [isOptInRange, setIsOptInRange] = useState(false);
  const { optOutStartDate, optOutEndDate, status } = subscriptionDetails || {};
  const [optBackLoading, setOptBackLoading] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const dateDifference = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDiff = d2 - d1;
    const dateDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return dateDiff;
  };

  const calculateWithinRange = () => {
    const today = new Date();
    const startDiff = dateDifference(optOutStartDate, today);
    const endDiff = dateDifference(optOutEndDate, today);
    const isRanged = startDiff > 0 && endDiff < 0;
    setIsOptInRange(isRanged);
  };
  const calculateRemainig = () => {
    const today = new Date();
    const remaining = dateDifference(today, optOutStartDate);
    setRemainingDays(remaining);
  };

  const handleOptBack = async () => {
    setOptBackLoading(true);
    setLoading(true);
    try {
      await updateSubscription({ status: "ACTIVE" });
    } catch (error) {}
    setOptBackLoading(false);
    setLoading(false);
  };

  const handleSkipNext = async () => {
    setSkipLoading(true);
    setLoading(true);
    try {
      await updateSubscription({ status: "PAUSED" });
    } catch (error) {}
    setSkipLoading(false);
    setLoading(false);
  };

  const handleCancel = async () => {
    setCancelLoading(true);
    setLoading(true);
    try {
      await updateSubscription({ status: "INACTIVE" });
    } catch (error) {}
    setCancelLoading(false);
    setLoading(false);
  };

  useEffect(calculateRemainig, [subscriptionDetails]);
  useEffect(calculateWithinRange, [subscriptionDetails]);

  const CancelButton = withPopup(Button);
  const SkipButton = withPopup(Button);

  return (
    <Paper variant="outlined">
      {isOptInRange ? (
        <Box className={styles.wrapper} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={status === "ACTIVE" ? 6 : 12}>
              {(status === "PAUSED" || status === "INACTIVE") && (
                <Button
                  disabled={loading}
                  fullWidth
                  size="small"
                  disableElevation
                  variant="contained"
                  className={styles.button}
                  onClick={handleOptBack}
                  endIcon={
                    optBackLoading && (
                      <CircularProgress
                        size={12}
                        style={{ color: colors.grey[600] }}
                      />
                    )
                  }
                >
                  OPT BACK IN
                </Button>
              )}

              {status === "ACTIVE" && !isSkipHidden && (
                <SkipButton
                  buttonText="SKIP NEXT MONTH"
                  confirmText="Are you sure you want to skip next month? If you skip a month, you will be charged the following month unless you skip again or cancel within the opt-out window."
                  className={styles.button}
                  action={handleSkipNext}
                  disabled={skipLoading}
                  {...{ loading }}
                />
              )}
            </Grid>

            {status === "ACTIVE" && (
              <Grid item xs={12} md={isSkipHidden ? 12 : 6}>
                <CancelButton
                  buttonText="Cancel Forever"
                  confirmText="Are you sure you want to cancel your subscription?"
                  className={styles.cancelButton}
                  action={handleCancel}
                  disabled={loading}
                  loading={cancelLoading}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      ) : (
        <Box p={2}>
          <Alert severity="warning">
            <Typography className={styles.alertText}>
              Opt Out / Opt In Window will start in {remainingDays}{" "}
              {remainingDays > 1 ? "days" : "day"}
            </Typography>
          </Alert>
        </Box>
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,
    fontFamily: "Poppins",
    fontSize: 16,
    paddingTop: "10px",
    paddingBottom: "10px",

    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      //   color: config.COLORS.secondaryContrastText,
    },
  },

  alertText: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
  },

  cancelButton: {
    backgroundColor: colors.grey[100],
    color: colors.red[400],
    // textDecoration: "underline",
    fontFamily: "Poppins",
    fontSize: 16,
    paddingTop: "10px",
    paddingBottom: "10px",

    "&:hover": {
      backgroundColor: colors.grey[150],
      //   color: config.COLORS.secondaryContrastText,
    },
  },
}));

import React from "react";
import { Box, Paper, makeStyles, Modal, colors } from "@material-ui/core";

export default function Popup({
  children,
  open,
  PaperProps,
  closeButtonProps,
}) {
  const classes = useStyles();

  return (
    <Modal {...{ open }}>
      <Box className={classes.presentation}>
        <Paper
          className={classes.paper}
          {...PaperProps}
          style={{ width: PaperProps?.width || 350 }}
        >
          {closeButtonProps?.canCloseByUser && closeButtonProps?.closeIcon && (
            <Box
              className={classes.closeButtonWrapper}
              style={{
                backgroundColor: closeButtonProps.backgroundColor || "white",
              }}
            >
              {closeButtonProps.closeIcon}
            </Box>
          )}

          {children}
        </Paper>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  presentation: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.6)",
    transform: "blur(8px)",
    position: "relative",
    backdropFilter: "blur(4px)",
  },

  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  closeButtonWrapper: {
    position: "absolute",
    transform: "translateY(-100%)",
    top: 0,
    right: 0,
    width: 35,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    cursor: "pointer",
  },
}));

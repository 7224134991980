import { toast } from "react-toastify";
import config from "src/config";
import axios from "axios";

async function apiCall(endpoint, body) {
  try {
    let response = await axios.post(endpoint, body, {
      headers: { team_id: config.TEAM_ID },
    });
    if (response.status === 200) {
      return Promise.resolve(response);
    }
  } catch (error) {
    toast.error(
      error?.response?.data?.message ||
        error?.response?.data ||
        "Something went wrong, please try again"
    );
    return Promise.reject(error);
  }
}

export async function getAccountSummary() {
  let resolve = Promise.resolve.bind(Promise);
  try {
    let endpoint = `${config.ADMIN_URI}/subscribers/accountSummary`;
    let teamId = config.TEAM_ID;
    let accountNumber = JSON.parse(
      localStorage.getItem(`${teamId}_MA_SUBSCRIBER`)
    ).accountNumber;
    let response = await apiCall(endpoint, { teamId, accountNumber });
    return !response ? resolve([]) : resolve(response.data.summary.subscribers);
  } catch (error) {
    return resolve([]);
  }
}

export async function getEvents(subscriberId) {
  let teamId = config.TEAM_ID;
  let endpoint = `${config.ADMIN_URI}/events/attendance`;
  let response = await apiCall(endpoint, { teamId, subscriberId });
  let resolve = Promise.resolve.bind(Promise);
  return !response ? resolve(null) : resolve(response.data);
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  makeStyles,
  TextField,
  Button,
  colors,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import config from "src/config";
import clsx from "clsx";
import { useFormik } from "formik";
import { useQuery } from "src/hooks/url_query_params";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  emailAddress: yup.string().email("Please insert a valid email address"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid"),
});
export default function ContactDetails({ subscribers, getSummary }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [subscriber, setSubscriber] = useState(null);
  const [testLoading, setTestLoading] = useState(false);
  const query = useQuery();

  const handleInit = () => {
    const subscriberId = query.get("subscriberId");
    const subscriber = subscribers.find((s) => s.id == subscriberId); // s = subscriber
    setSubscriber(subscriber);
  };

  useEffect(handleInit, [query.params, subscribers]);

  const initialValues = {
    emailAddress: subscriber?.emailAddress,
    phoneNumber: subscriber?.phoneNumber.replace(/\+1/, ""),
  };
  const onSubmit = (values) => {
    updatePhone();
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    isValid,
    dirty,
    setFieldValue,
  } = formik;

  const testPhone = async () => {
    setTestLoading(true);
    let endpoint = `${config.API_BASE_URI}/teamAuth/test/phone`;
    let phoneNumber = "+1" + values.phoneNumber;

    try {
      let response = await axios.post(endpoint, { phoneNumber });
      if (response.status === 200) {
        toast.success("Phone number test successful");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }

    setTestLoading(false);
  };

  const updatePhone = async () => {
    setLoading(true);
    let phoneNumber = values.phoneNumber;
    let endpoint = `${config.ADMIN_URI}/subscribers/edit-phone`;
    let teamId = config.TEAM_ID;
    let subscriberId = subscriber.id;

    try {
      let response = await axios.post(endpoint, {
        phoneNumber,
        teamId,
        subscriberId,
      });

      if (response.status === 200) {
        getSummary(); // getting the updated summary
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          "Something went wrong, please try again"
      );
    }
    setLoading(false);
  };

  return (
    <Paper variant="outlined">
      <Box p={2} pt={1} pb={1}>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          Contact Information
        </Typography>
      </Box>
      <Divider />
      {subscriber && (
        <Box p={3}>
          <Paper className={classes.warning} elevation={4}>
            <Box p={2}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.warningText}
              >
                Please make sure the phone number provided can recieve text
                messages.
              </Typography>
            </Box>
          </Paper>

          <Box mt={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    size="medium"
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    className={classes.inputField}
                    name="emailAddress"
                    label="EMAIL ADDRESS"
                    value={values.emailAddress}
                    error={errors.emailAddress && true}
                    helperText={errors.emailAddress}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <TextField
                    value="+1"
                    disabled
                    className={clsx(classes.phonePrefix, classes.inputField)}
                    variant="outlined"
                    size="medium"
                    disabled
                  />
                  <TextField
                    required
                    size="medium"
                    variant="outlined"
                    fullWidth
                    className={classes.inputField}
                    name="phoneNumber"
                    label="PHONE NUMBER"
                    value={values.phoneNumber}
                    error={errors.phoneNumber && true}
                    helperText={errors.phoneNumber}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          className={classes.submitButton}
                          onClick={testPhone}
                          disabled={testLoading}
                        >
                          Test
                          {testLoading && (
                            <CircularProgress
                              className={classes.loadingFix}
                              size={12}
                              style={{
                                marginLeft: 5,
                                color: colors.grey["600"],
                              }}
                            />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    size="large"
                    type="submit"
                    disabled={!(isValid && dirty) || loading}
                    className={classes.submitButton}
                  >
                    SAVE
                    {loading && (
                      <CircularProgress
                        size={12}
                        style={{
                          marginLeft: 5,
                          marginTop: -1,
                          color: colors.grey["600"],
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 22,
  },
  warning: {
    borderLeft: "3px solid #FF9800",
    borderRadius: 8,
  },
  warningText: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  inputField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: config.COLORS.primary,
    },

    "& fieldset": {
      borderRadius: 0,
    },

    "& .MuiInputLabel-shrink": {
      color: config.COLORS.primary,
    },
  },
  phonePrefix: {
    width: 60,

    "& fieldset": {
      borderRight: "none",
      borderRadius: 0,
    },
  },

  submitButton: {
    backgroundColor: config.COLORS.primary,
    color: "white",

    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      color: config.COLORS.contrastText,
    },
  },
  loadingFix: {
    "& .MuiCircularProgress-svg": {
      width: 12,
    },
  },

  statusText: {
    color: colors.green["600"],
  },
}));

import React, { useState } from "react";
import Popup from "src/components/Popup";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import config from "src/config";

export default function ErrorPopup({ title, subtitle }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handlePopup = () => setOpen(!open);
  return (
    <Popup
      {...{ open }}
      PaperProps={{
        width: 350,
      }}
      closeButtonProps={{
        canCloseByUser: true,
        backgroundColor: config.COLORS.primary,
        closeIcon: (
          <HighlightOff
            className={classes.closeButton}
            onClick={() => handlePopup(false)}
          />
        ),
      }}
    >
      <Box p={4}>
        <Box textAlign="center">
          <HighlightOff className={classes.errorIcon} />
        </Box>
        <Box textAlign="center">
          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.errorTitle}
          >
            {title}
          </Typography>
        </Box>
        <Box textAlign="center" mt={1}>
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.errorSubtitle}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Popup>
  );
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: "white",
  },
  errorIcon: {
    color: theme.palette.error.light,
    fontSize: 48,
  },
  errorTitle: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 22,
  },

  errorSubtitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.3,
  },
}));

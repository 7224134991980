import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import MatInput from "./MatInput";

export default function BillingDetails({ formik }) {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6}>
        <MatInput {...{ formik }} name="firstName" placeholder="FIRST NAME" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MatInput {...{ formik }} name="lastName" placeholder="LAST NAME" />
      </Grid>
      <Grid item xs={12} lg={12} className={classes.phoneInputWrapper}>
        <TextField
          value="+1"
          disabled
          className={clsx(classes.phonePrefix, classes.inputField)}
          variant="outlined"
          size="medium"
        />
        <MatInput
          {...{ formik }}
          name="phoneNumber"
          placeholder="PHONE NUMBER"
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <MatInput {...{ formik }} name="email" placeholder="EMAIL ADDRESS" />
      </Grid>

      <Grid item xs={12} lg={12}>
        <MatInput {...{ formik }} name="address" placeholder="ADDRESS" />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  phoneInputWrapper: {
    display: "flex",
  },

  phonePrefix: {
    width: 60,

    "& fieldset": {
      borderRight: "none",
      borderRadius: 0,
    },
  },
}));

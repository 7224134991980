import React from "react";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import config from "src/config";

export default function Manifest({ manifest }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper className={classes.paper} variant="outlined">
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems={sm ? "flex-start" : "center"}
        flexDirection={sm ? "column" : "row"}
        className={classes.contentWrapper}
      >
        <Box display="flex" width={sm ? "100%" : "40%"}>
          {/* <Box width={40} pt={1}>
            <ProductIcon />
          </Box> */}
          <Box>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.planDescription}
            >
              {manifest.priceCodeDescription}
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.planStatus}
            >
              PAID :
              {manifest.isPaid ? (
                <span style={{ color: "green", fontSize: 14, marginLeft: 5 }}>
                  YES
                </span>
              ) : (
                <span style={{ color: "red", fontSize: 14, marginLeft: 5 }}>
                  NO
                </span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderLeft: `3px solid ${config.COLORS.primary}`,
    borderRadius: "8px",
    marginTop: "20px",

    "&:first-child": {
      marginTop: 0,
    },
  },

  planStatus: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
  },
  planDescription: {
    fontFamily: "Raleway",
    fontWeight: "600",
    fontSize: 16,
  },
  scheduleButton: {
    color: config.COLORS.secondary,
    cursor: "pointer",
    padding: 0,
    fontSize: 14,
    fontFamily: "Raleway",
    backgroundColor: "transparent",
    border: "none",
    fontWeight: 700,

    "&:focus": {
      outline: "none",
    },
  },

  metaTitle: {
    fontSize: 16,
    fontWeight: "500",
  },
  metaDesc: {
    fontSize: 16,
    // fontWeight: "700",
  },

  counterButton: {
    fontWeight: "700",
    fontSize: 16,
  },
  counter: {
    boxSizing: "border-box",
  },
  divider: {
    [theme.breakpoints.down("sm")]: {
      height: "1px !important",
      marginBottom: "10px",
    },
  },
}));

import axios from "axios";
import config from "src/config";


// This API client points to subs janitor backened
// Eventually all APIs will point here, so i'm seperating it out in an 
// effort to start refactoring away from team specific backends 

const apiClientV2 = axios.create({
  baseURL: config.ADMIN_URI,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClientV2;

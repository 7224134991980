import React from "react";
import Layout from "src/pages/MembershipPortal/Layout";
import ManageSubscription from "src/views/ManageSubscription";

export default function () {
  return (
    <Layout>
      <ManageSubscription />
    </Layout>
  );
}

import React from "react";
import {
  Paper,
  Box,
  Typography,
  Divider,
  Button,
  useMediaQuery,
  useTheme,
  makeStyles,
  colors,
} from "@material-ui/core";
import config from "src/config";
import { LocalMallOutlined } from "@material-ui/icons";

export default function PassCard({ plan, index, handleBuyNow, handleGameDetail }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.paper}>
      {plan.bannerImage && (
        <Box className={classes.planImageWrapper}>
          <img src={plan.bannerImage} className={classes.planImage} alt="" />
        </Box>
      )}

      <Box className={classes.paperContents}>
        <Box className={classes.bgDecorator} />
        <Box p={sm ? 2 : 4} zIndex={99} position="relative">
          <Box pb={4} mt={plan.isExclusive ? 3 : 0}>
            <Typography variant="h5" className={classes.planName}>
              {plan["planName"]}
            </Typography>
          </Box>

          {!plan.isExclusive && (
            <Box display="flex" justifyContent="center">
              <Paper
                elevation={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {plan["maxEventCount"] != 0 && (
                  <>
                    <Box
                      width="50%"
                      p={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Box>
                        <Typography
                          variant="h3"
                          color="textPrimary"
                          className={classes.innerNumber}
                        >
                          {plan["maxEventCount"]}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          className={classes.innerLabel}
                        >
                          {plan["maxEventCount"] > 1 ? "Games" : "Game"}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider orientation="vertical" />
                  </>
                )}

                <Box
                  width="50%"
                  p={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Box>
                    {plan["monthlyCost"] !== 0 ? (
                      <Typography variant="h3" color="textPrimary" className={classes.innerNumber}>
                        ${Number(plan["monthlyCost"]).toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography variant="h3" color="textPrimary" className={classes.innerNumber}>
                        ${Number(plan["oneTimeCost"]).toFixed(2)}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    {plan["monthlyCost"] !== 0 ? (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.innerLabel}
                      >
                        Per Month
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.innerLabel}
                      >
                        Pass Price
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}

          <Box mt={plan.isExclusive ? 8 : 3} textAlign="center">
            <Typography variant="body1" color="textSecondary" className={classes.planDescription}>
              {plan["planDescription"]}
            </Typography>
          </Box>
        </Box>

        <Box p={sm ? 2 : 4} pt={0}>
          <Box mt={3} textAlign="center">
            <Button
              fullWidth
              disableElevation
              size="large"
              color="primary"
              variant="contained"
              className={classes.buyButton}
              onClick={() => {
                handleBuyNow(index);
              }}
            >
              {plan.isExclusive ? "Register Pass" : "Buy Now"}
            </Button>
          </Box>

          {!!plan.isExclusive && plan.buyLink && (
            <>
              <Box display="flex" justifyContent="center" mt={2}>
                <Typography variant="subtitle1" color="textSecondary" className={classes.orText}>
                  or
                </Typography>
              </Box>
              <Box mt={1} display="flex" justifyContent="center">
                <Typography className={classes.buyNowLink}>
                  <a href={plan.buyLink} target="_blank" rel="noreferrer">
                    <LocalMallOutlined className={classes.cartIcon} /> Buy Now
                  </a>
                </Typography>
              </Box>
            </>
          )}

          {config?.TEAM_ID !== 78 && (
            <Box mt={2} textAlign="center">
              <Typography variant="button" className={classes.scheduleButton}>
                <button
                  onClick={() => handleGameDetail(plan["planName"], plan["id"])}
                  className={classes.scheduleButton}
                >
                  View Schedule
                </button>
              </Typography>
            </Box>
          )}

          {/* {plan["isExclusive"] === 1 && (
          <span>Must verify eligibility to purchase</span>
        )} */}
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paperContents: {
    position: "relative",
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  bgDecorator: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "180px",
    width: "100%",
    background: config.COLORS.secondary,
    clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 70%)`,
  },
  planName: {
    color: config.COLORS.contrastText,
    fontFamily: "Raleway",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "28px !important",
  },
  innerNumber: {
    fontWeight: "bold",
    fontFamily: "Raleway",
    fontSize: "30px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
    },
  },
  innerLabel: {
    fontWeight: "bold",
    fontFamily: "Raleway",
    fontSize: "14px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  planDescription: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: "18px !important",
  },
  buyButton: {
    backgroundColor: config.COLORS.primary,
    height: 50,
    fontSize: "16px",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
      color: config.COLORS.contrastText,
    },
  },

  orText: {
    position: "relative",
    width: "fit-content",
    fontWeight: 700,
    fontFamily: "Raleway",
    color: colors.grey[600],
    fontSize: 18,

    "&::before, &::after": {
      position: "absolute",
      content: '""',
      display: "block",
      width: 80,
      height: 2,
      borderRadius: 2,
      backgroundColor: colors.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
    },

    "&::before": {
      right: "120%",
    },
    "&::after": {
      left: "120%",
    },
  },

  scheduleButton: {
    color: config.COLORS.typography,
    cursor: "pointer",
    textDecoration: "underline",
    padding: 0,
    fontSize: 18,
    fontFamily: "Raleway",
    backgroundColor: "transparent",
    border: "none",
    fontWeight: 500,

    "&:focus": {
      outline: "none",
    },
  },

  buyNowLink: {
    padding: 0,
    fontSize: 18,
    fontFamily: "Raleway",
    fontWeight: 500,

    "& a": {
      // color: config.COLORS.primary,
      textDecoration: "underline",
      color: colors.blue[600],
      display: "flex",
      alignItems: "center",
    },
  },
  cartIcon: {
    marginRight: 2,
    fontSize: 28,
    color: colors.blue[600],
  },

  planImageWrapper: {
    marginBottom: "-5px",
  },
  planImage: {
    width: "100%",
    height: "100%",
  },

  paper: {
    overflow: "hidden",
    borderRadius: 6,
  },
}));

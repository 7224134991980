import React from "react";
import {
  Paper,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import config from "src/config";
import { Users as UsersIcon } from "react-feather";

const useStyles = makeStyles((theme) => ({
  planName: {
    color: "white",
    fontFamily: "Raleway",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "28px !important",
  },
  innerNumber: {
    fontWeight: "bold",
    fontFamily: "Raleway",
    fontSize: "30px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
    },
  },
  innerLabel: {
    fontWeight: "bold",
    fontFamily: "Raleway",
    fontSize: "14px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  planDescription: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: "18px !important",
  },
  buyButton: {
    backgroundColor: config.COLORS.primary,
    height: 50,
    fontSize: "16px",
    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },
  scheduleButton: {
    fontFamily: "Raleway",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "16px !important",
  },
  purchaseDate: {
    color: "white",
    textAlign: "center",
  },
}));

export default function PassCard({ plan, subscriber }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <Paper
      // variant="outlined"
      elevation={8}
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: 6,
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "180px",
          width: "100%",
          background: config.COLORS.secondary,
          clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 70%)`,
        }}
      />

      <Box p={sm ? 2 : 4} zIndex={99} position="relative">
        <Box pb={2}>
          <Typography variant="h5" gutterBottom className={classes.planName}>
            {plan["planName"]}
          </Typography>

          <Typography variant="body2" className={classes.purchaseDate}>
            STATUS:
            {subscriber.status}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Paper
            elevation={6}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Box
              width="30%"
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box>
                <UsersIcon />
              </Box>
            </Box>
            <Divider orientation="vertical" />
            <Box
              width="50%"
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={classes.innerNumber}
                >
                  {subscriber["seatCount"]}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.innerLabel}
                >
                  {subscriber["seatCount"] > 1 ? "Seats" : "Seat"}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box mt={3} textAlign="center">
          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.planDescription}
          >
            {plan["planDescription"]}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

import React, { useEffect, useState } from "react";
import ContactDetails from "src/components/ContactDetails";
import Subscribers from "src/components/Subscribers";
import Passes from "src/components/Passes";
import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { getAccountSummary } from "./api_calls";
import config from "src/config";
import { useQuery } from "src/hooks/url_query_params";

export default function ManageContacts() {
  const classes = useStyles();
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const query = useQuery();

  const handleInit = () => {
    // if subscribers are not available then return
    // and wait for accountSummary to load
    if (!(subscribers.length > 0)) return;

    // Checking if the params defined in url
    let isDefined = /subscriberId/g.test(query.params);

    // if params are not present in url, then set them initially
    if (!isDefined) {
      let subscriberId = subscribers[0].id;
      query.set("subscriberId", subscriberId);
    }

    // getting params from url
    const subscriberId = query.get("subscriberId");

    // validating url
    const subscriber = subscribers?.find((s) => s.id == subscriberId); // s = subscriber

    // --> converting values to boolean by prefixing with !!
    const isValid = !!subscriber;

    // if given subscriberId is exists
    // in the api responses then no error will throw and loading will stop
    // otherwise throw error
    isValid ? setError(false) : setError(true);
    setLoading(false);
  };

  const getSummary = async () => {
    setLoading(true);
    let subscribers = await getAccountSummary();
    setSubscribers(subscribers);
    setLoading(false);
  };

  // const handleUpdate = (phoneNumber, subscriberId) => {
  //   let changed =
  // }

  useEffect(() => {
    getSummary();
  }, []);
  useEffect(handleInit, [query.params, subscribers]);

  return (
    <>
      {loading ? (
        <Box textAlign="center" mt={8}>
          <CircularProgress
            size={24}
            style={{ color: config.COLORS.primary }}
          />
        </Box>
      ) : (
        <>
          {!error ? (
            <Box>
              <Box>
                <Subscribers {...{ subscribers }} />
              </Box>
              <Box mt={3}>
                <ContactDetails {...{ subscribers, getSummary }} />
              </Box>
            </Box>
          ) : (
            <Box p={3}>
              <Box textAlign="center">
                <HighlightOff className={classes.errorIcon} />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.errorTitle}
                >
                  Invalid URL
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({}));

import axios from "axios";
import config from "src/config";

const apiClient = axios.create({
  baseURL: config.API_BASE_URI,
  headers: {
    "Content-Type": "application/json",
    "team_id": config.TEAM_ID,
    "teamid": config.TEAM_ID,
  },
});

export default apiClient;

import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  makeStyles,
  Divider,
  Button,
  CircularProgress,
  colors,
  Paper,
  lighten,
} from "@material-ui/core";
import UpdateCardForm from "./UpdateCardForm";
import { EditOutlined, CloseRounded, CreditCard } from "@material-ui/icons";
import VisaImage from "src/images/cards/visa.svg";
import config from "src/config";
import { Alert } from "@material-ui/lab";

export default function CardDetails({ cardDetails, saveNewCard }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const toggleCollapse = () => setExpanded(!expanded);

  return (
    <Accordion variant="outlined" expanded={expanded}>
      {!cardDetails ? (
        <Box>
          <Box display="flex" flexDirection="column" p={2}>
            <Alert className={classes.warningAlert} severity="warning">
              <Typography className={classes.alertText}>
                No Credit Card found.
              </Typography>
            </Alert>
            <Button
              fullWidth
              className={classes.updateButton}
              variant="contained"
              size="small"
              disableElevation
              onClick={toggleCollapse}
              startIcon={<EditOutlined />}
            >
              Update Card
            </Button>
          </Box>
        </Box>
      ) : (
        <AccordionSummary
          onClick={toggleCollapse}
          IconButtonProps={{
            disableRipple: true,
          }}
          expandIcon={expanded ? <CloseRounded /> : <EditOutlined />}
          className={classes.accordionSummary}
        >
          <Box display="flex" alignItems="center">
            <CreditCard
              fontSize="large"
              style={{ color: colors.grey["600"] }}
            />

            <Box ml={2}>
              <Typography
                className={classes.cardNumber}
                variant="h6"
                color="textPrimary"
              >
                Card On File : {cardDetails?.data_mask}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
      )}

      <AccordionDetails className={classes.details}>
        <UpdateCardForm {...{ saveNewCard, toggleCollapse }} />
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: "none",
  },
  cardImage: {
    width: 32,
    borderRadius: 4,
  },
  cardNumber: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: "0.06em",
  },

  editButton: {
    backgroundColor: config.COLORS.primary,
    color: config.COLORS.contrastText,

    "&:hover": {
      backgroundColor: config.COLORS.secondary,
    },
  },

  details: {
    padding: 0,
  },
  accordionSummary: {
    "&.Mui-expanded": {
      minHeight: 48,

      "& .MuiAccordionSummary-content": {
        marginTop: 12,
        marginRight: 0,
        marginBottom: 12,
        marginLeft: 0,
      },
    },

    "& .MuiAccordionSummary-expandIcon": {
      // transform: "rotate(0deg)",
      color: config.COLORS.primary,
    },
  },

  warningAlert: {
    // width: "100%",
  },
  alertText: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
  },
  updateButton: {
    backgroundColor: config?.COLORS?.primary,
    color: config?.COLORS?.contrastText,
    marginTop: "8px",

    "&:hover": {
      backgroundColor: lighten(config?.COLORS?.primary, 0.2),
    },
  },
}));
